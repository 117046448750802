import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'PROCESSING_REQUESTS_TABLE', {
  STATUS_LABEL: 'Status',
  INSTITUTION_LABEL: 'Institution',
  SENT_DATE: 'Sent date',
  USER_LABEL: 'User',
  DAYS_OF_DELAY: 'Days of delay',
  BOX_OK: 'Box OK',
  BOX_MISSING: 'Box missing',
  TOTAL_BRICKS_MISSING: 'Total bricks missing',
  LOADING: 'Loading...',
  LABELS: 'Labels',
  REQUEST: 'Request',
});

i18next.addResources(locales.ES, 'PROCESSING_REQUESTS_TABLE', {
  STATUS_LABEL: 'Estado',
  INSTITUTION_LABEL: 'Institución',
  SENT_DATE: 'Fecha de envío',
  USER_LABEL: 'Usuario',
  DAYS_OF_DELAY: 'Días de demora',
  BOX_OK: 'Cajas OK',
  BOX_MISSING: 'Cajas con faltantes',
  TOTAL_BRICKS_MISSING: 'Piezas faltantes totales',
  LOADING: 'Cargando...',
  LABELS: 'Etiquetas',
  REQUEST: 'Solicitud',
});
