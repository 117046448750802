import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as userActions from 'src/redux/actions/user';
import institutionsListSelector from 'src/redux/selectors/institution/institutionsList';
import rolesSelector from 'src/redux/selectors/roles/roles';
import userInfoSelector from 'src/redux/selectors/user/userInfo';
import userToEditSelector from 'src/redux/selectors/user/userToEdit';

import { ISelectOption } from 'src/components/Inputs/SelectInput/types';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { INewUser } from 'src/types/user';
import UsersEdit from 'src/views/Users/UsersEdit';

import LayoutContainer from '../Layout/Layout';

const UsersEditContainer = () => {
  const dispatch = useDispatch();
  const routeParams = useRouteMatch<{ id: string }>();

  const userToEdit = useSelector(userToEditSelector());
  const userInfo = useTypedSelector(userInfoSelector());
  const institutions: ISelectOption[] = useTypedSelector(institutionsListSelector()).map(
    (institution) => ({ value: institution.id, text: institution.name }),
  );
  const roles: ISelectOption[] = useTypedSelector(rolesSelector());

  const onSubmit = (id: string, user: INewUser) => {
    dispatch(userActions.onEditOneRequested(id, user));
  };

  const onInitializeUsersEdit = useCallback(() => {
    dispatch(userActions.initializeUsersEditView(routeParams.params.id));
  }, [dispatch, routeParams.params.id]);

  useEffect(() => {
    onInitializeUsersEdit();
  }, [onInitializeUsersEdit]);

  if (!userToEdit) return null;

  return (
    <LayoutContainer>
      <UsersEdit
        user={userToEdit}
        roles={roles}
        institutions={institutions}
        loading={userInfo.info.loading}
        onSubmit={onSubmit}
      />
    </LayoutContainer>
  );
};

export default UsersEditContainer;
