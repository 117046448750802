import { AnyAction, MiddlewareAPI } from 'redux';

import { APP_URL } from 'src/configs/configs';
import history from 'src/helpers/history';
import { getAuthToken } from 'src/helpers/user';

import * as appConstants from '../constants/app';
import * as authConstants from '../constants/auth';
import * as boxConstants from '../constants/box';
import * as institutionConstants from '../constants/institution';
import * as qrRequestConstants from '../constants/qrRequest';
import * as requestConstants from '../constants/request';
import * as userConstants from '../constants/user';

const redirectAfterLogin = () => {
  if (history.location.state?.from) {
    history.replace({
      pathname: history.location.state.from,
      search: history.location.state.params,
    });
  } else {
    history.push({
      pathname: 'requests',
    });
  }
};

const redirectToLogin = () => {
  history.replace({
    pathname: '/login',
    state: {
      from: history.location.pathname,
      params: history.location.search,
    },
    search: history.location.search,
  });
};

const redirectMiddleware =
  (state: MiddlewareAPI) => (next: (action: AnyAction) => void) => (action: AnyAction) => {
    switch (action.type) {
      // AUTH
      case authConstants.USER_ON_LOGIN_SUCCEEDED:
        redirectAfterLogin();
        break;
      default:
        break;
      case authConstants.USER_ON_LOGOUT_SUCCEEDED:
        window.location.href = APP_URL + '/login';
        break;

      // EDIT
      case userConstants.INITIALIZE_USERS_EDIT_VIEW_FAILED:
        history.replace('/users');
        break;

      // CREATE
      case userConstants.USERS_ON_CREATE_ONE_SUCCEEDED:
      case userConstants.USERS_ON_EDIT_ONE_SUCCEEDED:
        history.push('/users');
        break;

      // EDIT
      case institutionConstants.INITIALIZE_INSTITUTIONS_EDIT_VIEW_FAILED:
        history.replace('/institutions');
        break;

      // CREATE
      case institutionConstants.INSTITUTIONS_ON_CREATE_ONE_SUCCEEDED:
      case institutionConstants.INSTITUTIONS_ON_EDIT_ONE_SUCCEEDED:
        history.push('/institutions');
        break;

      // EDIT
      case boxConstants.INITIALIZE_BOXES_EDIT_VIEW_FAILED:
        history.replace('/boxes');
        break;

      // CREATE
      case boxConstants.BOXES_ON_CREATE_ONE_SUCCEEDED:
        history.push('/boxes/preview');
        break;

      case boxConstants.BOXES_ON_EDIT_ONE_SUCCEEDED:
        history.push('/boxes');
        break;

      // EDIT
      case requestConstants.INITIALIZE_REQUESTS_EDIT_VIEW_FAILED:
        history.replace('/requests');
        break;

      // CREATE
      case requestConstants.REQUESTS_ON_EDIT_ONE_SUCCEEDED:
      case requestConstants.REQUESTS_ON_EDIT_ONE_FAILED:
        if (action.inRequestsInProgress) {
          history.push('/requests');
        } else {
          history.push('/requests');
        }
        break;

      // EDIT
      case qrRequestConstants.QR_REQUESTS_ON_EDIT_ONE_SUCCEEDED:
      case qrRequestConstants.QR_REQUESTS_ON_EDIT_ONE_FAILED:
        history.push('/qr-requests');
        break;

      // ROUTES
      case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_REQUESTED:
        if (!getAuthToken()) {
          redirectToLogin();
        }
        break;
      case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_SUCCEDED:
        if (action.restricted && action.user) {
          history.push({
            pathname: '/users',
          });
        }
        break;

      case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_FAILED:
        if (action.error.statusCode === 401 || action.error.statusCode === 422) {
          redirectToLogin();
        }
        break;

      case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_FAILED:
        if (action.error.statusCode === 401) {
          history.go(0);
        }
        break;
    }

    next(action);
  };

export default redirectMiddleware;
