import i18next from 'i18next';
import React from 'react';

import { BRICKS_URL } from 'src/configs/configs';

import './i18n';
import { BrickContainer, BrickImage, Container, Text } from './styles';
import IBrickCardProps from './types';

const BoxImageCard = (props: IBrickCardProps) => {
  const { id, quantity } = props;

  return (
    <Container elevation={2}>
      <BrickContainer>
        <BrickImage alt={`brick-${id}`} src={`${BRICKS_URL}/${id}.jpeg`} />
        <Text fontSize={14} fontWeight={400}>
          {`${i18next.t('BRICK_CARD:BRICK')}${id}`}
        </Text>
        <Text fontSize={18} fontWeight={600}>
          {`${i18next.t('BRICK_CARD:STOCK')}: ${quantity}`}
        </Text>
      </BrickContainer>
    </Container>
  );
};

export default BoxImageCard;
