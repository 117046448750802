import Person from '@mui/icons-material/Person';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.primary};
`;

export const InputsWrapper = styled.div`
  @media (max-width: 468px) {
    height: 40%;
  }
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  border-radius: 10px;
  padding: 40px;
  background-color: ${(props) => props.theme.palette.common.white};
`;

export const PShLogo = styled.img`
  height: 100px;
  width: 100px;
  align-self: center;
  margin-bottom: 20px;
`;

export const InputWrapper = styled.div`
  margin: 10px 0;
`;

export const ButtonWrapper = styled.div``;

export const PersonIcon = styled(Person)`
  color: ${(props) => props.theme.palette.primary};
`;
