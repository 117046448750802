import { AnyAction } from 'redux';

import IReducerInfo from 'src/types/reducer';

import * as setTypeConstants from '../constants/setType';

export interface ISetTypeReducerState {
  setType: string[];
  setTypeInfo: IReducerInfo;
}

const defaultState: ISetTypeReducerState = {
  setType: [],
  setTypeInfo: {
    info: {
      error: false,
      success: false,
      loading: false,
    },
    message: '',
  },
};

const setSetType = (state: ISetTypeReducerState, setType: string[]) => ({
  ...state,
  setType,
});

const setSetTypeInfo = (
  state: ISetTypeReducerState,
  error: boolean,
  success: boolean,
  loading: boolean,
  message: string,
) => {
  return {
    ...state,
    setTypeInfo: {
      info: {
        error,
        success,
        loading,
      },
      message,
    },
  };
};

const setTypeReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case setTypeConstants.SET_TYPE_ON_REQUESTED:
      return setSetTypeInfo(state, false, false, true, '');
    case setTypeConstants.SET_TYPE_ON_SUCCEEDED:
      return setSetTypeInfo(setSetType(state, action.setType), false, true, false, '');
    case setTypeConstants.SET_TYPE_ON_FAILED:
      return setSetTypeInfo(state, false, false, false, '');
    default:
      return state;
  }
};

export default setTypeReducer;
