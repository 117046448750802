import { GridColumns, GridRowModel } from '@mui/x-data-grid';
import i18next from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as boxActions from 'src/redux/actions/box';
import boxInfoSelector from 'src/redux/selectors/box/boxInfo';
import boxesListSelector from 'src/redux/selectors/box/boxesList';

import history from 'src/helpers/history';
import { useTypedSelector } from 'src/hooks/typeSelector';
import IReducerInfo from 'src/types/reducer';
import BoxesList from 'src/views/Boxes/BoxesList';

import LayoutContainer from '../Layout/Layout';
import './i18n';

const BoxesListContainer = () => {
  const dispatch = useDispatch();

  const routeParams = useRouteMatch<{ id: string }>();
  const boxes: GridRowModel[] = useTypedSelector(boxesListSelector());
  const boxInfo: IReducerInfo = useTypedSelector(boxInfoSelector());

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'set',
      headerName: i18next.t('BOXES:SET_LABEL'),
      flex: 1,
    },
    {
      field: 'institution',
      headerName: i18next.t('BOXES:INSTITUTION_LABEL'),
      flex: 1,
    },
  ];

  const onEdit = (rowId: string) => {
    console.log(rowId);

    history.push(`/boxes/edit/${rowId}`);
  };

  const onDeleteOne = (rowId: string) => {
    dispatch(boxActions.onDeleteOneRequested(rowId));
  };

  const onDeleteMany = (rowsId: string[]) => {
    dispatch(boxActions.onDeleteManyRequested(rowsId));
  };

  const onAddNew = () => {
    history.push({
      pathname: '/boxes/new',
    });
  };

  const onInitializeBoxesList = useCallback(() => {
    dispatch(boxActions.initializeBoxesListView(routeParams.params.id));
  }, [dispatch, routeParams.params.id]);

  useEffect(() => {
    onInitializeBoxesList();
  }, [onInitializeBoxesList]);

  return (
    <LayoutContainer>
      <BoxesList
        columns={columns}
        error={boxInfo.info.error ? boxInfo.message : null}
        isLoading={boxInfo.info.loading}
        boxes={boxes}
        onAddNew={onAddNew}
        hideAddNew={false}
        onEdit={onEdit}
        onDeleteOne={onDeleteOne}
        onDeleteMany={onDeleteMany}
        hideDeleteMany
      />
    </LayoutContainer>
  );
};

export default BoxesListContainer;
