import i18next from 'i18next';
import React from 'react';

import Table from 'src/components/Table';

import './i18n';
import { QrRequestsContainer, QrRequestsContent } from './styles';
import { IQrRequestsListProps } from './types';

const QrRequestsList = (props: IQrRequestsListProps) => {
  const {
    columns,
    error,
    isLoading,
    qrRequests,
    onAddNew,
    hideAddNew,
    onEdit,
    hideDeleteMany,
    onDeleteOne,
  } = props;

  return (
    <QrRequestsContainer data-testid="qr-requests-view">
      <QrRequestsContent>
        <Table
          columns={columns}
          error={error}
          rows={qrRequests}
          title={i18next.t('QR_REQUESTS_LIST:TITLE')}
          loading={isLoading}
          onAddNew={onAddNew}
          hideAddNew={hideAddNew}
          onEdit={onEdit}
          onDeleteOne={onDeleteOne}
          hideDeleteMany={hideDeleteMany}
        />
      </QrRequestsContent>
    </QrRequestsContainer>
  );
};

export default QrRequestsList;
