import { GridColumns, GridRowModel, GridSortItem } from '@mui/x-data-grid';
import i18next from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as userActions from 'src/redux/actions/user';
import userInfoSelector from 'src/redux/selectors/user/userInfo';
import usersListSelector from 'src/redux/selectors/user/usersList';

import history from 'src/helpers/history';
import { useTypedSelector } from 'src/hooks/typeSelector';
import IReducerInfo from 'src/types/reducer';
import UsersList from 'src/views/Users/UsersList';

import LayoutContainer from '../Layout/Layout';
import './i18n';

const UsersListContainer = () => {
  const dispatch = useDispatch();
  const routeParams = useRouteMatch<{ id?: string }>();

  const users: GridRowModel[] = useTypedSelector(usersListSelector(routeParams.params.id));
  const userInfo: IReducerInfo = useTypedSelector(userInfoSelector());

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: i18next.t('USERS:NAME_LABEL'),
      flex: 2,
    },
    {
      field: 'email',
      headerName: i18next.t('USERS:EMAIL_LABEL'),
      flex: 2,
    },
    {
      field: 'institution',
      headerName: i18next.t('USERS:INSTITUTION_LABEL'),
      flex: 1,
    },
  ];

  const sortModel: GridSortItem[] = [{ field: 'name', sort: 'asc' }];

  const onEdit = (rowId: string) => {
    history.push({
      pathname: `users/edit/${rowId}`,
    });
  };

  const onDeleteOne = (rowId: string) => {
    dispatch(userActions.onDeleteOneRequested(rowId));
  };

  const onDeleteMany = (rowsId: string[]) => {
    dispatch(userActions.onDeleteManyRequested(rowsId));
  };

  const onAddNew = () => {
    history.push({
      pathname: '/users/new',
    });
  };

  const onInitializeUsersList = useCallback(() => {
    dispatch(userActions.initializeUsersListView());
  }, [dispatch]);

  useEffect(() => {
    onInitializeUsersList();
  }, [onInitializeUsersList]);

  return (
    <LayoutContainer>
      <UsersList
        columns={columns}
        sortModel={sortModel}
        error={userInfo.info.error ? userInfo.message : null}
        isLoading={userInfo.info.loading}
        users={users}
        onAddNew={onAddNew}
        hideAddNew={false}
        onEdit={onEdit}
        onDeleteOne={onDeleteOne}
        onDeleteMany={onDeleteMany}
        hideDeleteMany
      />
    </LayoutContainer>
  );
};

export default UsersListContainer;
