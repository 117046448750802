import * as authConstants from 'src/redux/constants/auth';

import { IAPIResponse } from '../services/api';

export const loginRequested = (email: string, password: string) => {
  return {
    type: authConstants.USER_ON_LOGIN_REQUESTED,
    email,
    password,
  };
};

export const loginSucceded = (authToken: string, refreshToken: string) => {
  return {
    type: authConstants.USER_ON_LOGIN_SUCCEEDED,
    authToken,
    refreshToken,
  };
};

export const loginFailed = (error: IAPIResponse) => {
  return {
    type: authConstants.USER_ON_LOGIN_FAILED,
    error,
  };
};

export const initializeLogin = () => {
  return {
    type: authConstants.USER_ON_INITIALIZE_LOGIN_VIEW,
  };
};

export const logoutRequested = (email: string) => {
  return {
    type: authConstants.USER_ON_LOGOUT_REQUESTED,
    email,
  };
};

export const logoutSucceded = () => {
  return {
    type: authConstants.USER_ON_LOGOUT_SUCCEEDED,
  };
};

export const logoutFailed = (error: IAPIResponse) => {
  return {
    type: authConstants.USER_ON_LOGOUT_FAILED,
    error,
  };
};
