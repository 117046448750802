import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const qrRequestInfoSelector = () =>
  createSelector(
    [(state: IReduxState) => state.qrRequest.qrRequestInfo],
    (qrRequestInfo) => qrRequestInfo,
  );

export default qrRequestInfoSelector;
