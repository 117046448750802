import { AnyAction } from 'redux';
import { takeLatest, all, call, put } from 'redux-saga/effects';

import * as userActions from 'src/redux/actions/user';
import * as userConstants from 'src/redux/constants/user';
import * as userService from 'src/redux/services/user';

import { INewUser, IUser } from 'src/types/user';

import * as appConstants from '../constants/app';

function* listUsers() {
  try {
    const users: { brickoutUsers: IUser[] } = yield call(userService.getAll);
    yield put(userActions.initializeUsersListViewSucceeded(users.brickoutUsers));
  } catch (error: any) {
    yield put(userActions.initializeUsersListViewFailed(error));
  }
}

function* deleteUser(action: AnyAction) {
  try {
    yield call(userService.deleteOne, action.userId);
    yield put(userActions.onDeleteOneSucceeded(action.userId));
  } catch (error: any) {
    yield put(userActions.onDeleteOneFailed(error));
  }
}

function* deleteManyUsers(action: AnyAction) {
  try {
    const { countDeleted } = yield call(userService.deleteMany, action.userIds);
    yield put(userActions.onDeleteManySucceeded(action.userIds, countDeleted));
  } catch (error: any) {
    yield put(userActions.onDeleteManyFailed(error));
  }
}

function* getUserToEdit(action: AnyAction) {
  try {
    const user: { brickoutUser: IUser } = yield call(userService.getOne, action.userId);
    yield put(userActions.initializeUsersEditViewSucceeded(user.brickoutUser));
  } catch (error: any) {
    yield put(userActions.initializeUsersEditViewFailed(error));
  }
}

function* editUser(action: AnyAction) {
  try {
    const newUser: INewUser = action.newUser;
    if (!newUser.password) {
      delete newUser.password;
    }
    const editedUser: { brickoutUser: IUser } = yield call(
      userService.editOne,
      action.userId,
      newUser,
    );
    yield put(userActions.onEditOneSucceeded(editedUser.brickoutUser));
  } catch (error: any) {
    yield put(userActions.onEditOneFailed(error));
  }
}

function* createUser(action: AnyAction) {
  try {
    const newUser: INewUser = action.user;
    newUser.passwordConfirmation = action.user.password;
    yield call(userService.createOne, action.user);
    const users: { brickoutUsers: IUser[] } = yield call(userService.getAll);
    yield put(userActions.onCreateOneSucceeded(users.brickoutUsers));
  } catch (error: any) {
    yield put(userActions.onCreateOneFailed(error));
  }
}

export default all([
  takeLatest(userConstants.INITIALIZE_USERS_LIST_VIEW_REQUESTED, listUsers),
  takeLatest(userConstants.USERS_ON_DELETE_ONE_SUCCEEDED, listUsers),
  takeLatest(userConstants.INITIALIZE_USERS_EDIT_VIEW_REQUESTED, getUserToEdit),
  takeLatest(userConstants.USERS_ON_EDIT_ONE_REQUESTED, editUser),
  takeLatest(userConstants.USERS_ON_DELETE_ONE_REQUESTED, deleteUser),
  takeLatest(userConstants.USERS_ON_CREATE_ONE_REQUESTED, createUser),
  takeLatest(userConstants.USERS_ON_DELETE_MANY_REQUESTED, deleteManyUsers),
  takeLatest(appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_SUCCEDED, listUsers),
]);
