import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const qrRequestToEditSelector = () =>
  createSelector(
    [(state: IReduxState) => state.qrRequest.qrRequestToEdit],
    (qrRequest) => qrRequest,
  );

export default qrRequestToEditSelector;
