import { GridColumns, GridRowModel } from '@mui/x-data-grid';
import i18next from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as qrRequestActions from 'src/redux/actions/qrRequest';

import { useTypedSelector } from 'src/hooks/typeSelector';
import IReducerInfo from 'src/types/reducer';

import history from '../../helpers/history';
import qrRequestInfoSelector from '../../redux/selectors/qrRequests/qrRequestInfo';
import qrRequestsListSelector from '../../redux/selectors/qrRequests/qrRequestsList';
import { QrRequestStatus } from '../../types/qrRequest';
import QrRequestsList from '../../views/QrRequests/QrRequestsList';
import LayoutContainer from '../Layout/Layout';
import './i18n';

const QrRequestsListContainer = () => {
  const dispatch = useDispatch();

  const qrRequestStatusLabels: { [key in QrRequestStatus]: string } = {
    PENDING: i18next.t('QR_REQUESTS:STATUS_PENDING'),
    PROCESSED: i18next.t('QR_REQUESTS:STATUS_PROCESSED'),
  };

  const qrRequests: GridRowModel[] = useTypedSelector(qrRequestsListSelector()).map(
    (qrRequest) => ({
      ...qrRequest,
      status: qrRequestStatusLabels[qrRequest.status],
    }),
  );
  const qrRequestInfo: IReducerInfo = useTypedSelector(qrRequestInfoSelector());

  const columns: GridColumns = [
    {
      field: 'email',
      headerName: i18next.t('QR_REQUESTS:EMAIL_LABEL'),
      flex: 1,
    },
    {
      field: 'amount',
      headerName: i18next.t('QR_REQUESTS:AMOUNT_LABEL'),
      flex: 1,
    },
    {
      field: 'status',
      headerName: i18next.t('QR_REQUESTS:STATUS_LABEL'),
      flex: 1,
    },
  ];

  const onEdit = (rowId: string) => {
    history.push({
      pathname: `qr-requests/edit/${rowId}`,
    });
  };

  const onDeleteOne = (rowId: string) => {
    dispatch(qrRequestActions.onDeleteOneRequested(rowId));
  };
  const onAddNew = () => {};

  const onInitializeQrRequestsList = useCallback(() => {
    dispatch(qrRequestActions.initializeQrRequestsListView());
  }, [dispatch]);

  useEffect(() => {
    onInitializeQrRequestsList();
  }, [onInitializeQrRequestsList]);

  return (
    <LayoutContainer>
      <QrRequestsList
        columns={columns}
        error={qrRequestInfo.info.error ? qrRequestInfo.message : null}
        isLoading={qrRequestInfo.info.loading}
        qrRequests={qrRequests}
        onAddNew={onAddNew}
        hideAddNew
        onEdit={onEdit}
        onDeleteOne={onDeleteOne}
        hideDeleteMany
      />
    </LayoutContainer>
  );
};

export default QrRequestsListContainer;
