import { GridRowModel } from '@mui/x-data-grid';
import i18next from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as requestActions from 'src/redux/actions/request';
import processingRequest from 'src/redux/selectors/request/processingRequest';
import requestInfoSelector from 'src/redux/selectors/request/requestInfo';

import Loading from 'src/components/Loading';
import LayoutContainer from 'src/containers/Layout/Layout';
import { ModalType } from 'src/containers/ProcessingRequests/types';
import printQRs from 'src/helpers/printQR';
import { useTypedSelector } from 'src/hooks/typeSelector';
import IReducerInfo from 'src/types/reducer';
import ProcessingRequestsDetailsView from 'src/views/ProcessingRequests/ProcessingRequestsDetails';

import './i18n';
import { requestTableColumns } from './tableColumns';

const ProcessingRequestsDetails = () => {
  const dispatch = useDispatch();
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);
  const [boxImagesIndex, setBoxImagesIndex] = useState<number>(0);
  const [showPrintScreen, setShowPrintScreen] = useState<boolean>(true);
  const [showImages, setShowImages] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.CONFIRM);
  const routeParams = useRouteMatch<{ id: string }>();
  const requestInfo: IReducerInfo = useTypedSelector(requestInfoSelector());
  const imagesRequest = useTypedSelector((state) => state.request.requestToEdit?.boxImages);
  const requests: GridRowModel[] | any = useTypedSelector(processingRequest(routeParams.params.id));
  const requestoEdit = useTypedSelector((state) => state.request.requestToEdit);

  const handleSeeImagesButton = () => {
    setShowImages(!showImages);
  };

  const handlePrintLabel = async () => {
    setLoadingPDF(true);
    if (imagesRequest) {
      const labels = Array.from((document.getElementById('print_qr') as HTMLElement).children);
      if (labels.length) {
        await printQRs(
          labels,
          `${i18next.t('PROCESSING_REQUESTS_TABLE:LABELS')}_${i18next.t(
            'PROCESSING_REQUESTS_TABLE:REQUEST',
          )}_${routeParams.params.id}`,
        );
      }
    }
    setLoadingPDF(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const onInitializeProcessingRequestsDetails = useCallback(() => {
    dispatch(requestActions.getRequestState(routeParams.params.id));
    dispatch(requestActions.initializeRequestsEditView(routeParams.params.id));
    if (!requests) {
      dispatch(requestActions.initializeRequestsListView('PROCESSING'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, routeParams.params.id]);

  useEffect(() => {
    onInitializeProcessingRequestsDetails();
  }, [onInitializeProcessingRequestsDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showPrintScreen, boxImagesIndex]);

  useEffect(() => {
    if (imagesRequest) {
      for (let i = 0; i < imagesRequest.length; i++) {
        if (imagesRequest[i].accepted === null && !requestInfo.info.loading) {
          setBoxImagesIndex(i);
          setShowPrintScreen(i === 0);
          break;
        }
        if (
          imagesRequest[i].accepted !== null &&
          i === imagesRequest.length - 1 &&
          requestoEdit &&
          !requestInfo.info.loading
        ) {
          dispatch(
            requestActions.onEditOneRequested(
              requestoEdit?.id,
              { status: 'COMPLETED', brickoutUserId: requestoEdit.brickoutUser.id },
              true,
            ),
          );
        }
      }
    }
  }, [dispatch, imagesRequest, requestInfo, requestoEdit]);

  if (imagesRequest && imagesRequest.length > 0) {
    const handleRejectModal = () => {
      setModalType(ModalType.REJECT);
      setShowModal(true);
    };

    const handleContinueModal = () => {
      setModalType(ModalType.CONTINUE);
      setShowModal(true);
    };

    const handleContinue = () => {
      setShowPrintScreen(false);
      setShowModal(false);
    };

    const handleReject = () => {
      dispatch(
        requestActions.updateAcceptedFieldBoxImages({
          boxImageId: imagesRequest[boxImagesIndex].id,
          accepted: false,
        }),
      );
      setShowModal(false);
      setShowImages(false);
    };

    const handleConfirm = () => {
      dispatch(
        requestActions.updateAcceptedFieldBoxImages({
          boxImageId: imagesRequest[boxImagesIndex].id,
          accepted: true,
        }),
      );
      setShowImages(false);
    };

    const handleModal = () => {
      switch (modalType) {
        case ModalType.REJECT:
          handleReject();
          break;
        case ModalType.CONTINUE:
          handleContinue();
          break;
        default:
          break;
      }
    };

    return (
      <LayoutContainer>
        <ProcessingRequestsDetailsView
          modalType={modalType}
          showModal={showModal}
          columns={requestTableColumns}
          requests={requests}
          error={requestInfo.info.error ? requestInfo.message : null}
          isLoading={requestInfo.info.loading}
          loadingPDF={loadingPDF}
          boxId={imagesRequest[boxImagesIndex].box.id}
          imagesRequest={imagesRequest}
          boxImagesIndex={boxImagesIndex}
          showImages={showImages}
          showPrintScreen={showPrintScreen}
          handleContinueModal={handleContinueModal}
          handleSeeImagesButton={handleSeeImagesButton}
          handlePrintLabel={handlePrintLabel}
          handleConfirm={handleConfirm}
          handleRejectModal={handleRejectModal}
          handleModal={handleModal}
          handleClose={handleClose}
        />
      </LayoutContainer>
    );
  }

  return (
    <LayoutContainer>
      <Loading open text={i18next.t('PROCESSING_REQUESTS_TABLE:LOADING')} />
    </LayoutContainer>
  );
};

export default ProcessingRequestsDetails;
