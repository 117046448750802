import styled from 'styled-components';

export const ProcessingRequestsDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
`;

export const ProcessingRequestsTableContent = styled.div`
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
`;

export const ProcessingRequestsDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 20px;
`;

export const Row1 = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  width: auto;
  display: flex;
  margin-left: 20px;
`;

export const Row2Bricks = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 30px;
  flex-direction: row;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-content: center;
`;

export const Row2Image = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 30px;
  flex-direction: row;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
`;

export const BrickCardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Row3 = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LeftButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
`;

export const Image = styled.img`
  width: 500px;
`;

export const ImagesFooterText = styled.div`
  margin-top: 30px;
  width: 100%;
  text-align: center;
`;

export const Info = styled.div`
  font-size: 24px;
`;
