import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'BRICK_CARD', {
  BRICK: '',
  STOCK: 'Quantity',
});

i18next.addResources(locales.ES, 'BRICK_CARD', {
  BRICK: '',
  STOCK: 'Cantidad',
});
