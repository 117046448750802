import i18next from 'i18next';
import * as Yup from 'yup';

import './i18n';

export const validationSchema = Yup.object({
  firstName: Yup.string().required(i18next.t('USERS_EDIT:FIRST_NAME_REQUIRED')).trim(),
  lastName: Yup.string().required(i18next.t('USERS_EDIT:LAST_NAME_REQUIRED')).trim(),
  email: Yup.string()
    .email(i18next.t('USERS_EDIT:EMAIL_INVALID'))
    .required(i18next.t('USERS_EDIT:EMAIL_REQUIRED'))
    .trim(),
  password: Yup.string().required(i18next.t('USERS_EDIT:PASSWORD_REQUIRED')).trim(),
});
