import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Text = styled.h2`
  color: ${(props) => props.theme.palette.secondary};
  margin-top: 20px;
`;

export const LoadingProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.secondary} !important;
`;
