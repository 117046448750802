import { INewBox } from 'src/types/box';

import { apiDelete, get, post, put } from './api';

export const getAll = (institution?: string) => {
  return get(`boxes${institution ? '?institution=' + institution : ''}`);
};

export const deleteMany = (boxIds: string[]) => {
  return apiDelete('boxes', {}, { boxIds });
};

export const getOne = (boxId: string) => {
  return get(`boxes/${boxId}`);
};

export const deleteOne = (boxId: string) => {
  return apiDelete(`boxes/${boxId}`);
};

export const editOne = (boxId: string, newBox: INewBox) => {
  return put(`boxes/${boxId}`, newBox);
};

export const createOne = (newBox: INewBox) => {
  return post('boxes', { ...newBox });
};
