import { TextField } from '@mui/material';
import styled from 'styled-components';

export const Input = styled(TextField)`
  .MuiInputLabel-root {
    color: ${(props) => props.theme.palette.primary};
  }
  label.Mui-focused {
    color: ${(props) => props.theme.palette.primary};
  }
  label.Mui-focused {
    color: ${(props) => props.theme.palette.primary};
  }
  .MuiFormLabel-filled {
    color: ${(props) => props.theme.palette.primary};
  }
  .MuiInput-root {
    color: ${(props) => props.theme.palette.primary};
  }
  .MuiInputBase-input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${(props) => props.theme.palette.primary};
      background-color: ${(props) => props.theme.palette.primary};
      background-clip: content-box !important;
    }
  }
`;
