import i18next from 'i18next';
import React from 'react';

import Table from 'src/components/Table';

import './i18n';
import { BoxesContainer, BoxesContent } from './styles';
import { IBoxesListProps } from './types';

const BoxesList = (props: IBoxesListProps) => {
  const {
    columns,
    error,
    isLoading,
    boxes,
    onAddNew,
    hideAddNew,
    onEdit,
    onDeleteMany,
    hideDeleteMany,
    onDeleteOne,
  } = props;

  return (
    <BoxesContainer data-testid="boxes-view">
      <BoxesContent>
        <Table
          columns={columns}
          error={error}
          rows={boxes}
          title={i18next.t('BOXES_LIST:TITLE')}
          loading={isLoading}
          onAddNew={onAddNew}
          hideAddNew={hideAddNew}
          onEdit={onEdit}
          onDeleteOne={onDeleteOne}
          onDeleteMany={onDeleteMany}
          hideDeleteMany={hideDeleteMany}
        />
      </BoxesContent>
    </BoxesContainer>
  );
};

export default BoxesList;
