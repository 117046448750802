import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18next from 'i18next';
import React from 'react';

import { ContentInterface, ReportInterface } from 'src/types/request';

import BrickCard from '../BrickCard';
import './i18n';
import { ReportDetails, Details, Collapse, Summary, Text } from './styles';
import IBoxImageCollapseProps from './types';

const renderReportContent = (
  id: string,
  title: string,
  keys: string[],
  report: ContentInterface,
) => {
  if (keys.length === 0) return null;
  return (
    <Collapse>
      <Summary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${id}-missing`}
        id={`panel${id}-header-missing`}
      >
        <Text fontSize={16} fontWeight={500}>
          {title}
        </Text>
      </Summary>
      <Details>
        {keys.map((key) => (
          <BrickCard key={key} id={key} quantity={report[key]} />
        ))}
      </Details>
    </Collapse>
  );
};

const renderReport = (id: string, report: ReportInterface) => {
  const missing = Object.keys(report.missing);
  const extra = Object.keys(report.extra);
  const correct = Object.keys(report.correct);
  const isProcessed = missing.length || extra.length || correct.length;

  if (!isProcessed) {
    return (
      <Text fontSize={18} fontWeight={500}>
        {i18next.t('BOX_IMAGE_ACCORDION:NOT_PROCESSED')}
      </Text>
    );
  }
  return (
    <>
      {renderReportContent(id, i18next.t('BOX_IMAGE_ACCORDION:MISSING'), missing, report.missing)}
      {renderReportContent(id, i18next.t('BOX_IMAGE_ACCORDION:EXTRA'), extra, report.extra)}
      {renderReportContent(id, i18next.t('BOX_IMAGE_ACCORDION:CORRECT'), correct, report.correct)}
    </>
  );
};

const BoxImageCollapse = (props: IBoxImageCollapseProps) => {
  const { id, report } = props;

  return (
    <Collapse>
      <Summary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${id}-content`}
        id={`panel${id}-header`}
      >
        <Text fontSize={20} fontWeight={600}>
          {id}
        </Text>
      </Summary>
      <ReportDetails>{renderReport(id, report)}</ReportDetails>
    </Collapse>
  );
};

export default BoxImageCollapse;
