import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'CONFIRMATION_DIALOG', {
  CANCEL: 'Cancel',
  CONFIRM: 'Confirm',
  DEFAULT_TITLE: 'Confirm the action',
});

i18next.addResources(locales.ES, 'CONFIRMATION_DIALOG', {
  CANCEL: 'No',
  CONFIRM: 'Sí',
  DEFAULT_TITLE: 'Confirma la acción',
});
