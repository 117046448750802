import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'QR_REQUESTS', {
  EMAIL_LABEL: 'Email',
  AMOUNT_LABEL: 'Quantity',
  STATUS_LABEL: 'Status',
  STATUS_PENDING: 'Pending',
  STATUS_PROCESSED: 'Processed',
});

i18next.addResources(locales.ES, 'QR_REQUESTS', {
  EMAIL_LABEL: 'Correo electrónico',
  AMOUNT_LABEL: 'Cantidad',
  STATUS_LABEL: 'Estado',
  STATUS_PENDING: 'Pendiente',
  STATUS_PROCESSED: 'Procesado',
});
