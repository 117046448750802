import * as requestConstants from 'src/redux/constants/request';

import { IRequest, INewRequest, IBoxImage, ISingleRequestReport } from 'src/types/request';

import { IAPIResponse } from '../services/api';

export const initializeRequestsListView = (status?: string) => ({
  type: requestConstants.INITIALIZE_REQUESTS_LIST_VIEW_REQUESTED,
  status,
});

export const initializeRequestsListViewSucceeded = (payload: any) => ({
  type: requestConstants.INITIALIZE_REQUESTS_LIST_VIEW_SUCCEEDED,
  payload,
});

export const initializeRequestsListViewFailed = (error: IAPIResponse) => ({
  type: requestConstants.INITIALIZE_REQUESTS_LIST_VIEW_FAILED,
  error,
});

export const initializeRequestsEditView = (requestId: string) => ({
  type: requestConstants.INITIALIZE_REQUESTS_EDIT_VIEW_REQUESTED,
  requestId,
});

export const reprocessRequest = (requestId: string) => ({
  type: requestConstants.REPROCESS_REQUEST,
  requestId,
});

export const reprocessRequestSucceeded = (payload: any) => ({
  type: requestConstants.REPROCESS_REQUEST_SUCCEEDED,
  payload,
});

export const reprocessRequestFailed = (error: IAPIResponse) => ({
  type: requestConstants.REPROCESS_REQUEST_FAILED,
  error,
});

export const initializeRequestsEditViewSucceeded = (request: IRequest) => ({
  type: requestConstants.INITIALIZE_REQUESTS_EDIT_VIEW_SUCCEEDED,
  request,
});

export const initializeRequestsEditViewFailed = (error: IAPIResponse) => ({
  type: requestConstants.INITIALIZE_REQUESTS_EDIT_VIEW_FAILED,
  error,
});

export const onDeleteOneRequested = (requestId: string) => ({
  type: requestConstants.REQUESTS_ON_DELETE_ONE_REQUESTED,
  requestId,
});

export const onDeleteOneSucceeded = (requestId: string) => ({
  type: requestConstants.REQUESTS_ON_DELETE_ONE_SUCCEEDED,
  requestId,
});

export const onDeleteOneFailed = (error: IAPIResponse) => ({
  type: requestConstants.REQUESTS_ON_DELETE_ONE_FAILED,
  error,
});

export const onDeleteManyRequested = (requestIds: string[]) => ({
  type: requestConstants.REQUESTS_ON_DELETE_MANY_REQUESTED,
  requestIds,
});

export const onDeleteManySucceeded = (requestIds: string[], countDeleted: number) => ({
  type: requestConstants.REQUESTS_ON_DELETE_MANY_SUCCEEDED,
  requestIds,
  countDeleted,
});

export const onDeleteManyFailed = (error: IAPIResponse) => ({
  type: requestConstants.REQUESTS_ON_DELETE_MANY_FAILED,
  error,
});

export const onEditOneRequested = (
  requestId: number,
  newRequest: INewRequest,
  inRequestsInProgress?: boolean,
) => ({
  type: requestConstants.REQUESTS_ON_EDIT_ONE_REQUESTED,
  requestId,
  newRequest,
  inRequestsInProgress,
});

export const onEditOneSucceeded = (request: IRequest, inRequestsInProgress?: boolean) => ({
  type: requestConstants.REQUESTS_ON_EDIT_ONE_SUCCEEDED,
  request,
  inRequestsInProgress,
});

export const onEditOneFailed = (error: IAPIResponse) => ({
  type: requestConstants.REQUESTS_ON_EDIT_ONE_FAILED,
  error,
});

export const updateAcceptedFieldBoxImages = (payload: {
  boxImageId: string;
  accepted: boolean;
}) => ({
  type: requestConstants.UPDATE_ACCEPTED_FIELD_BOXIMAGES,
  payload,
});

export const updateAcceptedFieldBoxImagesSucceeded = (newBoxImage: IBoxImage) => ({
  type: requestConstants.UPDATE_ACCEPTED_FIELD_BOXIMAGES_SUCCEEDED,
  newBoxImage,
});

export const updateAcceptedFieldBoxImagesFailed = () => ({
  type: requestConstants.UPDATE_ACCEPTED_FIELD_BOXIMAGES_FAILED,
});

export const getRequestState = (requestId: string) => ({
  type: requestConstants.GET_REQUEST_STATE,
  requestId,
});

export const getRequestStateSucceeded = (report: ISingleRequestReport) => ({
  type: requestConstants.GET_REQUEST_STATE_SUCCEEDED,
  report,
});

export const getRequestStateFailed = () => ({
  type: requestConstants.GET_REQUEST_STATE_FAILED,
});
