import * as boxConstants from 'src/redux/constants/box';

import { IBox, INewBox } from 'src/types/box';

import { IAPIResponse } from '../services/api';

export const initializeBoxesListView = (institution?: string) => ({
  type: boxConstants.INITIALIZE_BOXES_LIST_VIEW_REQUESTED,
  institution,
});

export const initializeBoxesListViewSucceeded = (boxes: IBox[]) => ({
  type: boxConstants.INITIALIZE_BOXES_LIST_VIEW_SUCCEEDED,
  boxes,
});

export const initializeBoxesListViewFailed = (error: IAPIResponse) => ({
  type: boxConstants.INITIALIZE_BOXES_LIST_VIEW_FAILED,
  error,
});

export const initializeBoxesEditView = (boxId: string) => ({
  type: boxConstants.INITIALIZE_BOXES_EDIT_VIEW_REQUESTED,
  boxId,
});

export const initializeBoxesEditViewSucceeded = (box: IBox) => ({
  type: boxConstants.INITIALIZE_BOXES_EDIT_VIEW_SUCCEEDED,
  box,
});

export const initializeBoxesEditViewFailed = (error: IAPIResponse) => ({
  type: boxConstants.INITIALIZE_BOXES_EDIT_VIEW_FAILED,
  error,
});

export const onDeleteOneRequested = (boxId: string) => ({
  type: boxConstants.BOXES_ON_DELETE_ONE_REQUESTED,
  boxId,
});

export const onDeleteOneSucceeded = (boxId: string) => ({
  type: boxConstants.BOXES_ON_DELETE_ONE_SUCCEEDED,
  boxId,
});

export const onDeleteOneFailed = (error: IAPIResponse) => ({
  type: boxConstants.BOXES_ON_DELETE_ONE_FAILED,
  error,
});

export const onDeleteManyRequested = (boxIds: string[]) => ({
  type: boxConstants.BOXES_ON_DELETE_MANY_REQUESTED,
  boxIds,
});

export const onDeleteManySucceeded = (boxIds: string[], countDeleted: number) => ({
  type: boxConstants.BOXES_ON_DELETE_MANY_SUCCEEDED,
  boxIds,
  countDeleted,
});

export const onDeleteManyFailed = (error: IAPIResponse) => ({
  type: boxConstants.BOXES_ON_DELETE_MANY_FAILED,
  error,
});

export const onEditOneRequested = (boxId: string, newBox: INewBox) => ({
  type: boxConstants.BOXES_ON_EDIT_ONE_REQUESTED,
  boxId,
  newBox,
});

export const onEditOneSucceeded = (box: IBox) => ({
  type: boxConstants.BOXES_ON_EDIT_ONE_SUCCEEDED,
  box,
});

export const onEditOneFailed = (error: IAPIResponse) => ({
  type: boxConstants.BOXES_ON_EDIT_ONE_FAILED,
  error,
});

export const onCreateOneRequested = (box: INewBox) => ({
  type: boxConstants.BOXES_ON_CREATE_ONE_REQUESTED,
  box,
});

export const onCreateOneSucceeded = (boxes: IBox[]) => ({
  type: boxConstants.BOXES_ON_CREATE_ONE_SUCCEEDED,
  boxes,
});

export const onCreateOneFailed = (error: IAPIResponse) => ({
  type: boxConstants.BOXES_ON_CREATE_ONE_FAILED,
  error,
});
