import { InputLabel, MenuItem, Select } from '@mui/material';
import styled from 'styled-components';

export const Input = styled(Select)`
  .MuiInput-root {
    color: ${(props) => props.theme.palette.primary};
  }
`;

export const InputOption = styled(MenuItem)``;

export const Label = styled(InputLabel)`
  color: ${(props) => props.theme.palette.primary} !important;
`;
