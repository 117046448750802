import i18next from 'i18next';
import React from 'react';

import Table from 'src/components/Table';

import './i18n';
import { InstitutionsContainer, InstitutionsContent } from './styles';
import { IInstitutionsListProps } from './types';

const InstitutionsList = (props: IInstitutionsListProps) => {
  const {
    columns,
    error,
    isLoading,
    institutions,
    onAddNew,
    hideAddNew,
    onEdit,
    onDeleteMany,
    hideDeleteMany,
    onDeleteOne,
  } = props;

  return (
    <InstitutionsContainer data-testid="institutions-view">
      <InstitutionsContent>
        <Table
          columns={columns}
          error={error}
          rows={institutions}
          title={i18next.t('INSTITUTIONS_LIST:TITLE')}
          loading={isLoading}
          onAddNew={onAddNew}
          hideAddNew={hideAddNew}
          onEdit={onEdit}
          onDeleteOne={onDeleteOne}
          onDeleteMany={onDeleteMany}
          hideDeleteMany={hideDeleteMany}
        />
      </InstitutionsContent>
    </InstitutionsContainer>
  );
};

export default InstitutionsList;
