import { takeLatest, all, call, put } from 'redux-saga/effects';

import * as rolesActions from 'src/redux/actions/roles';
import * as appConstants from 'src/redux/constants/app';
import * as rolesConstants from 'src/redux/constants/roles';
import * as rolesService from 'src/redux/services/roles';

function* getRoles() {
  try {
    const roles: { roles: string[] } = yield call(rolesService.getRoles);
    yield put(rolesActions.onGetSucceeded(roles.roles));
  } catch (error: any) {
    yield put(rolesActions.onGetFailed(error));
  }
}

export default all([
  takeLatest(appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_SUCCEDED, getRoles),
  takeLatest(rolesConstants.ROLES_ON_REQUESTED, getRoles),
]);
