import { IUser } from './user';

export enum QrRequestStatus {
  PENDING = 'PENDING',
  PROCESSED = 'PROCESSED',
}

export interface IQrRequest {
  id: number;
  user: IUser;
  amount: number;
  status: QrRequestStatus;
}

export interface INewQrRequest {
  userId: string;
  amount: number;
  status: QrRequestStatus;
}
