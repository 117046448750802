import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const requestsListSelector = () =>
  createSelector([(state: IReduxState) => state.request.requests], (requests) => {
    if (!requests) return [];
    return requests.map((r) => ({
      id: r.id,
      status: r.status,
      institution: r.brickoutUser.institution.name,
      user: `${r.brickoutUser.firstName} ${r.brickoutUser.lastName}`,
      dateCreated: r.dateCreated,
      allBoxesProcessed: r.allBoxesProcessed,
    }));
  });

export default requestsListSelector;
