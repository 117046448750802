import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const qrRequestsListSelector = () =>
  createSelector([(state: IReduxState) => state.qrRequest.qrRequests], (qrRequests) => {
    if (!qrRequests) return [];
    return qrRequests.map((i) => ({
      id: i.id,
      email: i.user.email,
      amount: i.amount,
      status: i.status,
    }));
  });

export default qrRequestsListSelector;
