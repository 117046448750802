import { InputAdornment } from '@mui/material';
import React, { ChangeEvent } from 'react';

import { Input } from './styles';
import { ITextInputProps } from './types';

const TextInput = (props: ITextInputProps) => {
  const {
    adornment = null,
    error = '',
    isDisabled,
    id,
    name,
    placeholder,
    value,
    onChange,
  } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  return (
    <Input
      data-testid="text-field"
      inputProps={{ 'data-testid': 'input' }}
      fullWidth
      error={error !== ''}
      id={id}
      name={name}
      label={placeholder}
      helperText={error}
      disabled={isDisabled}
      value={value}
      type="text"
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <>{adornment && <InputAdornment position="start">{adornment}</InputAdornment>}</>
        ),
      }}
    />
  );
};

export default TextInput;
