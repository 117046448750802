import { INewQrRequest } from '../../types/qrRequest';
import { apiDelete, get, put } from './api';

export const getAll = () => {
  return get('qr-requests');
};
export const getOne = (qrRequestId: string) => {
  return get(`qr-requests/${qrRequestId}`);
};
export const editOne = (qrRequestId: string, newQrRequest: INewQrRequest) => {
  return put(`qr-requests/${qrRequestId}`, newQrRequest);
};
export const deleteOne = (qrRequestId: string) => {
  return apiDelete(`qr-requests/${qrRequestId}`);
};
