import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'USERS', {
  NAME_LABEL: 'Name',
  EMAIL_LABEL: 'Email',
  ROLE_LABEL: 'Role',
  INSTITUTION_LABEL: 'Institution',
});

i18next.addResources(locales.ES, 'USERS', {
  NAME_LABEL: 'Nombre',
  EMAIL_LABEL: 'Email',
  ROLE_LABEL: 'Rol',
  INSTITUTION_LABEL: 'Institución',
});
