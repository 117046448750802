import { useFormik } from 'formik';
import i18next from 'i18next';
import React, { useEffect } from 'react';

import TextButton from 'src/components/Buttons/TextButton';
import Form from 'src/components/Form';
import { IFormInput } from 'src/components/Form/types';
import TextInput from 'src/components/Inputs/TextInput';
import Loading from 'src/components/Loading';

import './i18n';
import { FormWrapper, Title, Text, ButtonContainer } from './styles';
import { IInstitutionsEditProps } from './types';
import { validationSchema } from './validations';

const InstitutionsEdit = (props: IInstitutionsEditProps) => {
  const { boxes, institution, loading, onSubmit, handleCreateBox, handleViewBox, handleViewUser } =
    props;

  const config = useFormik({
    initialValues: {
      id: institution.id,
      name: institution.name,
      street: institution.street,
      streetNumber: institution.streetNumber,
      door: institution.door,
      zipCode: institution.zipCode,
      population: institution.population,
      province: institution.province,
      phoneNumber: institution.phoneNumber,
      clientId: institution.clientId,
    },
    validationSchema,
    onSubmit: () => {
      onSubmit(institution.id, config.values);
    },
  });

  const inputs: IFormInput[] = [
    {
      key: 'id',
      input: (
        <Text marginBottom={8}>{`${i18next.t('INSTITUTIONS_EDIT:ID_LABEL')}: ${
          institution.id
        }`}</Text>
      ),
    },
    {
      key: 'id',
      input: (
        <Text marginBottom={8}>
          {i18next.t('INSTITUTIONS_EDIT:BOXES')}: {boxes}
        </Text>
      ),
    },
    {
      key: 'name',
      input: (
        <TextInput
          id="name"
          name="name"
          placeholder={i18next.t('INSTITUTIONS_EDIT:NAME_LABEL')}
          error={config.touched.name && Boolean(config.errors.name) ? config.errors.name : ''}
          value={config.values.name}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'street',
      input: (
        <TextInput
          id="street"
          name="street"
          placeholder={i18next.t('INSTITUTIONS_EDIT:STREET_LABEL')}
          error={config.touched.street && Boolean(config.errors.street) ? config.errors.street : ''}
          value={config.values.street}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'streetNumber',
      input: (
        <TextInput
          id="streetNumber"
          name="streetNumber"
          placeholder={i18next.t('INSTITUTIONS_EDIT:STREET_NUMBER_LABEL')}
          error={
            config.touched.streetNumber && Boolean(config.errors.streetNumber)
              ? config.errors.streetNumber
              : ''
          }
          value={config.values.streetNumber}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'door',
      input: (
        <TextInput
          id="door"
          name="door"
          placeholder={i18next.t('INSTITUTIONS_EDIT:DOOR_LABEL')}
          error={config.touched.door && Boolean(config.errors.door) ? config.errors.door : ''}
          value={config.values.door}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'zipCode',
      input: (
        <TextInput
          id="zipCode"
          name="zipCode"
          placeholder={i18next.t('INSTITUTIONS_EDIT:ZIP_CODE_LABEL')}
          error={
            config.touched.zipCode && Boolean(config.errors.zipCode) ? config.errors.zipCode : ''
          }
          value={config.values.zipCode}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'population',
      input: (
        <TextInput
          id="population"
          name="population"
          placeholder={i18next.t('INSTITUTIONS_EDIT:POPULATION_LABEL')}
          error={
            config.touched.population && Boolean(config.errors.population)
              ? config.errors.population
              : ''
          }
          value={config.values.population}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'province',
      input: (
        <TextInput
          id="province"
          name="province"
          placeholder={i18next.t('INSTITUTIONS_EDIT:PROVINCE_LABEL')}
          error={
            config.touched.province && Boolean(config.errors.province) ? config.errors.province : ''
          }
          value={config.values.province}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'phoneNumber',
      input: (
        <TextInput
          id="phoneNumber"
          name="phoneNumber"
          placeholder={i18next.t('INSTITUTIONS_EDIT:PHONE_LABEL')}
          error={
            config.touched.phoneNumber && Boolean(config.errors.phoneNumber)
              ? config.errors.phoneNumber
              : ''
          }
          value={config.values.phoneNumber}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'clientId',
      input: (
        <TextInput
          id="clientId"
          name="clientId"
          placeholder={i18next.t('INSTITUTIONS_EDIT:CLIENT_ID_LABEL')}
          error={
            config.touched.clientId && Boolean(config.errors.clientId) ? config.errors.clientId : ''
          }
          value={config.values.clientId}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
  ];

  useEffect(() => {
    config.setValues({
      id: institution.id,
      name: institution.name,
      street: institution.street,
      streetNumber: institution.streetNumber,
      door: institution.door,
      zipCode: institution.zipCode,
      population: institution.population,
      province: institution.province,
      phoneNumber: institution.phoneNumber,
      clientId: institution.clientId,
    });
    // eslint-disable-next-line
  }, [institution]);

  return (
    <FormWrapper>
      <Loading open={loading} text={i18next.t(`INSTITUTIONS_EDIT:LOADING`)} />
      <Title>{i18next.t('INSTITUTIONS_EDIT:TITLE')}</Title>
      <Form inputs={inputs} />
      <ButtonContainer>
        <div>
          <TextButton onSubmit={handleCreateBox} text={i18next.t('INSTITUTIONS_EDIT:CREATE_BOX')} />
          <TextButton onSubmit={handleViewBox} text={i18next.t('INSTITUTIONS_EDIT:SEE_BOXES')} />
          <TextButton onSubmit={handleViewUser} text={i18next.t('INSTITUTIONS_EDIT:SEE_USERS')} />
        </div>
        <TextButton
          text={i18next.t('INSTITUTIONS_EDIT:SUBMIT_BUTTON')}
          isLoading={loading}
          onSubmit={config.handleSubmit}
        />
      </ButtonContainer>
    </FormWrapper>
  );
};

export default InstitutionsEdit;
