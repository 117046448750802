import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'BOXES_PREVIEW', {
  TITLE: 'CREATED BOXES',
  DOWNLOAD: 'DOWNLOAD',
  LOADING_PDF: 'Generating PDF',
});

i18next.addResources(locales.ES, 'BOXES_PREVIEW', {
  TITLE: 'CAJAS CREADAS',
  DOWNLOAD: 'DESCARGAR',
  LOADING_PDF: 'Generando PDF',
});
