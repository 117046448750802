import i18next from 'i18next';
import React from 'react';

import AddActionButton from '../Add';
import DeleteActionButton from '../Delete';
import './i18n';
import { ActionButtonsWrapper, Title, ToolbarWrapper } from './styles';
import { IToolbarProps } from './types';

const Toolbar = (props: IToolbarProps) => {
  const { title, onAddNew, onBulkDelete, disabledBulkDelete, hideAddNew, hideBulkDelete } = props;

  return (
    <ToolbarWrapper>
      <Title>{title}</Title>
      <ActionButtonsWrapper>
        {!hideAddNew && (
          <AddActionButton onClick={onAddNew} label={i18next.t('TABLE_TOOLBAR:ADD')} />
        )}
        {!hideBulkDelete && (
          <DeleteActionButton
            onClick={onBulkDelete}
            label={i18next.t('TABLE_TOOLBAR:BULK_DELETE')}
            disabled={disabledBulkDelete}
          />
        )}
      </ActionButtonsWrapper>
    </ToolbarWrapper>
  );
};

export default Toolbar;
