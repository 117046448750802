import React from 'react';

import BoxQR from 'src/components/BoxQR';

import { PrintContainer } from './styles';
import { ImagesRequestQrProps } from './types';

const ImagesRequestQr = ({ imagesRequest }: ImagesRequestQrProps) => {
  return (
    <PrintContainer id={'print_qr'}>
      {imagesRequest.map((imageRequest) => (
        <BoxQR
          key={`${imageRequest.box.id}-${imageRequest.id}`}
          id={imageRequest.box.id}
          clientId={imageRequest.box.institution.clientId}
          institution={imageRequest.box.institution.name}
          address={`${imageRequest.box.institution.street} ${imageRequest.box.institution.streetNumber} ${imageRequest.box.institution.door}`}
          address2={`${imageRequest.box.institution.population} ${imageRequest.box.institution.province} ${imageRequest.box.institution.zipCode}`}
        />
      ))}
    </PrintContainer>
  );
};

export default ImagesRequestQr;
