import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'BOX_IMAGE_CONTAINER', {
  TITLE: 'Boxes',
});

i18next.addResources(locales.ES, 'BOX_IMAGE_CONTAINER', {
  TITLE: 'Cajas',
});
