import { GridRowModel } from '@mui/x-data-grid';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as boxActions from 'src/redux/actions/box';
import * as institutionActions from 'src/redux/actions/institution';
import boxInfoSelector from 'src/redux/selectors/box/boxInfo';
import boxesListSelector from 'src/redux/selectors/box/boxesList';
import institutionInfoSelector from 'src/redux/selectors/institution/institutionInfo';
import institutionToEditSelector from 'src/redux/selectors/institution/institutionToEdit';

import history from 'src/helpers/history';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { INewInstitution } from 'src/types/institution';
import IReducerInfo from 'src/types/reducer';
import InstitutionsEdit from 'src/views/Institutions/InstitutionsEdit';

import LayoutContainer from '../Layout/Layout';

const InstitutionsEditContainer = () => {
  const dispatch = useDispatch();
  const routeParams = useRouteMatch<{ id: string }>();

  const boxes: GridRowModel[] = useTypedSelector(boxesListSelector());
  const boxInfo: IReducerInfo = useTypedSelector(boxInfoSelector());
  const institutionToEdit = useSelector(institutionToEditSelector());
  const institutionInfo = useTypedSelector(institutionInfoSelector());
  const onSubmit = (id: number, institution: INewInstitution) => {
    dispatch(institutionActions.onEditOneRequested(id, institution));
  };

  const handleCreateBox = () => {
    history.push(`/boxes/new/${routeParams.params.id}`);
  };

  const handleViewBox = () => {
    history.push(`/boxes/institution/${routeParams.params.id}`);
  };

  const handleViewUser = () => {
    history.push(`/users/institution/${routeParams.params.id}`);
  };

  const onInitializeInstitutionsEdit = useCallback(() => {
    dispatch(institutionActions.initializeInstitutionsEditView(routeParams.params.id));
  }, [dispatch, routeParams.params.id]);

  useEffect(() => {
    onInitializeInstitutionsEdit();
  }, [onInitializeInstitutionsEdit]);

  const onInitializeBoxesList = useCallback(() => {
    dispatch(boxActions.initializeBoxesListView(routeParams.params.id));
  }, [dispatch, routeParams.params.id]);

  useEffect(() => {
    onInitializeBoxesList();
  }, [onInitializeBoxesList]);

  if (!institutionToEdit) return null;

  return (
    <LayoutContainer>
      <InstitutionsEdit
        institution={institutionToEdit}
        boxes={boxes.length}
        loading={institutionInfo.info.loading || boxInfo.info.loading}
        onSubmit={onSubmit}
        handleCreateBox={handleCreateBox}
        handleViewBox={handleViewBox}
        handleViewUser={handleViewUser}
      />
    </LayoutContainer>
  );
};

export default InstitutionsEditContainer;
