import { Modal } from '@mui/material';
import React from 'react';

import { Container, LoadingProgress, Text } from './styles';
import ILoadingProps from './types';

const Loading = (props: ILoadingProps) => {
  const { open, text } = props;

  return (
    <Modal open={open}>
      <Container>
        <LoadingProgress size={100} />
        <Text>{text}</Text>
      </Container>
    </Modal>
  );
};

export default Loading;
