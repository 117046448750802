import { useFormik } from 'formik';
import i18next from 'i18next';
import React, { useEffect } from 'react';

import TextButton from 'src/components/Buttons/TextButton';
import Form from 'src/components/Form';
import { IFormInput } from 'src/components/Form/types';
import TextInput from 'src/components/Inputs/TextInput';
import Loading from 'src/components/Loading';

import SelectInput from '../../../components/Inputs/SelectInput';
import { QrRequestStatus } from '../../../types/qrRequest';
import './i18n';
import { FormWrapper, Title, Text, ButtonContainer } from './styles';
import { IQrRequestsEditProps } from './types';
import { validationSchema } from './validations';

const QrRequestsEdit = (props: IQrRequestsEditProps) => {
  const { qrRequest, users, loading, onSubmit } = props;

  const config = useFormik({
    initialValues: {
      id: qrRequest.id,
      amount: qrRequest.amount,
      status: qrRequest.status,
      userId: qrRequest.user.id,
    },
    validationSchema,
    onSubmit: () => {
      onSubmit(qrRequest.id, config.values);
    },
  });

  const inputs: IFormInput[] = [
    {
      key: 'id',
      input: (
        <Text marginBottom={8}>{`${i18next.t('QR_REQUESTS_EDIT:ID_LABEL')}: ${qrRequest.id}`}</Text>
      ),
    },
    {
      key: 'userId',
      input: (
        <SelectInput
          id="userId"
          name="userId"
          label={i18next.t('QR_REQUESTS_EDIT:USER_LABEL')}
          options={users}
          error={config.touched.userId && Boolean(config.errors.userId) ? config.errors.userId : ''}
          value={config.values.userId}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'amount',
      input: (
        <TextInput
          id="amount"
          name="amount"
          placeholder={i18next.t('QR_REQUESTS_EDIT:AMOUNT_LABEL')}
          error={config.touched.amount && Boolean(config.errors.amount) ? config.errors.amount : ''}
          value={config.values.amount.toString()}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'status',
      input: (
        <SelectInput
          id="status"
          name="status"
          error={config.touched.status && Boolean(config.errors.status) ? config.errors.status : ''}
          value={config.values.status}
          onChange={config.handleChange}
          label={i18next.t('QR_REQUESTS_EDIT:STATUS_LABEL')}
          options={[
            { value: QrRequestStatus.PENDING, text: i18next.t('QR_REQUESTS_EDIT:STATUS_PENDING') },
            {
              value: QrRequestStatus.PROCESSED,
              text: i18next.t('QR_REQUESTS_EDIT:STATUS_PROCESSED'),
            },
          ]}
        />
      ),
    },
  ];

  useEffect(() => {
    config.setValues({
      id: qrRequest.id,
      amount: qrRequest.amount,
      status: qrRequest.status,
      userId: qrRequest.user.id,
    });
    // eslint-disable-next-line
  }, [qrRequest]);

  return (
    <FormWrapper>
      <Loading open={loading} text={i18next.t(`QR_REQUESTS_EDIT:LOADING`)} />
      <Title>{i18next.t('QR_REQUESTS_EDIT:TITLE')}</Title>
      <Form inputs={inputs} />
      <ButtonContainer>
        <TextButton
          text={i18next.t('QR_REQUESTS_EDIT:SUBMIT_BUTTON')}
          isLoading={loading}
          onSubmit={config.handleSubmit}
        />
      </ButtonContainer>
    </FormWrapper>
  );
};

export default QrRequestsEdit;
