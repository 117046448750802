import React, { useCallback, useEffect } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useDispatch } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';

import * as appActions from 'src/redux/actions/app';

import history from 'src/helpers/history';

import PublicRoute from './components/PublicRoute';
import LoginContainer from './containers/Login';
import { useTypedSelector } from './hooks/typeSelector';
import isUserLoggedSelector from './redux/selectors/auth/isUserLogged';
import BoxRoutes from './routes/box';
import InstitutionRoutes from './routes/institution';
import ProcessingRequests from './routes/processingRequests';
import QrRequestRoutes from './routes/qrRequest';
import RequestRoutes from './routes/request';
import UserRoutes from './routes/user';

const App = () => {
  const dispatch = useDispatch();
  const isUserLogged = useTypedSelector(isUserLoggedSelector());

  const handleRouteChange = useCallback(() => {
    dispatch(appActions.changeRoute(history.location.pathname));
  }, [dispatch]);

  useEffect(() => {
    handleRouteChange();
    const unListen = history.listen(handleRouteChange);
    return unListen;
  }, [handleRouteChange]);

  return (
    <>
      <ReactNotifications />
      <Switch>
        <PublicRoute
          path="/login"
          component={LoginContainer}
          restricted={true}
          isUserLogged={true}
        />
        {ProcessingRequests(isUserLogged)}
        {UserRoutes(isUserLogged)}
        {InstitutionRoutes(isUserLogged)}
        {BoxRoutes(isUserLogged)}
        {RequestRoutes(isUserLogged)}
        {QrRequestRoutes(isUserLogged)}
        <Redirect to="/requests" />
      </Switch>
    </>
  );
};

export default App;
