import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'NOTIFICATIONS', {
  SUCCESS: 'Success!',
  WELCOME: 'Welcome back!',
  BYE: 'Bye!',
  ERROR: 'Error',
  USER_DELETED: 'User deleted',
  USERS_DELETED: 'users deleted',
  USER_EDITED: 'User edited',
  USER_CREATED: 'User created',
  INSTITUION_DELETED: 'Institution deleted',
  INSTITUIONS_DELETED: 'institutions deleted',
  INSTITUION_EDITED: 'Institution edited',
  INSTITUION_CREATED: 'Institution created',
  BOX_DELETED: 'Box deleted',
  BOXES_DELETED: 'boxes deleted',
  BOX_EDITED: 'Box edited',
  BOX_CREATED: 'Box created',
  REQUEST_DELETED: 'Request deleted',
  REQUESTS_DELETED: 'requests deleted',
  REQUEST_EDITED: 'Request edited',
  QR_REQUEST_EDITED: 'QR request edited',
  QR_REQUEST_DELETED: 'QR request deleted',
  TOKEN_EXPIRED: 'Token expired',
});

i18next.addResources(locales.ES, 'NOTIFICATIONS', {
  SUCCESS: '¡Éxito!',
  WELCOME: '¡Bienvenido!',
  BYE: '¡Adios!',
  ERROR: 'Error',
  USER_DELETED: 'Usuario borrado',
  USERS_DELETED: 'usuarios borrados',
  USER_EDITED: 'Usuario editado',
  USER_CREATED: 'Usuario creado',
  INSTITUION_DELETED: 'Institución borrada',
  INSTITUIONS_DELETED: 'instituciones borradas',
  INSTITUION_EDITED: 'Institución editada',
  INSTITUION_CREATED: 'Institución creada',
  BOX_DELETED: 'Caja borrada',
  BOXES_DELETED: 'cajas borradas',
  BOX_EDITED: 'Caja editada',
  BOX_CREATED: 'Caja creada',
  REQUEST_DELETED: 'Solicitud borrada',
  REQUESTS_DELETED: 'solicitudes borradas',
  REQUEST_EDITED: 'Solicitud editada',
  QR_REQUEST_EDITED: 'Petición de QR editada',
  QR_REQUEST_DELETED: 'Petición de QR borrada',
  TOKEN_EXPIRED: 'Token expirado',
});
