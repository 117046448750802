import { AnyAction } from 'redux';

import { IInstitution } from 'src/types/institution';
import IReducerInfo from 'src/types/reducer';

import * as institutionConstants from '../constants/institution';

export interface IInstitutionReducerState {
  institutions: IInstitution[] | null;
  institutionToEdit: IInstitution | null;
  institutionInfo: IReducerInfo;
}

const defaultState: IInstitutionReducerState = {
  institutions: null,
  institutionToEdit: null,
  institutionInfo: {
    info: {
      error: false,
      success: false,
      loading: false,
    },
    message: '',
  },
};

const setInstitutions = (state: IInstitutionReducerState, institutions: IInstitution[] | null) => ({
  ...state,
  institutions,
});

const setInstitutionToEdit = (
  state: IInstitutionReducerState,
  institutionToEdit: IInstitution | null,
) => ({
  ...state,
  institutionToEdit,
});

const removeInstitutions = (state: IInstitutionReducerState, idsToRemove: number[]) => {
  const newInstitutions = state.institutions?.filter((i) => !idsToRemove.includes(i.id)) || null;
  return {
    ...state,
    institutions: newInstitutions,
  };
};

const setInstitutionInfo = (
  state: IInstitutionReducerState,
  error: boolean,
  success: boolean,
  loading: boolean,
  message: string,
) => {
  return {
    ...state,
    institutionInfo: {
      info: {
        error,
        success,
        loading,
      },
      message,
    },
  };
};

const institutionReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case institutionConstants.INITIALIZE_INSTITUTIONS_LIST_VIEW_REQUESTED:
    case institutionConstants.INITIALIZE_INSTITUTIONS_EDIT_VIEW_REQUESTED:
    case institutionConstants.INSTITUTIONS_ON_DELETE_ONE_REQUESTED:
    case institutionConstants.INSTITUTIONS_ON_DELETE_MANY_REQUESTED:
    case institutionConstants.INSTITUTIONS_ON_EDIT_ONE_REQUESTED:
    case institutionConstants.INSTITUTIONS_ON_CREATE_ONE_REQUESTED:
      return setInstitutionInfo(state, false, false, true, '');
    case institutionConstants.INITIALIZE_INSTITUTIONS_LIST_VIEW_SUCCEEDED:
      return setInstitutionInfo(
        setInstitutions(state, action.institutions),
        false,
        true,
        false,
        '',
      );
    case institutionConstants.INITIALIZE_INSTITUTIONS_EDIT_VIEW_SUCCEEDED:
      return setInstitutionInfo(
        setInstitutionToEdit(state, action.institution),
        false,
        true,
        false,
        '',
      );
    case institutionConstants.INSTITUTIONS_ON_DELETE_ONE_SUCCEEDED:
      return setInstitutionInfo(
        removeInstitutions(state, [action.institutionId]),
        false,
        true,
        false,
        '',
      );
    case institutionConstants.INSTITUTIONS_ON_DELETE_MANY_SUCCEEDED:
      return setInstitutionInfo(
        removeInstitutions(state, action.institutionIds),
        false,
        true,
        false,
        '',
      );
    case institutionConstants.INSTITUTIONS_ON_EDIT_ONE_SUCCEEDED:
      return setInstitutionInfo(
        setInstitutionToEdit(state, action.institution),
        false,
        true,
        false,
        '',
      );
    case institutionConstants.INSTITUTIONS_ON_CREATE_ONE_SUCCEEDED:
      return setInstitutionInfo(
        setInstitutions(state, action.institutions),
        false,
        true,
        false,
        '',
      );
    case institutionConstants.INSTITUTIONS_ON_DELETE_ONE_FAILED:
    case institutionConstants.INSTITUTIONS_ON_DELETE_MANY_FAILED:
    case institutionConstants.INSTITUTIONS_ON_EDIT_ONE_FAILED:
    case institutionConstants.INSTITUTIONS_ON_CREATE_ONE_FAILED:
      return setInstitutionInfo(state, false, false, false, '');
    case institutionConstants.INITIALIZE_INSTITUTIONS_LIST_VIEW_FAILED:
    case institutionConstants.INITIALIZE_INSTITUTIONS_EDIT_VIEW_FAILED:
      return setInstitutionInfo(state, true, false, false, action.errorMessage);
    default:
      return state;
  }
};

export default institutionReducer;
