import { useFormik } from 'formik';
import i18next from 'i18next';
import React from 'react';

import Form from 'src/components/Form';
import { IFormInput } from 'src/components/Form/types';
import SelectInput from 'src/components/Inputs/SelectInput';
import TextInput from 'src/components/Inputs/TextInput';

import './i18n';
import { FormWrapper, Title } from './styles';
import { IBoxNewProps } from './types';
import { validationSchema } from './validations';

const BoxesNew = (props: IBoxNewProps) => {
  const { setType, institutions, onSubmit, loading, institution } = props;

  const config = useFormik({
    initialValues: {
      set: '',
      institutionId: institution,
      quantity: '',
    },
    validationSchema,
    onSubmit: () => {
      onSubmit(config.values);
    },
  });

  const inputs: IFormInput[] = [
    {
      key: 'set',
      input: (
        <SelectInput
          id="set"
          name="set"
          label={i18next.t('BOXES_NEW:SET_LABEL')}
          options={setType}
          error={config.touched.set && Boolean(config.errors.set) ? config.errors.set : ''}
          value={config.values.set}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'institutionId',
      input: (
        <SelectInput
          id="institutionId"
          name="institutionId"
          label={i18next.t('BOXES_NEW:INSTITUTION_LABEL')}
          options={institutions}
          error={
            config.touched.institutionId && Boolean(config.errors.institutionId)
              ? config.errors.institutionId
              : ''
          }
          value={institution}
          onChange={config.handleChange}
          disabled
        />
      ),
    },
    {
      key: 'quantity',
      input: (
        <TextInput
          id="quantity"
          name="quantity"
          placeholder={i18next.t('BOXES_NEW:QUANTITY_LABEL')}
          error={
            config.touched.quantity && Boolean(config.errors.quantity) ? config.errors.quantity : ''
          }
          value={config.values.quantity}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
  ];

  return (
    <FormWrapper>
      <Title> {i18next.t('BOXES_NEW:TITLE')}</Title>
      <Form
        inputs={inputs}
        onSubmit={config.handleSubmit}
        isLoading={loading}
        submitText={i18next.t('BOXES_NEW:SUBMIT_BUTTON')}
      />
    </FormWrapper>
  );
};

export default BoxesNew;
