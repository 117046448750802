import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const usersListSelector = (institutionId?: string) =>
  createSelector([(state: IReduxState) => state.user.users], (users) => {
    if (!users) return [];
    let usersFiltered = users;
    if (institutionId) {
      usersFiltered = usersFiltered.filter(
        (users) => users.institution.id === Number(institutionId),
      );
    }
    return usersFiltered.map((u) => ({
      id: u.id,
      name: `${u.lastName}, ${u.firstName}`,
      email: u.email,
      institution: u.institution?.name || '',
    }));
  });

export default usersListSelector;
