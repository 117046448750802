import { AnyAction } from 'redux';
import { takeLatest, all, call, put } from 'redux-saga/effects';

import * as requestActions from 'src/redux/actions/request';
import * as requestConstants from 'src/redux/constants/request';
import * as requestService from 'src/redux/services/request';

import { IBoxImage, IRequest, ISingleRequestReport } from 'src/types/request';

function* listRequests(action: AnyAction) {
  try {
    const requests: { requests: IRequest[] } = yield call(requestService.getAll, action.status);
    yield put(requestActions.initializeRequestsListViewSucceeded(requests));
  } catch (error: any) {
    yield put(requestActions.initializeRequestsListViewFailed(error));
  }
}

function* deleteRequest(action: AnyAction) {
  try {
    yield call(requestService.deleteOne, action.requestId);
    yield put(requestActions.onDeleteOneSucceeded(action.requestId));
  } catch (error: any) {
    yield put(requestActions.onDeleteOneFailed(error));
  }
}

function* deleteManyRequests(action: AnyAction) {
  try {
    const { countDeleted } = yield call(requestService.deleteMany, action.requestIds);
    yield put(requestActions.onDeleteManySucceeded(action.requestIds, countDeleted));
  } catch (error: any) {
    yield put(requestActions.onDeleteManyFailed(error));
  }
}

function* getRequestToEdit(action: AnyAction) {
  try {
    const request: { request: IRequest } = yield call(requestService.getOne, action.requestId);
    const boxImages: { boxImages: IBoxImage[] } = yield call(
      requestService.getBoxImages,
      action.requestId,
    );
    yield put(
      requestActions.initializeRequestsEditViewSucceeded({ ...request.request, ...boxImages }),
    );
  } catch (error: any) {
    yield put(requestActions.initializeRequestsEditViewFailed(error));
  }
}

function* editRequest(action: AnyAction) {
  try {
    const editedRequest: { request: IRequest } = yield call(
      requestService.editOne,
      action.requestId,
      action.newRequest,
    );
    yield put(
      requestActions.onEditOneSucceeded(editedRequest.request, action.inRequestsInProgress),
    );
  } catch (error: any) {
    yield put(requestActions.onEditOneFailed(error));
  }
}

function* reprocessRequest(action: AnyAction) {
  try {
    const reprocessedRequest: { request: IRequest } = yield call(
      requestService.reprocessRequest,
      action.requestId,
    );
    alert('Solicitud de reprocesamiento enviada correctamente');
    yield put(requestActions.reprocessRequestSucceeded(reprocessedRequest.request));
  } catch (error: any) {
    alert('Fallo al enviar solicitud de reprocesamiento');
    yield put(requestActions.reprocessRequestFailed(error));
  }
}

function* updateAcceptedFieldBoxImages(action: AnyAction) {
  try {
    const newBoxImage: { boxImage: IBoxImage } = yield call(
      requestService.updateBoxImages,
      action.payload.boxImageId,
      action.payload.accepted,
    );
    yield put(requestActions.updateAcceptedFieldBoxImagesSucceeded(newBoxImage.boxImage));
  } catch (error: any) {
    yield put(requestActions.updateAcceptedFieldBoxImagesFailed());
  }
}

function* getRequestState(action: AnyAction) {
  try {
    const request: ISingleRequestReport = yield call(
      requestService.getRequestState,
      action.requestId,
    );
    yield put(requestActions.getRequestStateSucceeded(request));
  } catch (error: any) {
    yield put(requestActions.getRequestStateFailed());
  }
}

export default all([
  takeLatest(requestConstants.INITIALIZE_REQUESTS_LIST_VIEW_REQUESTED, listRequests),
  takeLatest(requestConstants.REQUESTS_ON_DELETE_ONE_SUCCEEDED, listRequests),
  takeLatest(requestConstants.INITIALIZE_REQUESTS_EDIT_VIEW_REQUESTED, getRequestToEdit),
  takeLatest(requestConstants.REQUESTS_ON_EDIT_ONE_REQUESTED, editRequest),
  takeLatest(requestConstants.REQUESTS_ON_DELETE_ONE_REQUESTED, deleteRequest),
  takeLatest(requestConstants.REQUESTS_ON_DELETE_MANY_REQUESTED, deleteManyRequests),
  takeLatest(requestConstants.UPDATE_ACCEPTED_FIELD_BOXIMAGES, updateAcceptedFieldBoxImages),
  takeLatest(requestConstants.REPROCESS_REQUEST, reprocessRequest),
  takeLatest(requestConstants.GET_REQUEST_STATE, getRequestState),
]);
