import { useFormik } from 'formik';
import i18next from 'i18next';
import React from 'react';

import Form from 'src/components/Form';
import { IFormInput } from 'src/components/Form/types';
import PasswordInput from 'src/components/Inputs/PasswordInput';
import SelectInput from 'src/components/Inputs/SelectInput';
import TextInput from 'src/components/Inputs/TextInput';
import passwordGenerator from 'src/helpers/password';

import './i18n';
import { FormWrapper, Title } from './styles';
import { IUserNewProps } from './types';
import { validationSchema } from './validations';

const UsersNew = (props: IUserNewProps) => {
  const { institutions, onSubmit, loading } = props;

  const config = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: passwordGenerator(),
      institutionId: '',
    },
    validationSchema,
    onSubmit: () => {
      onSubmit(config.values);
    },
  });

  const inputs: IFormInput[] = [
    {
      key: 'firstName',
      input: (
        <TextInput
          id="firstName"
          name="firstName"
          placeholder={i18next.t('USERS_NEW:FIRST_NAME_LABEL')}
          error={
            config.touched.firstName && Boolean(config.errors.firstName)
              ? config.errors.firstName
              : ''
          }
          value={config.values.firstName}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'lastName',
      input: (
        <TextInput
          id="lastName"
          name="lastName"
          placeholder={i18next.t('USERS_NEW:LAST_NAME_LABEL')}
          error={
            config.touched.lastName && Boolean(config.errors.lastName) ? config.errors.lastName : ''
          }
          value={config.values.lastName}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'email',
      input: (
        <TextInput
          id="email"
          name="email"
          placeholder={i18next.t('USERS_NEW:EMAIL_LABEL')}
          error={config.touched.email && Boolean(config.errors.email) ? config.errors.email : ''}
          value={config.values.email}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'password',
      input: (
        <PasswordInput
          id="password"
          name="password"
          placeholder={i18next.t('USERS_NEW:PASSWORD_LABEL')}
          error={
            config.touched.password && Boolean(config.errors.password) ? config.errors.password : ''
          }
          value={config.values.password}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'institutionId',
      input: (
        <SelectInput
          id="institutionId"
          name="institutionId"
          label={i18next.t('USERS_NEW:INSTITUTION_LABEL')}
          options={institutions}
          error={
            config.touched.institutionId && Boolean(config.errors.institutionId)
              ? config.errors.institutionId
              : ''
          }
          value={config.values.institutionId}
          onChange={config.handleChange}
        />
      ),
    },
  ];

  return (
    <FormWrapper>
      <Title> {i18next.t('USERS_NEW:TITLE')}</Title>
      <Form
        inputs={inputs}
        onSubmit={config.handleSubmit}
        isLoading={loading}
        submitText={i18next.t('USERS_NEW:SUBMIT_BUTTON')}
      />
    </FormWrapper>
  );
};

export default UsersNew;
