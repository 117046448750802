import i18next from 'i18next';

export const locales = {
  EN: 'en',
  ES: 'es',
};

const DEFAULT_LOCALE = locales.ES;

i18next.init({
  lng: DEFAULT_LOCALE,
  fallbackLng: DEFAULT_LOCALE,
  initImmediate: false,
});
