import { AnyAction } from 'redux';

import IReducerInfo from 'src/types/reducer';

import * as rolesConstants from '../constants/roles';

export interface IRolesReducerState {
  roles: string[];
  rolesInfo: IReducerInfo;
}

const defaultState: IRolesReducerState = {
  roles: [],
  rolesInfo: {
    info: {
      error: false,
      success: false,
      loading: false,
    },
    message: '',
  },
};

const setRoles = (state: IRolesReducerState, roles: string[]) => ({
  ...state,
  roles,
});

const setRolesInfo = (
  state: IRolesReducerState,
  error: boolean,
  success: boolean,
  loading: boolean,
  message: string,
) => {
  return {
    ...state,
    rolesInfo: {
      info: {
        error,
        success,
        loading,
      },
      message,
    },
  };
};

const rolesReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case rolesConstants.ROLES_ON_REQUESTED:
      return setRolesInfo(state, false, false, true, '');
    case rolesConstants.ROLES_ON_SUCCEEDED:
      return setRolesInfo(setRoles(state, action.roles), false, true, false, '');
    case rolesConstants.ROLES_ON_FAILED:
      return setRolesInfo(state, false, false, false, '');
    default:
      return state;
  }
};

export default rolesReducer;
