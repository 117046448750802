import React from 'react';
import { useDispatch } from 'react-redux';

import * as institutionActions from 'src/redux/actions/institution';
import institutionInfoSelector from 'src/redux/selectors/institution/institutionInfo';

import { useTypedSelector } from 'src/hooks/typeSelector';
import { INewInstitution } from 'src/types/institution';
import InstitutionsNew from 'src/views/Institutions/InstitutionsNew';

import LayoutContainer from '../Layout/Layout';

const InstitutionsNewContainer = () => {
  const dispatch = useDispatch();

  const institutionInfo = useTypedSelector(institutionInfoSelector());
  const onSubmit = (newInstitution: INewInstitution) => {
    dispatch(institutionActions.onCreateOneRequested(newInstitution));
  };

  return (
    <LayoutContainer>
      <InstitutionsNew onSubmit={onSubmit} loading={institutionInfo.info.loading} />
    </LayoutContainer>
  );
};

export default InstitutionsNewContainer;
