import { Close, ExitToApp, Menu } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import i18next from 'i18next';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import './i18n';
import {
  HeaderContainer,
  ToolbarContainer,
  DrawerContainer,
  DrawerHeader,
  MenuItemText,
  RightMenuWrapper,
  LeftMenuWrapper,
  LogoWrapper,
} from './styles';
import { IHeaderProps } from './types';

const Header = (props: IHeaderProps) => {
  const { isMenuOpened, items, onCloseMenu, onLogout, onOpenMenu, user } = props;

  const onLogoutClick = () => {
    onLogout();
  };

  const currentLocation = useLocation();

  return (
    <>
      <HeaderContainer data-testid="header" position="fixed">
        <ToolbarContainer>
          {user && (
            <LeftMenuWrapper>
              <IconButton
                color="inherit"
                data-testid="header-menu-icon-button"
                onClick={onOpenMenu}
              >
                <Menu />
              </IconButton>
              <LogoWrapper>{i18next.t('HEADER:TITLE', { name: user.firstName })}</LogoWrapper>
            </LeftMenuWrapper>
          )}
          {user && (
            <RightMenuWrapper data-testid="header-account-button-container">
              <MenuItem data-testid="header-logout-button" onClick={onLogoutClick}>
                <ExitToApp fontSize="small" />{' '}
                <MenuItemText>{i18next.t('HEADER:LOGOUT')}</MenuItemText>
              </MenuItem>
            </RightMenuWrapper>
          )}
        </ToolbarContainer>
      </HeaderContainer>
      <DrawerContainer
        anchor="left"
        data-testid="header-drawer"
        open={isMenuOpened}
        onClose={onCloseMenu}
        onOpen={onOpenMenu}
      >
        <DrawerHeader>
          <IconButton
            color="inherit"
            data-testid="header-close-icon-button"
            onClick={onCloseMenu}
            edge="start"
          >
            <Close />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {items.map(({ icon: Icon, key, label, to }) => (
            <ListItem
              activeClassName="active"
              component={NavLink}
              data-testid={`header-drawer-item-${key}`}
              key={key}
              onClick={onCloseMenu}
              to={to}
              isActive={() => currentLocation.pathname + currentLocation.search === to}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
      </DrawerContainer>
    </>
  );
};

export default Header;
