import { People, Apartment, Inventory, Assignment, QrCode } from '@mui/icons-material';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as userActions from 'src/redux/actions/auth';
import userDataSelector from 'src/redux/selectors/auth/userData';

import ConfirmationDialog from 'src/components/ConfirmationDialog';
import Header from 'src/components/Layout/Header';
import { SIZES } from 'src/helpers/devices';
import { useTypedSelector } from 'src/hooks/typeSelector';

const HeaderContainer = () => {
  const dispatch = useDispatch();

  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] = useState<boolean>(false);

  const user = useTypedSelector(userDataSelector());

  useEffect(() => {
    const handleResize = (): void => {
      if (isMenuOpened && window.innerWidth > SIZES.mediumTablet) {
        setIsMenuOpened(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const onLogoutClick = () => {
    setIsMenuOpened(false);
    setIsConfirmationDialogOpened(true);
  };

  const handleLogOut = () => {
    dispatch(userActions.logoutRequested(user?.email || ''));
  };

  const headerItems = [
    {
      icon: Assignment,
      key: 'requests',
      label: i18next.t('HEADER:REQUESTS'),
      to: '/requests',
    },
    ...['FAILED', 'PROCESSING', 'COMPLETED', 'PENDING'].map((status) => ({
      icon: Assignment,
      key: 'requests-' + status,
      label: i18next.t('HEADER:REQUESTS') + ' - ' + i18next.t(`REQUESTS:STATUS_${status}`),
      to: '/requests?filterColumn=status&filterValue=' + status,
    })),
    {
      icon: QrCode,
      key: 'qr-requests',
      label: i18next.t('HEADER:QR_REQUESTS'),
      to: '/qr-requests',
    },
    {
      icon: Inventory,
      key: 'boxes',
      label: i18next.t('HEADER:BOXES'),
      to: '/boxes',
    },
    {
      icon: People,
      key: 'users',
      label: i18next.t('HEADER:USERS'),
      to: '/users',
    },
    {
      icon: Apartment,
      key: 'institutions',
      label: i18next.t('HEADER:INSTITUTIONS'),
      to: '/institutions',
    },
  ];

  return (
    <>
      <Header
        isMenuOpened={isMenuOpened}
        items={headerItems}
        onCloseMenu={() => setIsMenuOpened(false)}
        onLogout={onLogoutClick}
        onOpenMenu={() => setIsMenuOpened(true)}
        user={user}
      />
      <ConfirmationDialog
        message={i18next.t('HEADER:CONFIRMATION_DIALOG_MESSAGE')}
        onCancelClick={() => setIsConfirmationDialogOpened(false)}
        onConfirmClick={handleLogOut}
        open={isConfirmationDialogOpened}
      />
    </>
  );
};

export default HeaderContainer;
