import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'LOGIN_INPUT', {
  EMAIL: 'Email',
  PASSWORD: 'Password',
  LOGIN: 'Login',
});

i18next.addResources(locales.ES, 'LOGIN_INPUT', {
  EMAIL: 'Email',
  PASSWORD: 'Contraseña',
  LOGIN: 'Iniciar sesión',
});
