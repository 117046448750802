import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'INSTITUTIONS_NEW', {
  SUBMIT_BUTTON: 'New',
  TITLE: 'NEW INSTITUTION',
  NAME_LABEL: 'Name',
  STREET_LABEL: 'Street',
  STREET_NUMBER_LABEL: 'Number',
  DOOR_LABEL: 'Door',
  ZIP_CODE_LABEL: 'Zip code',
  POPULATION_LABEL: 'Population',
  PROVINCE_LABEL: 'Province',
  PHONE_LABEL: 'Phone',
  CLIENT_ID_LABEL: 'Client ID',
  NAME_REQUIRED: 'Name is required',
  STREET_REQUIRED: 'Street is required',
  STREET_NUMBER_REQUIRED: 'Street number is required',
  DOOR_REQUIRED: 'Door is required',
  ZIP_CODE_REQUIRED: 'Zip code is required',
  POPULATION_REQUIRED: 'Population is required',
  PROVINCE_REQUIRED: 'Province is required',
  PHONE_REQUIRED: 'Phone is required',
  CLIENT_ID_REQUIRED: 'Client ID is required',
});

i18next.addResources(locales.ES, 'INSTITUTIONS_NEW', {
  SUBMIT_BUTTON: 'Guardar',
  TITLE: 'NUEVA INSTITUCIÓN',
  NAME_LABEL: 'Nombre',
  STREET_LABEL: 'Calle',
  STREET_NUMBER_LABEL: 'Número',
  DOOR_LABEL: 'Puerta',
  ZIP_CODE_LABEL: 'Código postal',
  POPULATION_LABEL: 'Población',
  PROVINCE_LABEL: 'Provincia',
  PHONE_LABEL: 'Teléfono',
  CLIENT_ID_LABEL: 'ID de cliente',
  NAME_REQUIRED: 'Nombre es requerido',
  STREET_REQUIRED: 'Calle es requerido',
  STREET_NUMBER_REQUIRED: 'El número de calle es requerido',
  DOOR_REQUIRED: 'Puerta es requerido',
  ZIP_CODE_REQUIRED: 'Código postal es requerido',
  POPULATION_REQUIRED: 'Población es requerido',
  PROVINCE_REQUIRED: 'Provincia es requerido',
  PHONE_REQUIRED: 'Teléfono es requerido',
  CLIENT_ID_REQUIRED: 'ID de cliente es requerido',
});
