import i18next from 'i18next';
import React from 'react';

import Table from 'src/components/Table';

import './i18n';
import { RequestsContainer, RequestsContent } from './styles';
import { IRequestsListProps } from './types';

const RequestsList = (props: IRequestsListProps) => {
  const {
    columns,
    error,
    isLoading,
    requests,
    onAddNew,
    hideAddNew,
    onView,
    onEdit,
    showEditButton,
    onRowClick,
    onDeleteMany,
    hideDeleteMany,
    sortModel = [],
    onDeleteOne,
  } = props;
  return (
    <RequestsContainer data-testid="requests-view">
      <RequestsContent>
        <Table
          columns={columns}
          error={error}
          rows={requests}
          title={i18next.t('REQUESTS_LIST:TITLE')}
          loading={isLoading}
          onAddNew={onAddNew}
          hideAddNew={hideAddNew}
          defaultSortModel={sortModel}
          onView={onView}
          onEdit={onEdit}
          onRowClick={onRowClick}
          showEditButton={showEditButton}
          onDeleteOne={onDeleteOne}
          onDeleteMany={onDeleteMany}
          hideDeleteMany={hideDeleteMany}
        />
      </RequestsContent>
    </RequestsContainer>
  );
};

export default RequestsList;
