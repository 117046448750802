import React, { useState } from 'react';

import boxInfoSelector from 'src/redux/selectors/box/boxInfo';
import boxesCreatedSelector from 'src/redux/selectors/box/boxesCreated';

import printQRs from 'src/helpers/printQR';
import { useTypedSelector } from 'src/hooks/typeSelector';
import BoxesPreview from 'src/views/Boxes/BoxesPreview';

import LayoutContainer from '../Layout/Layout';

const BoxesPreviewContainer = () => {
  const boxes = useTypedSelector(boxesCreatedSelector());
  const {
    info: { loading },
  } = useTypedSelector(boxInfoSelector());
  const [loadingPDF, setLoadingPDF] = useState(false);

  if (!boxes) return null;

  const downloadQRs = async () => {
    setLoadingPDF(true);
    const labels = Array.from((document.getElementById('print_qr') as HTMLElement).children);
    if (labels.length) {
      await printQRs(labels, `${boxes[0].institution.name.replace(/\s/gm, '')}_QRs.pdf`);
    }
    setLoadingPDF(false);
  };

  return (
    <LayoutContainer>
      <BoxesPreview boxes={boxes} isLoading={loading || loadingPDF} onDownload={downloadQRs} />
    </LayoutContainer>
  );
};

export default BoxesPreviewContainer;
