import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const setTypeSelector = () =>
  createSelector([(state: IReduxState) => state.setType.setType], (setType) =>
    setType.map((type) => ({ value: type, text: type })),
  );

export default setTypeSelector;
