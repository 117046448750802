import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const institutionInfoSelector = () =>
  createSelector(
    [(state: IReduxState) => state.institution.institutionInfo],
    (institutionInfo) => institutionInfo,
  );

export default institutionInfoSelector;
