import styled from 'styled-components';

export const FormWrapper = styled.div`
  max-width: 800px;
  padding: 30px;
  margin: 30px auto;
`;

export const Title = styled.h2`
  font-size: 28px;
  color: ${(props) => props.theme.palette.primary};
  text-align: center;
  margin-bottom: 30px;
`;

export const Text = styled.h6`
  font-size: 20px;
  margin-bottom: ${({ marginBottom = 0 }: { marginBottom?: number }) => marginBottom}px;
`;
