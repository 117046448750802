import { IQrRequest } from '../../types/qrRequest';
import IReducerInfo from '../../types/reducer';
import * as qrRequestConstants from '../constants/qrRequest';

export interface IQrRequestReducerState {
  qrRequests: IQrRequest[] | null;
  qrRequestToEdit: IQrRequest | null;
  qrRequestInfo: IReducerInfo;
}

const defaultState: IQrRequestReducerState = {
  qrRequests: null,
  qrRequestToEdit: null,
  qrRequestInfo: {
    info: {
      error: false,
      success: false,
      loading: false,
    },
    message: '',
  },
};

const setQrRequests = (state: IQrRequestReducerState, qrRequests: IQrRequest[] | null) => ({
  ...state,
  qrRequests,
});

const setQrRequestToEdit = (state: IQrRequestReducerState, qrRequestToEdit: IQrRequest | null) => ({
  ...state,
  qrRequestToEdit,
});

const setQrRequestInfo = (
  state: IQrRequestReducerState,
  error: boolean,
  success: boolean,
  loading: boolean,
  message: string,
) => {
  return {
    ...state,
    qrRequestInfo: {
      info: {
        error,
        success,
        loading,
      },
      message,
    },
  };
};

const removeQrRequest = (state: IQrRequestReducerState, idToRemove: number | null) => {
  const newQrRequests = state.qrRequests?.filter((i) => i.id !== idToRemove) || null;
  return {
    ...state,
    qrRequests: newQrRequests,
  };
};

const qrRequestReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case qrRequestConstants.INITIALIZE_QR_REQUESTS_LIST_VIEW_REQUESTED:
    case qrRequestConstants.INITIALIZE_QR_REQUESTS_EDIT_VIEW_REQUESTED:
    case qrRequestConstants.QR_REQUESTS_ON_EDIT_ONE_REQUESTED:
    case qrRequestConstants.QR_REQUESTS_ON_DELETE_ONE_REQUESTED:
      return setQrRequestInfo(state, false, false, true, '');
    case qrRequestConstants.INITIALIZE_QR_REQUESTS_LIST_VIEW_SUCCEEDED:
      return setQrRequestInfo(setQrRequests(state, action.qrRequests), false, true, false, '');
    case qrRequestConstants.INITIALIZE_QR_REQUESTS_EDIT_VIEW_SUCCEEDED:
      return setQrRequestInfo(setQrRequestToEdit(state, action.qrRequest), false, true, false, '');
    case qrRequestConstants.QR_REQUESTS_ON_EDIT_ONE_SUCCEEDED:
      return setQrRequestInfo(setQrRequestToEdit(state, action.qrRequest), false, true, false, '');
    case qrRequestConstants.QR_REQUESTS_ON_DELETE_ONE_SUCCEEDED:
      return setQrRequestInfo(removeQrRequest(state, null), false, true, false, '');
    case qrRequestConstants.QR_REQUESTS_ON_DELETE_ONE_FAILED:
    case qrRequestConstants.QR_REQUESTS_ON_EDIT_ONE_FAILED:
      return setQrRequestInfo(state, true, false, false, '');
    case qrRequestConstants.INITIALIZE_QR_REQUESTS_LIST_VIEW_FAILED:
    case qrRequestConstants.INITIALIZE_QR_REQUESTS_EDIT_VIEW_FAILED:
      return setQrRequestInfo(state, true, false, false, action.error.message);
    default:
      return state;
  }
};

export default qrRequestReducer;
