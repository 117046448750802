import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'DELETE_ACTION', {
  CONFIRM: 'Are you sure you want to delete this records?',
});

i18next.addResources(locales.ES, 'DELETE_ACTION', {
  CONFIRM: '¿Seguro quieres borrar este registro?',
});
