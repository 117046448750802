import { AnyAction } from 'redux';
import { takeLatest, all, call, put } from 'redux-saga/effects';

import * as institutionActions from 'src/redux/actions/institution';
import * as appConstants from 'src/redux/constants/app';
import * as institutionConstants from 'src/redux/constants/institution';
import * as institutionService from 'src/redux/services/institution';

import { IInstitution } from 'src/types/institution';

function* listInstitutions() {
  try {
    const institutions: { institutions: IInstitution[] } = yield call(institutionService.getAll);
    yield put(
      institutionActions.initializeInstitutionsListViewSucceeded(institutions.institutions),
    );
  } catch (error: any) {
    yield put(institutionActions.initializeInstitutionsListViewFailed(error));
  }
}

function* deleteInstitution(action: AnyAction) {
  try {
    yield call(institutionService.deleteOne, action.institutionId);
    yield put(institutionActions.onDeleteOneSucceeded(action.institutionId));
  } catch (error: any) {
    yield put(institutionActions.onDeleteOneFailed(error));
  }
}

function* deleteManyInstitutions(action: AnyAction) {
  try {
    const { countDeleted } = yield call(institutionService.deleteMany, action.institutionIds);
    yield put(institutionActions.onDeleteManySucceeded(action.institutionIds, countDeleted));
  } catch (error: any) {
    yield put(institutionActions.onDeleteManyFailed(error));
  }
}

function* getInstitutionToEdit(action: AnyAction) {
  try {
    const institution: { institution: IInstitution } = yield call(
      institutionService.getOne,
      action.institutionId,
    );
    yield put(institutionActions.initializeInstitutionsEditViewSucceeded(institution.institution));
  } catch (error: any) {
    yield put(institutionActions.initializeInstitutionsEditViewFailed(error));
  }
}

function* editInstitution(action: AnyAction) {
  try {
    const editedInstitution: { institution: IInstitution } = yield call(
      institutionService.editOne,
      action.institutionId,
      action.newInstitution,
    );
    yield put(institutionActions.onEditOneSucceeded(editedInstitution.institution));
  } catch (error: any) {
    yield put(institutionActions.onEditOneFailed(error));
  }
}

function* createInstitution(action: AnyAction) {
  try {
    yield call(institutionService.createOne, action.institution);
    const institutions: { institutions: IInstitution[] } = yield call(institutionService.getAll);
    yield put(institutionActions.onCreateOneSucceeded(institutions.institutions));
  } catch (error: any) {
    yield put(institutionActions.onCreateOneFailed(error));
  }
}

export default all([
  takeLatest(institutionConstants.INITIALIZE_INSTITUTIONS_LIST_VIEW_REQUESTED, listInstitutions),
  takeLatest(institutionConstants.INSTITUTIONS_ON_DELETE_ONE_SUCCEEDED, listInstitutions),
  takeLatest(appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_SUCCEDED, listInstitutions),
  takeLatest(
    institutionConstants.INITIALIZE_INSTITUTIONS_EDIT_VIEW_REQUESTED,
    getInstitutionToEdit,
  ),
  takeLatest(institutionConstants.INSTITUTIONS_ON_EDIT_ONE_REQUESTED, editInstitution),
  takeLatest(institutionConstants.INSTITUTIONS_ON_DELETE_ONE_REQUESTED, deleteInstitution),
  takeLatest(institutionConstants.INSTITUTIONS_ON_CREATE_ONE_REQUESTED, createInstitution),
  takeLatest(institutionConstants.INSTITUTIONS_ON_DELETE_MANY_REQUESTED, deleteManyInstitutions),
]);
