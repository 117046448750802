import React from 'react';
import { useDispatch } from 'react-redux';

import * as userActions from 'src/redux/actions/user';
import institutionsListSelector from 'src/redux/selectors/institution/institutionsList';
import rolesSelector from 'src/redux/selectors/roles/roles';
import userInfoSelector from 'src/redux/selectors/user/userInfo';

import { ISelectOption } from 'src/components/Inputs/SelectInput/types';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { INewUser } from 'src/types/user';
import UsersNew from 'src/views/Users/UsersNew';

import LayoutContainer from '../Layout/Layout';

const UsersNewContainer = () => {
  const dispatch = useDispatch();

  const userInfo = useTypedSelector(userInfoSelector());
  const institutions: ISelectOption[] = useTypedSelector(institutionsListSelector()).map(
    (institution) => ({ value: institution.id, text: institution.name }),
  );
  const roles: ISelectOption[] = useTypedSelector(rolesSelector());

  const onSubmit = (newUser: INewUser) => {
    dispatch(userActions.onCreateOneRequested(newUser));
  };

  return (
    <LayoutContainer>
      <UsersNew
        roles={roles}
        institutions={institutions}
        onSubmit={onSubmit}
        loading={userInfo.info.loading}
      />
    </LayoutContainer>
  );
};

export default UsersNewContainer;
