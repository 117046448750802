import { AnyAction } from 'redux';

import { IBox } from 'src/types/box';
import IReducerInfo from 'src/types/reducer';

import * as boxConstants from '../constants/box';

export interface IBoxReducerState {
  boxes: IBox[] | null;
  boxToEdit: IBox | null;
  boxInfo: IReducerInfo;
  boxesCreated: IBox[] | null;
}

const defaultState: IBoxReducerState = {
  boxes: null,
  boxToEdit: null,
  boxesCreated: null,
  boxInfo: {
    info: {
      error: false,
      success: false,
      loading: false,
    },
    message: '',
  },
};

const setBoxes = (state: IBoxReducerState, boxes: IBox[] | null) => ({
  ...state,
  boxes,
});

const setBoxesCreated = (state: IBoxReducerState, boxesCreated: IBox[] | null) => ({
  ...state,
  boxesCreated,
});

const setBoxToEdit = (state: IBoxReducerState, boxToEdit: IBox | null) => ({
  ...state,
  boxToEdit,
});

const removeBoxes = (state: IBoxReducerState, idsToRemove: string[]) => {
  const newBoxes = state.boxes?.filter((b) => !idsToRemove.includes(b.id)) || null;
  return {
    ...state,
    boxes: newBoxes,
  };
};

const setBoxInfo = (
  state: IBoxReducerState,
  error: boolean,
  success: boolean,
  loading: boolean,
  message: string,
) => {
  return {
    ...state,
    boxInfo: {
      info: {
        error,
        success,
        loading,
      },
      message,
    },
  };
};

const boxReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case boxConstants.INITIALIZE_BOXES_LIST_VIEW_REQUESTED:
    case boxConstants.INITIALIZE_BOXES_EDIT_VIEW_REQUESTED:
    case boxConstants.BOXES_ON_DELETE_ONE_REQUESTED:
    case boxConstants.BOXES_ON_DELETE_MANY_REQUESTED:
    case boxConstants.BOXES_ON_EDIT_ONE_REQUESTED:
    case boxConstants.BOXES_ON_CREATE_ONE_REQUESTED:
      return setBoxInfo(state, false, false, true, '');
    case boxConstants.INITIALIZE_BOXES_LIST_VIEW_SUCCEEDED:
      return setBoxInfo(setBoxes(state, action.boxes), false, true, false, '');
    case boxConstants.INITIALIZE_BOXES_EDIT_VIEW_SUCCEEDED:
      return setBoxInfo(setBoxToEdit(state, action.box), false, true, false, '');
    case boxConstants.BOXES_ON_DELETE_ONE_SUCCEEDED:
      return setBoxInfo(removeBoxes(state, [action.boxId]), false, true, false, '');
    case boxConstants.BOXES_ON_DELETE_MANY_SUCCEEDED:
      return setBoxInfo(removeBoxes(state, action.boxIds), false, true, false, '');
    case boxConstants.BOXES_ON_EDIT_ONE_SUCCEEDED:
      return setBoxInfo(setBoxToEdit(state, action.box), false, true, false, '');
    case boxConstants.BOXES_ON_CREATE_ONE_SUCCEEDED:
      return setBoxInfo(setBoxesCreated(state, action.boxes), false, true, false, '');
    case boxConstants.BOXES_ON_DELETE_ONE_FAILED:
    case boxConstants.BOXES_ON_DELETE_MANY_FAILED:
    case boxConstants.BOXES_ON_EDIT_ONE_FAILED:
    case boxConstants.BOXES_ON_CREATE_ONE_FAILED:
      return setBoxInfo(state, false, false, false, '');
    case boxConstants.INITIALIZE_BOXES_LIST_VIEW_FAILED:
    case boxConstants.INITIALIZE_BOXES_EDIT_VIEW_FAILED:
      return setBoxInfo(state, true, false, false, action.errorMessage);
    default:
      return state;
  }
};

export default boxReducer;
