import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import history from 'src/helpers/history';

import cookiesMiddleware from './middlewares/cookies.middleware';
import notificationsMiddleware from './middlewares/notifications.middleware';
import redirectMiddleware from './middlewares/redirect.middleware';
import reducers from './reducers';
import { IAuthReducerState } from './reducers/auth';
import { IBoxReducerState } from './reducers/box';
import { IInstitutionReducerState } from './reducers/institution';
import { IQrRequestReducerState } from './reducers/qrRequest';
import { IRequestReducerState } from './reducers/request';
import { IRequestStatusReducerState } from './reducers/requestStatus';
import { IRolesReducerState } from './reducers/roles';
import { ISetTypeReducerState } from './reducers/setType';
import { IUserReducerState } from './reducers/user';
import rootSaga from './sagas';

export const sagaMiddleware = createSagaMiddleware();

export interface IReduxState {
  auth: IAuthReducerState;
  user: IUserReducerState;
  roles: IRolesReducerState;
  requestStatus: IRequestStatusReducerState;
  institution: IInstitutionReducerState;
  box: IBoxReducerState;
  request: IRequestReducerState;
  setType: ISetTypeReducerState;
  qrRequest: IQrRequestReducerState;
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  /* preloadedState, */
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      cookiesMiddleware,
      notificationsMiddleware,
      redirectMiddleware,
      sagaMiddleware,
    ),
  ),
);

sagaMiddleware.run(rootSaga);

export default store;
