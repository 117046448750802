import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';

export const Button = styled(LoadingButton)`
  color: ${(props) => props.theme.palette.common.white} !important;
  background-color: ${(props) => props.theme.palette.primary} !important;
  min-height: 45px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? '25%' : '100%')};
  .MuiLoadingButton-loadingIndicator {
    color: ${(props) => props.theme.palette.common.white} !important;
  }
  @media (any-hover: hover) {
    &:hover {
      ${(props) =>
        !props.disabled &&
        `
      cursor: pointer;
      filter: brightness(120%);
      `}
    }
  }
`;

export const ButtonImportant = styled(LoadingButton)`
  color: ${(props) => props.theme.palette.common.white} !important;
  background-color: ${(props) => 'orange'} !important;
  min-height: 45px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? '25%' : '100%')};
  .MuiLoadingButton-loadingIndicator {
    color: ${(props) => props.theme.palette.common.white} !important;
  }
  @media (any-hover: hover) {
    &:hover {
      ${(props) =>
  !props.disabled &&
  `
      cursor: pointer;
      filter: brightness(120%);
      `}
    }
  }
`;
