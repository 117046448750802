import { useFormik } from 'formik';
import i18next from 'i18next';
import React, { useEffect } from 'react';

import BoxQR from 'src/components/BoxQR';
import { Button } from 'src/components/Buttons/TextButton/styles';
import Form from 'src/components/Form';
import { IFormInput } from 'src/components/Form/types';
import SelectInput from 'src/components/Inputs/SelectInput';
import Loading from 'src/components/Loading';

import './i18n';
import { FormWrapper, Title, Text, QRContainer, QRButtonContainer } from './styles';
import { IBoxesEditProps } from './types';
import { validationSchema } from './validations';

const BoxesEdit = (props: IBoxesEditProps) => {
  const { box, loading, onSubmit, institutions, onDownload, downloading } = props;

  const config = useFormik({
    initialValues: {
      id: box.id,
      set: box.set,
      institutionId: box.institution.id.toString(),
    },
    validationSchema,
    onSubmit: () => {
      onSubmit(box.id, config.values);
    },
  });

  const inputs: IFormInput[] = [
    {
      key: 'id',
      input: <Text>{`${i18next.t('BOXES_EDIT:ID_LABEL')}: ${box.id}`}</Text>,
    },
    {
      key: 'set',
      input: <Text marginBottom={8}>{`${i18next.t('BOXES_EDIT:SET_LABEL')}: ${box.set}`}</Text>,
    },
    {
      key: 'institutionId',
      input: (
        <SelectInput
          id="institutionId"
          name="institutionId"
          label={i18next.t('BOXES_EDIT:INSTITUTION_LABEL')}
          options={institutions}
          error={
            config.touched.institutionId && Boolean(config.errors.institutionId)
              ? config.errors.institutionId
              : ''
          }
          value={config.values.institutionId.toString()}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'qr',
      input: (
        <>
          <QRContainer id={'print_qr'}>
            <BoxQR id={box.id} />
          </QRContainer>
          <QRButtonContainer>
            <Button onClick={onDownload}>{i18next.t('BOX_QR:DOWNLOAD')}</Button>
          </QRButtonContainer>
        </>
      ),
    },
  ];

  useEffect(() => {
    config.setValues({
      id: box.id,
      set: box.set,
      institutionId: box.institution.id.toString(),
    });
    // eslint-disable-next-line
  }, [box]);

  return (
    <FormWrapper>
      <Title>{i18next.t('BOXES_EDIT:TITLE')}</Title>
      <Loading open={downloading || loading} text={i18next.t('BOXES_EDIT:LOADING')} />
      <Form
        inputs={inputs}
        onSubmit={config.handleSubmit}
        isLoading={loading}
        submitText={i18next.t('BOXES_EDIT:SUBMIT_BUTTON')}
      />
    </FormWrapper>
  );
};

export default BoxesEdit;
