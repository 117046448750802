import axios, { AxiosResponse } from 'axios';

import { API_BASE_URL } from 'src/configs/configs';
import { saveAuthToken, getCookie } from 'src/helpers/cookies';
import { getAuthToken } from 'src/helpers/user';

export interface IAPIResponse {
  success: boolean;
  errorMessage: string;
  statusCode: number;
  data: JSON[] | JSON;
  payload?: { token: string; refreshToken: string };
}

const API = axios.create({
  baseURL: API_BASE_URL,
});

// If token is expired
let originalRequest: any;
API.interceptors.response.use(
  (response) => response,
  async (error) => {
    originalRequest = { ...originalRequest, ...error.config };
    if (
      error?.response?.status === 401 &&
      !originalRequest.retry &&
      !error.config.url.includes('login')
    ) {
      originalRequest.retry = true;
      return API.get('auth/refresh', {
        headers: { Authorization: `Bearer ${getCookie('refreshToken')}` },
      }).then((resp: AxiosResponse<IAPIResponse>) => {
        saveAuthToken(
          resp.data.payload?.token as string,
          resp.data.payload?.refreshToken as string,
        );
        originalRequest.headers.Authorization = `Bearer ${resp.data.payload?.token}`;
        return axios(originalRequest);
      });
    }
    originalRequest.retry = false;
    return Promise.reject(error);
  },
);

const defaultResponseData: IAPIResponse = {
  success: true,
  data: [],
  statusCode: 200,
  errorMessage: '',
};

function _buildHeaders(extraHeaders = {}) {
  const accessToken = getAuthToken();
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
    ...extraHeaders,
  };
}

const _handleErrorResponse = (error: any) => {
  return {
    success: false,
    errorMessage: error.response.statusText,
    statusCode: error.response.status,
    data: error.response.data,
  };
};

async function post(url: string, params: any, extraHeaders = {}) {
  let responseData: IAPIResponse = defaultResponseData;
  try {
    const config = {
      headers: _buildHeaders(extraHeaders),
    };
    const response = await API.post(url, params, config);
    responseData = response.data || responseData;
  } catch (error) {
    responseData = _handleErrorResponse(error);
    throw responseData;
  }

  return responseData;
}

async function get(url: string, params = {}, extraHeaders = {}) {
  let responseData: IAPIResponse = defaultResponseData;
  try {
    const config = {
      headers: _buildHeaders(extraHeaders),
      params,
    };
    const response = await API.get(url, config);
    responseData = response.data || responseData;
  } catch (error) {
    responseData = _handleErrorResponse(error);
    throw responseData;
  }

  return responseData;
}

async function put(url: string, params: any, extraHeaders = {}) {
  let responseData: IAPIResponse = defaultResponseData;
  try {
    const config = {
      headers: _buildHeaders(extraHeaders),
    };
    const response = await API.put(url, params, config);
    responseData = response.data || responseData;
  } catch (error) {
    responseData = _handleErrorResponse(error);
    throw responseData;
  }

  return responseData;
}

async function apiDelete(url: string, extraHeaders = {}, body = {}) {
  let responseData: IAPIResponse = defaultResponseData;

  try {
    const headers = _buildHeaders(extraHeaders);
    const response = await API.delete(url, { headers, data: body });
    responseData = response.data || responseData;
  } catch (error) {
    responseData = _handleErrorResponse(error);
    throw responseData;
  }

  return responseData;
}

export default API;

export { post, get, put, apiDelete };
