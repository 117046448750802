import { INewQrRequest, IQrRequest } from '../../types/qrRequest';
import * as qrRequestConstants from '../constants/qrRequest';
import { IAPIResponse } from '../services/api';

export const initializeQrRequestsListView = () => ({
  type: qrRequestConstants.INITIALIZE_QR_REQUESTS_LIST_VIEW_REQUESTED,
});

export const initializeQrRequestsListViewSucceeded = (qrRequests: IQrRequest[]) => ({
  type: qrRequestConstants.INITIALIZE_QR_REQUESTS_LIST_VIEW_SUCCEEDED,
  qrRequests,
});

export const initializeQrRequestsListViewFailed = (error: IAPIResponse) => ({
  type: qrRequestConstants.INITIALIZE_QR_REQUESTS_LIST_VIEW_FAILED,
  error,
});

export const initializeQrRequestsEditView = (qrRequestId: string) => ({
  type: qrRequestConstants.INITIALIZE_QR_REQUESTS_EDIT_VIEW_REQUESTED,
  qrRequestId,
});

export const initializeQrRequestsEditViewSucceeded = (qrRequest: IQrRequest) => ({
  type: qrRequestConstants.INITIALIZE_QR_REQUESTS_EDIT_VIEW_SUCCEEDED,
  qrRequest,
});

export const initializeQrRequestsEditViewFailed = (error: IAPIResponse) => ({
  type: qrRequestConstants.INITIALIZE_QR_REQUESTS_EDIT_VIEW_FAILED,
  error,
});

export const onEditOneRequested = (qrRequestId: number, newQrRequest: INewQrRequest) => {
  return {
    type: qrRequestConstants.QR_REQUESTS_ON_EDIT_ONE_REQUESTED,
    qrRequestId,
    newQrRequest,
  };
};

export const onEditOneSucceeded = (qrRequest: IQrRequest) => ({
  type: qrRequestConstants.QR_REQUESTS_ON_EDIT_ONE_SUCCEEDED,
  qrRequest,
});

export const onEditOneFailed = (error: IAPIResponse) => ({
  type: qrRequestConstants.QR_REQUESTS_ON_EDIT_ONE_FAILED,
  error,
});

export const onDeleteOneRequested = (qrRequestId: string) => ({
  type: qrRequestConstants.QR_REQUESTS_ON_DELETE_ONE_REQUESTED,
  qrRequestId,
});

export const onDeleteOneSucceeded = (qrRequestId: string) => ({
  type: qrRequestConstants.QR_REQUESTS_ON_DELETE_ONE_SUCCEEDED,
  qrRequestId,
});

export const onDeleteOneFailed = (error: IAPIResponse) => ({
  type: qrRequestConstants.QR_REQUESTS_ON_DELETE_ONE_FAILED,
  error,
});
