import { AnyAction } from 'redux';

import IReducerInfo from 'src/types/reducer';

import * as requestStatusConstants from '../constants/requestStatus';

export interface IRequestStatusReducerState {
  requestStatus: string[];
  requestStatusInfo: IReducerInfo;
}

const defaultState: IRequestStatusReducerState = {
  requestStatus: [],
  requestStatusInfo: {
    info: {
      error: false,
      success: false,
      loading: false,
    },
    message: '',
  },
};

const setRequestStatus = (state: IRequestStatusReducerState, requestStatus: string[]) => ({
  ...state,
  requestStatus,
});

const setRequestStatusInfo = (
  state: IRequestStatusReducerState,
  error: boolean,
  success: boolean,
  loading: boolean,
  message: string,
) => {
  return {
    ...state,
    requestStatusInfo: {
      info: {
        error,
        success,
        loading,
      },
      message,
    },
  };
};

const requestStatusReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case requestStatusConstants.REQUEST_STATUS_ON_REQUESTED:
      return setRequestStatusInfo(state, false, false, true, '');
    case requestStatusConstants.REQUEST_STATUS_ON_SUCCEEDED:
      return setRequestStatusInfo(
        setRequestStatus(state, action.requestStatus),
        false,
        true,
        false,
        '',
      );
    case requestStatusConstants.REQUEST_STATUS_ON_FAILED:
      return setRequestStatusInfo(state, false, false, false, '');
    default:
      return state;
  }
};

export default requestStatusReducer;
