import React from 'react';

import PrivateRoute from 'src/components/PrivateRoute';

import QrRequestsEditContainer from '../containers/QrRequests/QrRequestsEdit';
import QrRequestsListContainer from '../containers/QrRequests/QrRequestsList';

const QrRequestRoutes = (isUserLogged: boolean) => {
  return [
    <PrivateRoute
      key="list"
      path="/qr-requests"
      exact
      component={QrRequestsListContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="edit"
      path="/qr-requests/edit/:id"
      component={QrRequestsEditContainer}
      isUserLogged={isUserLogged}
    />,
  ];
};

export default QrRequestRoutes;
