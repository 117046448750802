import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'BOXES_NEW', {
  SUBMIT_BUTTON: 'New',
  TITLE: 'NEW BOX',
  SET_LABEL: 'Set',
  INSTITUTION_LABEL: 'Institution',
  QUANTITY_LABEL: 'Quantity',
  QUANTITY_REQUIRED: 'Quantity is required',
  QUANTITY_POSITIVE: 'Quantity must be positive',
  QUANTITY_INTEGER: 'Quantity must be a number',
  QUANTITY_MAX: 'Quantity must be less than 1000',
  SET_REQUIRED: 'Set is required',
  INSTITUTION_REQUIRED: 'Institution is required',
});

i18next.addResources(locales.ES, 'BOXES_NEW', {
  SUBMIT_BUTTON: 'Guardar',
  TITLE: 'NUEVA CAJA',
  SET_LABEL: 'Set',
  INSTITUTION_LABEL: 'Institución',
  QUANTITY_LABEL: 'Cantidad',
  QUANTITY_REQUIRED: 'Cantidad es requerido',
  QUANTITY_POSITIVE: 'Cantidad debe ser un número positivo',
  QUANTITY_INTEGER: 'Cantidad debe ser un núnmero',
  QUANTITY_MAX: 'Cantidad debe ser menor a 1000',
  SET_REQUIRED: 'Set es requerido',
  INSTITUTION_REQUIRED: 'Institución es requerido',
});
