import React from 'react';

import PrivateRoute from 'src/components/PrivateRoute';
import InstitutionsEditContainer from 'src/containers/Institutions/InstitutionsEdit';
import InstitutionsListContainer from 'src/containers/Institutions/InstitutionsList';
import InstitutionsNewContainer from 'src/containers/Institutions/InstitutionsNew';

const InstitutionRoutes = (isUserLogged: boolean) => {
  return [
    <PrivateRoute
      key="list"
      path="/institutions"
      exact
      component={InstitutionsListContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="new"
      path="/institutions/new"
      component={InstitutionsNewContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="edit"
      path="/institutions/edit/:id"
      component={InstitutionsEditContainer}
      isUserLogged={isUserLogged}
    />,
  ];
};

export default InstitutionRoutes;
