import React from 'react';

import PrivateRoute from 'src/components/PrivateRoute';
import BoxesEditContainer from 'src/containers/Boxes/BoxesEdit';
import BoxesListContainer from 'src/containers/Boxes/BoxesList';
import BoxesNewContainer from 'src/containers/Boxes/BoxesNew';
import BoxesPreviewContainer from 'src/containers/Boxes/BoxesPreview';
import InstitutionBoxesListContainer from 'src/containers/Boxes/InstitutionBoxesList';
import InstitutionBoxesNew from 'src/containers/Boxes/InstitutionBoxesNew';

const BoxRoutes = (isUserLogged: boolean) => {
  return [
    <PrivateRoute
      key="list"
      path="/boxes"
      exact
      component={BoxesListContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="institutionList"
      path="/boxes/institution/:id"
      exact
      component={InstitutionBoxesListContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="new"
      path="/boxes/new"
      component={BoxesNewContainer}
      isUserLogged={isUserLogged}
      exact
    />,
    <PrivateRoute
      key="institutionNew"
      path="/boxes/new/:id"
      component={InstitutionBoxesNew}
      isUserLogged={isUserLogged}
      exact
    />,
    <PrivateRoute
      key="preview"
      path="/boxes/preview"
      component={BoxesPreviewContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="edit"
      path="/boxes/edit/:id"
      component={BoxesEditContainer}
      isUserLogged={isUserLogged}
    />,
  ];
};

export default BoxRoutes;
