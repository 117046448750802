import React from 'react';

import PrivateRoute from 'src/components/PrivateRoute';
import RequestsEditContainer from 'src/containers/Requests/RequestsEdit';
import RequestsListContainer from 'src/containers/Requests/RequestsList';

const RequestRoutes = (isUserLogged: boolean) => {
  return [
    <PrivateRoute
      key="list"
      path="/requests"
      exact
      component={RequestsListContainer}
      isUserLogged={isUserLogged}
    />,
    <PrivateRoute
      key="edit"
      path="/requests/edit/:id"
      component={RequestsEditContainer}
      isUserLogged={isUserLogged}
    />,
  ];
};

export default RequestRoutes;
