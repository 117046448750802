import { useFormik } from 'formik';
import i18next from 'i18next';
import React, { useEffect } from 'react';

import Form from 'src/components/Form';
import { IFormInput } from 'src/components/Form/types';
import PasswordInput from 'src/components/Inputs/PasswordInput';
import SelectInput from 'src/components/Inputs/SelectInput';
import TextInput from 'src/components/Inputs/TextInput';
import Loading from 'src/components/Loading';

import './i18n';
import { FormWrapper, Title, Text } from './styles';
import { IUsersEditProps } from './types';
import { validationSchema } from './validations';

const UsersEdit = (props: IUsersEditProps) => {
  const { user, loading, onSubmit, institutions } = props;

  const config = useFormik({
    initialValues: {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: '',
      institutionId: user.institution?.id.toString() || '',
    },
    validationSchema,
    onSubmit: () => {
      onSubmit(user.id, config.values);
    },
  });

  const inputs: IFormInput[] = [
    {
      key: 'id',
      input: <Text marginBottom={8}>{`${i18next.t('USERS_EDIT:ID_LABEL')}: ${user.id}`}</Text>,
    },
    {
      key: 'firstName',
      input: (
        <TextInput
          id="firstName"
          name="firstName"
          placeholder={i18next.t('USERS_EDIT:FIRST_NAME_LABEL')}
          error={
            config.touched.firstName && Boolean(config.errors.firstName)
              ? config.errors.firstName
              : ''
          }
          value={config.values.firstName}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'lastName',
      input: (
        <TextInput
          id="lastName"
          name="lastName"
          placeholder={i18next.t('USERS_EDIT:LAST_NAME_LABEL')}
          error={
            config.touched.lastName && Boolean(config.errors.lastName) ? config.errors.lastName : ''
          }
          value={config.values.lastName}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'email',
      input: (
        <TextInput
          id="email"
          name="email"
          placeholder={i18next.t('USERS_EDIT:EMAIL_LABEL')}
          error={config.touched.email && Boolean(config.errors.email) ? config.errors.email : ''}
          value={config.values.email}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'password',
      input: (
        <PasswordInput
          id="password"
          name="password"
          placeholder={i18next.t('USERS_EDIT:PASSWORD_LABEL')}
          error={
            config.touched.password && Boolean(config.errors.password) ? config.errors.password : ''
          }
          value={config.values.password}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'institutionId',
      input: (
        <SelectInput
          id="institutionId"
          name="institutionId"
          label={i18next.t('USERS_EDIT:INSTITUTION_LABEL')}
          options={institutions}
          error={
            config.touched.institutionId && Boolean(config.errors.institutionId)
              ? config.errors.institutionId
              : ''
          }
          value={config.values.institutionId}
          onChange={config.handleChange}
        />
      ),
    },
  ];

  useEffect(() => {
    config.setValues({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: '',
      institutionId: user.institution?.id.toString() || '',
    });
    // eslint-disable-next-line
  }, [user]);

  return (
    <FormWrapper>
      <Loading open={loading} text={i18next.t(`USERS_EDIT:LOADING`)} />
      <Title>{i18next.t('USERS_EDIT:TITLE')}</Title>
      <Form
        inputs={inputs}
        onSubmit={config.handleSubmit}
        isLoading={loading}
        submitText={i18next.t('USERS_EDIT:SUBMIT_BUTTON')}
      />
    </FormWrapper>
  );
};

export default UsersEdit;
