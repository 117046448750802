import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'BOX_IMAGE_ACCORDION', {
  MISSING: 'Missing',
  EXTRA: 'Extra',
  CORRECT: 'Correct',
  NOT_PROCESSED: 'Not processed',
});

i18next.addResources(locales.ES, 'BOX_IMAGE_ACCORDION', {
  MISSING: 'Faltantes',
  EXTRA: 'Sobrantes',
  CORRECT: 'Correctas',
  NOT_PROCESSED: 'No procesada',
});
