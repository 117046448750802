import * as userConstants from 'src/redux/constants/user';

import { INewUser, IUser } from 'src/types/user';

import { IAPIResponse } from '../services/api';

export const initializeUsersListView = () => ({
  type: userConstants.INITIALIZE_USERS_LIST_VIEW_REQUESTED,
});

export const initializeUsersListViewSucceeded = (users: IUser[]) => ({
  type: userConstants.INITIALIZE_USERS_LIST_VIEW_SUCCEEDED,
  users,
});

export const initializeUsersListViewFailed = (error: IAPIResponse) => ({
  type: userConstants.INITIALIZE_USERS_LIST_VIEW_FAILED,
  error,
});

export const initializeUsersEditView = (userId: string) => ({
  type: userConstants.INITIALIZE_USERS_EDIT_VIEW_REQUESTED,
  userId,
});

export const initializeUsersEditViewSucceeded = (user: IUser) => ({
  type: userConstants.INITIALIZE_USERS_EDIT_VIEW_SUCCEEDED,
  user,
});

export const initializeUsersEditViewFailed = (error: IAPIResponse) => ({
  type: userConstants.INITIALIZE_USERS_EDIT_VIEW_FAILED,
  error,
});

export const onDeleteOneRequested = (userId: string) => ({
  type: userConstants.USERS_ON_DELETE_ONE_REQUESTED,
  userId,
});

export const onDeleteOneSucceeded = (userId: string) => ({
  type: userConstants.USERS_ON_DELETE_ONE_SUCCEEDED,
  userId,
});

export const onDeleteOneFailed = (error: IAPIResponse) => ({
  type: userConstants.USERS_ON_DELETE_ONE_FAILED,
  error,
});

export const onDeleteManyRequested = (userIds: string[]) => ({
  type: userConstants.USERS_ON_DELETE_MANY_REQUESTED,
  userIds,
});

export const onDeleteManySucceeded = (userIds: string[], countDeleted: number) => ({
  type: userConstants.USERS_ON_DELETE_MANY_SUCCEEDED,
  userIds,
  countDeleted,
});

export const onDeleteManyFailed = (error: IAPIResponse) => ({
  type: userConstants.USERS_ON_DELETE_MANY_FAILED,
  error,
});

export const onEditOneRequested = (userId: string, newUser: INewUser) => ({
  type: userConstants.USERS_ON_EDIT_ONE_REQUESTED,
  userId,
  newUser,
});

export const onEditOneSucceeded = (user: IUser) => ({
  type: userConstants.USERS_ON_EDIT_ONE_SUCCEEDED,
  user,
});

export const onEditOneFailed = (error: IAPIResponse) => ({
  type: userConstants.USERS_ON_EDIT_ONE_FAILED,
  error,
});

export const onCreateOneRequested = (user: INewUser) => ({
  type: userConstants.USERS_ON_CREATE_ONE_REQUESTED,
  user,
});

export const onCreateOneSucceeded = (users: IUser[]) => ({
  type: userConstants.USERS_ON_CREATE_ONE_SUCCEEDED,
  users,
});

export const onCreateOneFailed = (error: IAPIResponse) => ({
  type: userConstants.USERS_ON_CREATE_ONE_FAILED,
  error,
});
