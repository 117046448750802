import { AnyAction } from 'redux';
import { takeLatest, all, call, put } from 'redux-saga/effects';

import * as boxActions from 'src/redux/actions/box';
import * as boxConstants from 'src/redux/constants/box';
import * as boxService from 'src/redux/services/box';

import { IBox } from 'src/types/box';

function* listBoxes(action: AnyAction) {
  try {
    const boxes: { boxes: IBox[] } = yield call(boxService.getAll, action.institution);
    yield put(boxActions.initializeBoxesListViewSucceeded(boxes.boxes));
  } catch (error: any) {
    yield put(boxActions.initializeBoxesListViewFailed(error));
  }
}

function* deleteBox(action: AnyAction) {
  try {
    yield call(boxService.deleteOne, action.boxId);
    yield put(boxActions.onDeleteOneSucceeded(action.boxId));
  } catch (error: any) {
    yield put(boxActions.onDeleteOneFailed(error));
  }
}

function* deleteManyBoxes(action: AnyAction) {
  try {
    const { countDeleted } = yield call(boxService.deleteMany, action.boxIds);
    yield put(boxActions.onDeleteManySucceeded(action.boxIds, countDeleted));
  } catch (error: any) {
    yield put(boxActions.onDeleteManyFailed(error));
  }
}

function* getBoxToEdit(action: AnyAction) {
  try {
    const box: { box: IBox } = yield call(boxService.getOne, action.boxId);
    yield put(boxActions.initializeBoxesEditViewSucceeded(box.box));
  } catch (error: any) {
    yield put(boxActions.initializeBoxesEditViewFailed(error));
  }
}

function* editBox(action: AnyAction) {
  try {
    const editedBox: { box: IBox } = yield call(boxService.editOne, action.boxId, action.newBox);
    yield put(boxActions.onEditOneSucceeded(editedBox.box));
  } catch (error: any) {
    yield put(boxActions.onEditOneFailed(error));
  }
}

function* createBox(action: AnyAction) {
  try {
    const boxes: { boxes: IBox[] } = yield call(boxService.createOne, action.box);
    yield put(boxActions.onCreateOneSucceeded(boxes.boxes));
  } catch (error: any) {
    yield put(boxActions.onCreateOneFailed(error));
  }
}

export default all([
  takeLatest(boxConstants.INITIALIZE_BOXES_LIST_VIEW_REQUESTED, listBoxes),
  takeLatest(boxConstants.BOXES_ON_DELETE_ONE_SUCCEEDED, listBoxes),
  takeLatest(boxConstants.INITIALIZE_BOXES_EDIT_VIEW_REQUESTED, getBoxToEdit),
  takeLatest(boxConstants.BOXES_ON_EDIT_ONE_REQUESTED, editBox),
  takeLatest(boxConstants.BOXES_ON_DELETE_ONE_REQUESTED, deleteBox),
  takeLatest(boxConstants.BOXES_ON_CREATE_ONE_REQUESTED, createBox),
  takeLatest(boxConstants.BOXES_ON_DELETE_MANY_REQUESTED, deleteManyBoxes),
]);
