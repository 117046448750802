import React from 'react';
import { useDispatch } from 'react-redux';

import * as boxActions from 'src/redux/actions/box';
import boxInfoSelector from 'src/redux/selectors/box/boxInfo';
import institutionsListSelector from 'src/redux/selectors/institution/institutionsList';
import setTypeSelector from 'src/redux/selectors/setType/setType';

import { ISelectOption } from 'src/components/Inputs/SelectInput/types';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { INewBox } from 'src/types/box';
import BoxesNew from 'src/views/Boxes/BoxesNew';

import LayoutContainer from '../Layout/Layout';

const BoxesNewContainer = () => {
  const dispatch = useDispatch();

  const boxInfo = useTypedSelector(boxInfoSelector());
  const institutions: ISelectOption[] = useTypedSelector(institutionsListSelector()).map(
    (institution) => ({ value: institution.id, text: institution.name }),
  );
  const setType: ISelectOption[] = useTypedSelector(setTypeSelector());
  const onSubmit = (newBox: INewBox) => {
    dispatch(boxActions.onCreateOneRequested(newBox));
  };

  return (
    <LayoutContainer>
      <BoxesNew
        setType={setType}
        institutions={institutions}
        onSubmit={onSubmit}
        loading={boxInfo.info.loading}
      />
    </LayoutContainer>
  );
};

export default BoxesNewContainer;
