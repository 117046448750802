import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'USERS_LIST', {
  TITLE: 'Users',
});

i18next.addResources(locales.ES, 'USERS_LIST', {
  TITLE: 'Usuarios',
});
