import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';

export const DataTable = styled(DataGrid)`
  .MuiCircularProgress-root {
    color: ${(props) => props.theme.palette.primary};
  }
  .MuiCheckbox-root {
    color: ${(props) => props.theme.palette.primary} !important;
  }
  .MuiDataGrid-row {
    cursor: ${(props) => (props.onRowClick ? 'pointer' : 'default')};
  }
`;
