export const INITIALIZE_USERS_LIST_VIEW_REQUESTED = 'INITIALIZE_USERS_LIST_VIEW_REQUESTED';

export const INITIALIZE_USERS_LIST_VIEW_SUCCEEDED = 'INITIALIZE_USERS_LIST_VIEW_SUCCEEDED';

export const INITIALIZE_USERS_LIST_VIEW_FAILED = 'INITIALIZE_USERS_LIST_VIEW_FAILED';

export const USERS_ON_DELETE_ONE_REQUESTED = 'USERS_ON_DELETE_ONE_REQUESTED';

export const USERS_ON_DELETE_ONE_SUCCEEDED = 'USERS_ON_DELETE_ONE_SUCCEEDED';

export const USERS_ON_DELETE_ONE_FAILED = 'USERS_ON_DELETE_ONE_FAILED';

export const USERS_ON_DELETE_MANY_REQUESTED = 'USERS_ON_DELETE_MANY_REQUESTED';

export const USERS_ON_DELETE_MANY_SUCCEEDED = 'USERS_ON_DELETE_MANY_SUCCEEDED';

export const USERS_ON_DELETE_MANY_FAILED = 'USERS_ON_DELETE_MANY_FAILED';

export const USERS_ON_EDIT_ONE_REQUESTED = 'USERS_ON_EDIT_ONE_REQUESTED';

export const USERS_ON_EDIT_ONE_SUCCEEDED = 'USERS_ON_EDIT_ONE_SUCCEEDED';

export const USERS_ON_EDIT_ONE_FAILED = 'USERS_ON_EDIT_ONE_FAILED';

export const USERS_ON_CREATE_ONE_REQUESTED = 'USERS_ON_CREATE_ONE_REQUESTED';

export const USERS_ON_CREATE_ONE_SUCCEEDED = 'USERS_ON_CREATE_ONE_SUCCEEDED';

export const USERS_ON_CREATE_ONE_FAILED = 'USERS_ON_CREATE_ONE_FAILED';

export const INITIALIZE_USERS_EDIT_VIEW_REQUESTED = 'INITIALIZE_USERS_EDIT_VIEW_REQUESTED';

export const INITIALIZE_USERS_EDIT_VIEW_SUCCEEDED = 'INITIALIZE_USERS_EDIT_VIEW_SUCCEEDED';

export const INITIALIZE_USERS_EDIT_VIEW_FAILED = 'INITIALIZE_USERS_EDIT_VIEW_FAILED';
