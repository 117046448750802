import { AnyAction } from 'redux';
import { takeLatest, call, all, put, select } from 'redux-saga/effects';

import * as appActions from 'src/redux/actions/app';
import * as authActions from 'src/redux/actions/auth';
import * as appConstants from 'src/redux/constants/app';
import * as authContants from 'src/redux/constants/auth';
import * as authService from 'src/redux/services/auth';

import { getAuthToken } from 'src/helpers/user';
import ILoginResponse from 'src/types/auth';
import { IUser } from 'src/types/user';

import isUserLoggedSelector from '../selectors/auth/isUserLogged';
import userDataSelector from '../selectors/auth/userData';

function* loginUser(action: AnyAction) {
  try {
    const loginResponse: ILoginResponse = yield call(
      authService.loginUser,
      action.email,
      action.password,
    );
    yield put(
      authActions.loginSucceded(loginResponse.payload.token, loginResponse.payload.refreshToken),
    );
  } catch (error: any) {
    yield put(authActions.loginFailed(error));
  }
}

function* logoutUser(action: AnyAction) {
  try {
    yield call(authService.logoutUser, action.email);
    yield put(authActions.logoutSucceded());
  } catch (error: any) {
    yield put(authActions.logoutFailed(error));
  }
}

function* getUser() {
  try {
    if (getAuthToken()) {
      const user: { brickoutUser: IUser } = yield call(authService.getUser);
      return user.brickoutUser;
    }
    return null;
  } catch (error) {
    throw error;
  }
}

function* initializePrivateRoute() {
  try {
    const isUserLogged: boolean = yield select(isUserLoggedSelector());
    const user: IUser = yield select(userDataSelector());
    let userLogging = { ...user };

    if (!isUserLogged) {
      userLogging = yield call(getUser);
    }
    yield put(appActions.initializePrivateRouteSucceded(userLogging));
  } catch (error: any) {
    yield put(appActions.initializePrivateRouteFailed(error));
  }
}

function* initializePublicRoute(action: AnyAction) {
  try {
    const isUserLogged: boolean = yield select(isUserLoggedSelector());
    const user: IUser = yield select(userDataSelector());
    let userLogging = { ...user };
    if (!isUserLogged) {
      userLogging = yield call(getUser);
    }
    yield put(appActions.initializePublicRouteSucceded(userLogging, action.restricted));
  } catch (error: any) {
    yield put(appActions.initializePublicRouteFailed(error));
  }
}

export default all([
  takeLatest(appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_REQUESTED, initializePrivateRoute),
  takeLatest(appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_REQUESTED, initializePublicRoute),
  takeLatest(authContants.USER_ON_LOGIN_SUCCEEDED, getUser),
  takeLatest(authContants.USER_ON_LOGIN_REQUESTED, loginUser),
  takeLatest(authContants.USER_ON_LOGOUT_REQUESTED, logoutUser),
]);
