import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'QR_REQUESTS_LIST', {
  TITLE: 'QR requests',
});

i18next.addResources(locales.ES, 'QR_REQUESTS_LIST', {
  TITLE: 'Peticiones de QR',
});
