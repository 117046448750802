import React from 'react';

import { StyledButton } from './styles';
import { IButtonProps } from './types';

const Button = (props: IButtonProps) => {
  const { isDisabled, isLoading, children, className = '', onClick = () => {} } = props;

  return (
    <StyledButton
      fullWidth
      className={className}
      data-testid="button"
      disabled={isDisabled || isLoading}
      loading={isLoading}
      loadingPosition="center"
      onClick={onClick}
    >
      {!isLoading && children}
    </StyledButton>
  );
};

export default Button;
