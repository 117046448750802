import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as qrRequestActions from 'src/redux/actions/qrRequest';

import { useTypedSelector } from 'src/hooks/typeSelector';

import { ISelectOption } from '../../components/Inputs/SelectInput/types';
import qrRequestInfoSelector from '../../redux/selectors/qrRequests/qrRequestInfo';
import qrRequestToEditSelector from '../../redux/selectors/qrRequests/qrRequestToEdit';
import usersListSelector from '../../redux/selectors/user/usersList';
import { INewQrRequest } from '../../types/qrRequest';
import QrRequestsEdit from '../../views/QrRequests/QrRequestsEdit';
import LayoutContainer from '../Layout/Layout';

const QrRequestsEditContainer = () => {
  const dispatch = useDispatch();
  const routeParams = useRouteMatch<{ id: string }>();

  const qrRequestToEdit = useSelector(qrRequestToEditSelector());
  const qrRequestInfo = useTypedSelector(qrRequestInfoSelector());
  const users: ISelectOption[] = useTypedSelector(usersListSelector()).map((user) => ({
    value: user.id,
    text: user.email,
  }));
  const onSubmit = (id: number, qrRequest: INewQrRequest) => {
    dispatch(qrRequestActions.onEditOneRequested(id, qrRequest));
  };

  const onInitializeQrRequestsEdit = useCallback(() => {
    dispatch(qrRequestActions.initializeQrRequestsEditView(routeParams.params.id));
  }, [dispatch, routeParams.params.id]);

  useEffect(() => {
    onInitializeQrRequestsEdit();
  }, [onInitializeQrRequestsEdit]);

  if (!qrRequestToEdit) return null;

  return (
    <LayoutContainer>
      <QrRequestsEdit
        qrRequest={qrRequestToEdit}
        users={users}
        loading={qrRequestInfo.info.loading}
        onSubmit={onSubmit}
      />
    </LayoutContainer>
  );
};

export default QrRequestsEditContainer;
