import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const processingRequestsList = (id: string) =>
  createSelector(
    [
      (state: IReduxState) => state.request.requests,
      (state: IReduxState) => state.request.currentRequestReport.requestState,
    ],
    (requests, report) => {
      if (!requests || !report) return undefined;
      let response: any = undefined;
      const requestsFiltered = requests.filter((request) => request.id === Number(id));
      if (requestsFiltered.length !== 0) {
        response = [
          {
            id: requestsFiltered[0].id,
            institution: requestsFiltered[0].brickoutUser.institution.name,
            address: `${requestsFiltered[0].brickoutUser.institution.street} ${requestsFiltered[0].brickoutUser.institution.streetNumber}, ${requestsFiltered[0].brickoutUser.institution.door}`,
            address2: `${requestsFiltered[0].brickoutUser.institution.population}. ${requestsFiltered[0].brickoutUser.institution.province}. ${requestsFiltered[0].brickoutUser.institution.zipCode}`,
            phone: requestsFiltered[0].brickoutUser.institution.phoneNumber,
            user: `${requestsFiltered[0].brickoutUser.firstName} ${requestsFiltered[0].brickoutUser.lastName}`,
            dateCreated: requestsFiltered[0].dateCreated,
            daysOfDelay: dayjs().diff(requestsFiltered[0].dateCreated as Date, 'day'),
            boxOk: report.totalBoxOk,
            boxMissing: report.totalBoxMissing,
            totalBricksMissing: report.totalBricksMissing,
          },
        ];
      }
      return response;
    },
  );

export default processingRequestsList;
