export const INITIALIZE_INSTITUTIONS_LIST_VIEW_REQUESTED =
  'INITIALIZE_INSTITUTIONS_LIST_VIEW_REQUESTED';

export const INITIALIZE_INSTITUTIONS_LIST_VIEW_SUCCEEDED =
  'INITIALIZE_INSTITUTIONS_LIST_VIEW_SUCCEEDED';

export const INITIALIZE_INSTITUTIONS_LIST_VIEW_FAILED = 'INITIALIZE_INSTITUTIONS_LIST_VIEW_FAILED';

export const INSTITUTIONS_ON_DELETE_ONE_REQUESTED = 'INSTITUTIONS_ON_DELETE_ONE_REQUESTED';

export const INSTITUTIONS_ON_DELETE_ONE_SUCCEEDED = 'INSTITUTIONS_ON_DELETE_ONE_SUCCEEDED';

export const INSTITUTIONS_ON_DELETE_ONE_FAILED = 'INSTITUTIONS_ON_DELETE_ONE_FAILED';

export const INSTITUTIONS_ON_DELETE_MANY_REQUESTED = 'INSTITUTIONS_ON_DELETE_MANY_REQUESTED';

export const INSTITUTIONS_ON_DELETE_MANY_SUCCEEDED = 'INSTITUTIONS_ON_DELETE_MANY_SUCCEEDED';

export const INSTITUTIONS_ON_DELETE_MANY_FAILED = 'INSTITUTIONS_ON_DELETE_MANY_FAILED';

export const INSTITUTIONS_ON_EDIT_ONE_REQUESTED = 'INSTITUTIONS_ON_EDIT_ONE_REQUESTED';

export const INSTITUTIONS_ON_EDIT_ONE_SUCCEEDED = 'INSTITUTIONS_ON_EDIT_ONE_SUCCEEDED';

export const INSTITUTIONS_ON_EDIT_ONE_FAILED = 'INSTITUTIONS_ON_EDIT_ONE_FAILED';

export const INSTITUTIONS_ON_CREATE_ONE_REQUESTED = 'INSTITUTIONS_ON_CREATE_ONE_REQUESTED';

export const INSTITUTIONS_ON_CREATE_ONE_SUCCEEDED = 'INSTITUTIONS_ON_CREATE_ONE_SUCCEEDED';

export const INSTITUTIONS_ON_CREATE_ONE_FAILED = 'INSTITUTIONS_ON_CREATE_ONE_FAILED';

export const INITIALIZE_INSTITUTIONS_EDIT_VIEW_REQUESTED =
  'INITIALIZE_INSTITUTIONS_EDIT_VIEW_REQUESTED';

export const INITIALIZE_INSTITUTIONS_EDIT_VIEW_SUCCEEDED =
  'INITIALIZE_INSTITUTIONS_EDIT_VIEW_SUCCEEDED';

export const INITIALIZE_INSTITUTIONS_EDIT_VIEW_FAILED = 'INITIALIZE_INSTITUTIONS_EDIT_VIEW_FAILED';
