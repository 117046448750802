import i18next from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as boxActions from 'src/redux/actions/box';
import boxInfoSelector from 'src/redux/selectors/box/boxInfo';
import boxToEditSelector from 'src/redux/selectors/box/boxToEdit';
import institutionsListSelector from 'src/redux/selectors/institution/institutionsList';

import { ISelectOption } from 'src/components/Inputs/SelectInput/types';
import printQRs from 'src/helpers/printQR';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { INewBox } from 'src/types/box';
import BoxesEdit from 'src/views/Boxes/BoxesEdit';

import LayoutContainer from '../Layout/Layout';

const BoxesEditContainer = () => {
  const dispatch = useDispatch();
  const routeParams = useRouteMatch<{ id: string }>();

  const [downloading, setDownloading] = useState(false);
  const boxToEdit = useSelector(boxToEditSelector());
  const boxInfo = useTypedSelector(boxInfoSelector());
  const institutions: ISelectOption[] = useTypedSelector(institutionsListSelector()).map(
    (institution) => ({ value: institution.id, text: institution.name }),
  );

  const onSubmit = (id: string, box: INewBox) => {
    dispatch(boxActions.onEditOneRequested(id, box));
  };

  const onInitializeBoxesEdit = useCallback(() => {
    dispatch(boxActions.initializeBoxesEditView(routeParams.params.id));
  }, [dispatch, routeParams.params.id]);

  useEffect(() => {
    onInitializeBoxesEdit();
  }, [onInitializeBoxesEdit]);

  if (!boxToEdit) return null;

  const downloadQR = async () => {
    setDownloading(true);
    const labels = Array.from((document.getElementById('print_qr') as HTMLElement).children);
    if (labels.length) {
      await printQRs(
        labels,
        `${i18next.t('BOXES:BOX')}_${boxToEdit.id}_${boxToEdit.institution.name.replace(
          /\s/gm,
          '',
        )}_QR.pdf`,
      );
    }
    setDownloading(false);
  };

  return (
    <LayoutContainer>
      <BoxesEdit
        box={boxToEdit}
        institutions={institutions}
        loading={boxInfo.info.loading}
        downloading={downloading}
        onSubmit={onSubmit}
        onDownload={downloadQR}
      />
    </LayoutContainer>
  );
};

export default BoxesEditContainer;
