import styled from 'styled-components';

export const InstitutionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
`;

export const InstitutionsContent = styled.div`
  width: 100%;
  min-height: 680px;
  display: flex;
  flex-direction: column;
`;
