import i18next from 'i18next';
import React from 'react';

import Table from 'src/components/Table';

import './i18n';
import { UsersContainer, UsersContent } from './styles';
import { IUsersListProps } from './types';

const UsersList = (props: IUsersListProps) => {
  const {
    columns,
    sortModel,
    error,
    isLoading,
    users,
    onAddNew,
    hideAddNew,
    onEdit,
    onDeleteMany,
    hideDeleteMany,
    onDeleteOne,
  } = props;

  return (
    <UsersContainer data-testid="users-view">
      <UsersContent>
        <Table
          columns={columns}
          defaultSortModel={sortModel}
          error={error}
          rows={users}
          title={i18next.t('USERS_LIST:TITLE')}
          loading={isLoading}
          onAddNew={onAddNew}
          hideAddNew={hideAddNew}
          onEdit={onEdit}
          onDeleteOne={onDeleteOne}
          onDeleteMany={onDeleteMany}
          hideDeleteMany={hideDeleteMany}
        />
      </UsersContent>
    </UsersContainer>
  );
};

export default UsersList;
