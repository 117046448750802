import i18next from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as requestActions from 'src/redux/actions/request';
import requestToEditSelector from 'src/redux/selectors/request/requestToEdit';

import history from 'src/helpers/history';
import printQRs from 'src/helpers/printQR';
import { useTypedSelector } from 'src/hooks/typeSelector';
import RequestsEdit from 'src/views/Requests/RequestsEdit';

import LayoutContainer from '../Layout/Layout';
import './i18n';

const RequestsEditContainer = () => {
  const dispatch = useDispatch();
  const routeParams = useRouteMatch<{ id: string }>();
  const requestToEdit = useSelector(requestToEditSelector());
  const requestInfo = useTypedSelector((state) => state.request.requestInfo);
  const imagesRequest = useTypedSelector((state) => state.request.requestToEdit?.boxImages);
  const [seeLables, setSeeLabels] = useState<boolean>(false);
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);

  const handleBack = () => {
    if (seeLables) {
      setSeeLabels(false);
    } else {
      history.goBack();
    }
  };

  const handleDownloadLabels = async () => {
    setLoadingPDF(true);
    if (imagesRequest) {
      const labels = Array.from((document.getElementById('print_qr') as HTMLElement).children);
      if (labels.length) {
        await printQRs(
          labels,
          `${i18next.t('REQUESTS:LABELS')}_${i18next.t('REQUESTS:REQUEST')}_${
            routeParams.params.id
          }`,
        );
      }
    }
    setLoadingPDF(false);
  };

  const handleViewLabels = () => {
    setSeeLabels(true);
  };

  const onInitializeRequestsEdit = useCallback(() => {
    dispatch(requestActions.initializeRequestsEditView(routeParams.params.id));
  }, [dispatch, routeParams.params.id]);

  useEffect(() => {
    onInitializeRequestsEdit();
  }, [onInitializeRequestsEdit]);

  if (!requestToEdit) return null;

  function handleReprocessing() {
    dispatch(requestActions.reprocessRequest(routeParams.params.id));
  }

  function seeReprocessRequest() {
    console.log(requestToEdit?.status);
    return requestToEdit?.status === 'PROCESSING' || requestToEdit?.status === 'FAILED';
  }

  return (
    <LayoutContainer>
      <RequestsEdit
        request={requestToEdit}
        handleBack={handleBack}
        handleDownloadLabels={handleDownloadLabels}
        handleViewLabels={handleViewLabels}
        handleReprocessing={handleReprocessing}
        imagesRequest={imagesRequest || []}
        seeLables={seeLables}
        seeReprocessRequest={seeReprocessRequest()}
        loading={loadingPDF || requestInfo.info.loading}
      />
    </LayoutContainer>
  );
};

export default RequestsEditContainer;
