import { takeLatest, all, call, put } from 'redux-saga/effects';

import * as requestStatusActions from 'src/redux/actions/requestStatus';
import * as appConstants from 'src/redux/constants/app';
import * as requestStatusConstants from 'src/redux/constants/requestStatus';
import * as requestService from 'src/redux/services/request';

function* getRequestStatus() {
  try {
    const requestStatus: { requestStatus: string[] } = yield call(requestService.getRequestStatus);
    yield put(requestStatusActions.onGetSucceeded(requestStatus.requestStatus));
  } catch (error: any) {
    yield put(requestStatusActions.onGetFailed(error));
  }
}

export default all([
  takeLatest(appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_SUCCEDED, getRequestStatus),
  takeLatest(requestStatusConstants.REQUEST_STATUS_ON_REQUESTED, getRequestStatus),
]);
