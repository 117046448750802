import Cookies, { CookieAttributes } from 'js-cookie';

import { APP_DOMAIN, COOKIE_EXPIRATION, REFRESH_COOKIE_EXPIRATION } from 'src/configs/configs';

export const setCookie = (name: string, value: string, options: CookieAttributes = {}) => {
  Cookies.set(name, value, options);
};

export const getCookie = (name: string) => {
  return Cookies.get(name);
};

export const removeCookie = (name: string, options: CookieAttributes = {}) => {
  Cookies.remove(name, options);
};

const constructOptions = (expiration: string, domain: string) => {
  // localhost config
  let options: CookieAttributes = {
    expires: parseInt(expiration || '35', 10),
    sameSite: 'Lax',
    secure: false,
  };

  // production config
  if (!domain?.includes('localhost')) {
    options.domain = domain;
    options.sameSite = 'None';
    options.secure = true;
  }

  return options;
};

export const saveAuthToken = (authToken: string, refreshToken: string) => {
  const tokenOptions = constructOptions(COOKIE_EXPIRATION!, APP_DOMAIN!);
  const refreshTokenOptions = constructOptions(REFRESH_COOKIE_EXPIRATION!, APP_DOMAIN!);

  setCookie('token', authToken, tokenOptions);
  setCookie('refreshToken', refreshToken, refreshTokenOptions);
};

export const removeAuthToken = () => {
  let options = {};
  if (!APP_DOMAIN?.includes('localhost')) {
    options = { domain: APP_DOMAIN };
  }

  removeCookie('token', options);
  removeCookie('refreshToken', options);
};
