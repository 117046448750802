export const INITIALIZE_QR_REQUESTS_LIST_VIEW_REQUESTED =
  'INITIALIZE_QR_REQUESTS_LIST_VIEW_REQUESTED';
export const INITIALIZE_QR_REQUESTS_LIST_VIEW_SUCCEEDED =
  'INITIALIZE_QR_REQUESTS_LIST_VIEW_SUCCEEDED';
export const INITIALIZE_QR_REQUESTS_LIST_VIEW_FAILED = 'INITIALIZE_QR_REQUESTS_LIST_VIEW_FAILED';
export const INITIALIZE_QR_REQUESTS_EDIT_VIEW_REQUESTED =
  'INITIALIZE_QR_REQUESTS_EDIT_VIEW_REQUESTED';
export const INITIALIZE_QR_REQUESTS_EDIT_VIEW_SUCCEEDED =
  'INITIALIZE_QR_REQUESTS_EDIT_VIEW_SUCCEEDED';
export const INITIALIZE_QR_REQUESTS_EDIT_VIEW_FAILED = 'INITIALIZE_QR_REQUESTS_EDIT_VIEW_FAILED';
export const QR_REQUESTS_ON_EDIT_ONE_REQUESTED = 'QR_REQUESTS_ON_EDIT_ONE_REQUESTED';
export const QR_REQUESTS_ON_EDIT_ONE_SUCCEEDED = 'QR_REQUESTS_ON_EDIT_ONE_SUCCEEDED';
export const QR_REQUESTS_ON_EDIT_ONE_FAILED = 'QR_REQUESTS_ON_EDIT_ONE_FAILED';
export const QR_REQUESTS_ON_DELETE_ONE_REQUESTED = 'QR_REQUESTS_ON_DELETE_ONE_REQUESTED';
export const QR_REQUESTS_ON_DELETE_ONE_SUCCEEDED = 'QR_REQUESTS_ON_DELETE_ONE_SUCCEEDED';
export const QR_REQUESTS_ON_DELETE_ONE_FAILED = 'QR_REQUESTS_ON_DELETE_ONE_FAILED';
