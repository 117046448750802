import { get, post } from './api';

export async function loginUser(email: string, password: string) {
  return post('auth/login/admin', { email, password });
}

export async function logoutUser(email: string) {
  return post('auth/logout', { email });
}

export async function getUser() {
  return get('brickout-users/profile');
}
