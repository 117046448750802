import { PictureAsPdf } from '@mui/icons-material';
import styled from 'styled-components';

import StyledButton from 'src/components/Buttons/Button';

export const PreviewWrapper = styled.div`
  max-width: 800px;
  padding: 30px;
  margin: 30px auto;
`;

export const Title = styled.h2`
  font-size: 28px;
  color: ${(props) => props.theme.palette.primary};
  text-align: center;
  margin-bottom: 30px;
`;

export const PDFIcon = styled(PictureAsPdf)`
  margin-left: 10px;
`;

export const Button = styled(StyledButton)`
  width: auto !important;
  padding: 0 30px !important;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const QRsContainer = styled.div`
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(2, 1fr);
`;
