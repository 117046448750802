import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'REQUESTS_LIST', {
  TITLE: 'Requests',
});

i18next.addResources(locales.ES, 'REQUESTS_LIST', {
  TITLE: 'Solicitudes',
});
