import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const rolesSelector = () =>
  createSelector([(state: IReduxState) => state.roles.roles], (roles) =>
    roles.map((role) => ({ value: role, text: role })),
  );

export default rolesSelector;
