import { useFormik } from 'formik';
import i18next from 'i18next';
import React from 'react';

import Form from 'src/components/Form';
import { IFormInput } from 'src/components/Form/types';
import TextInput from 'src/components/Inputs/TextInput';

import './i18n';
import { FormWrapper, Title } from './styles';
import { IInstitutionNewProps } from './types';
import { validationSchema } from './validations';

const InstitutionsNew = (props: IInstitutionNewProps) => {
  const { onSubmit, loading } = props;

  const config = useFormik({
    initialValues: {
      name: '',
      street: '',
      streetNumber: '',
      door: '',
      zipCode: '',
      population: '',
      province: '',
      phoneNumber: '',
      clientId: '',
    },
    validationSchema,
    onSubmit: () => {
      onSubmit(config.values);
    },
  });

  const inputs: IFormInput[] = [
    {
      key: 'name',
      input: (
        <TextInput
          id="name"
          name="name"
          placeholder={i18next.t('INSTITUTIONS_NEW:NAME_LABEL')}
          error={config.touched.name && Boolean(config.errors.name) ? config.errors.name : ''}
          value={config.values.name}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'street',
      input: (
        <TextInput
          id="street"
          name="street"
          placeholder={i18next.t('INSTITUTIONS_NEW:STREET_LABEL')}
          error={config.touched.street && Boolean(config.errors.street) ? config.errors.street : ''}
          value={config.values.street}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'streetNumber',
      input: (
        <TextInput
          id="streetNumber"
          name="streetNumber"
          placeholder={i18next.t('INSTITUTIONS_NEW:STREET_NUMBER_LABEL')}
          error={
            config.touched.streetNumber && Boolean(config.errors.streetNumber)
              ? config.errors.streetNumber
              : ''
          }
          value={config.values.streetNumber}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'door',
      input: (
        <TextInput
          id="door"
          name="door"
          placeholder={i18next.t('INSTITUTIONS_NEW:DOOR_LABEL')}
          error={config.touched.door && Boolean(config.errors.door) ? config.errors.door : ''}
          value={config.values.door}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'zipCode',
      input: (
        <TextInput
          id="zipCode"
          name="zipCode"
          placeholder={i18next.t('INSTITUTIONS_NEW:ZIP_CODE_LABEL')}
          error={
            config.touched.zipCode && Boolean(config.errors.zipCode) ? config.errors.zipCode : ''
          }
          value={config.values.zipCode}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'population',
      input: (
        <TextInput
          id="population"
          name="population"
          placeholder={i18next.t('INSTITUTIONS_NEW:POPULATION_LABEL')}
          error={
            config.touched.population && Boolean(config.errors.population)
              ? config.errors.population
              : ''
          }
          value={config.values.population}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'province',
      input: (
        <TextInput
          id="province"
          name="province"
          placeholder={i18next.t('INSTITUTIONS_NEW:PROVINCE_LABEL')}
          error={
            config.touched.province && Boolean(config.errors.province) ? config.errors.province : ''
          }
          value={config.values.province}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'phoneNumber',
      input: (
        <TextInput
          id="phoneNumber"
          name="phoneNumber"
          placeholder={i18next.t('INSTITUTIONS_NEW:PHONE_LABEL')}
          error={
            config.touched.phoneNumber && Boolean(config.errors.phoneNumber)
              ? config.errors.phoneNumber
              : ''
          }
          value={config.values.phoneNumber}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
    {
      key: 'clientId',
      input: (
        <TextInput
          id="clientId"
          name="clientId"
          placeholder={i18next.t('INSTITUTIONS_NEW:CLIENT_ID_LABEL')}
          error={
            config.touched.clientId && Boolean(config.errors.clientId) ? config.errors.clientId : ''
          }
          value={config.values.clientId}
          isDisabled={false}
          onChange={config.handleChange}
        />
      ),
    },
  ];

  return (
    <FormWrapper>
      <Title> {i18next.t('INSTITUTIONS_NEW:TITLE')}</Title>
      <Form
        inputs={inputs}
        onSubmit={config.handleSubmit}
        isLoading={loading}
        submitText={i18next.t('INSTITUTIONS_NEW:SUBMIT_BUTTON')}
      />
    </FormWrapper>
  );
};

export default InstitutionsNew;
