import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const boxesListSelector = () =>
  createSelector([(state: IReduxState) => state.box.boxes], (boxes) => {
    if (!boxes) return [];
    return boxes.map((b) => ({
      id: b.id,
      set: b.set,
      institution: b.institution.name,
    }));
  });

export default boxesListSelector;
