import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const QRContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

export const QRInfo = styled.div`
  display: flex;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const QRDiv = styled.div``;

export const Text = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.palette.primary};
`;
