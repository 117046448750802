import i18next from 'i18next';
import { AnyAction } from 'redux';

import { notifyError, notifySuccess } from 'src/helpers/notifications';

import * as appConstants from '../constants/app';
import * as authConstants from '../constants/auth';
import * as boxConstants from '../constants/box';
import * as institutionConstants from '../constants/institution';
import * as qrRequestConstants from '../constants/qrRequest';
import * as requestConstants from '../constants/request';
import * as userConstants from '../constants/user';
import './i18n';

const notificationsMiddleware =
  () => (next: (action: AnyAction) => void) => (action: AnyAction) => {
    switch (action.type) {
      case authConstants.USER_ON_LOGIN_SUCCEEDED:
        notifySuccess(i18next.t('NOTIFICATIONS:SUCCESS'), i18next.t('NOTIFICATIONS:WELCOME'));
        break;
      case authConstants.USER_ON_LOGOUT_SUCCEEDED:
        notifySuccess(i18next.t('NOTIFICATIONS:SUCCESS'), i18next.t('NOTIFICATIONS:BYE'));
        break;
      case userConstants.USERS_ON_DELETE_ONE_SUCCEEDED:
        notifySuccess(i18next.t('NOTIFICATIONS:SUCCESS'), i18next.t('NOTIFICATIONS:USER_DELETED'));
        break;
      case userConstants.USERS_ON_DELETE_MANY_SUCCEEDED:
        notifySuccess(
          i18next.t('NOTIFICATIONS:SUCCESS'),
          `${action.countDeleted} ${i18next.t('NOTIFICATIONS:USERS_DELETED')}`,
        );
        break;
      case userConstants.USERS_ON_EDIT_ONE_SUCCEEDED:
        notifySuccess(i18next.t('NOTIFICATIONS:SUCCESS'), i18next.t('NOTIFICATIONS:USER_EDITED'));
        break;
      case userConstants.USERS_ON_CREATE_ONE_SUCCEEDED:
        notifySuccess(i18next.t('NOTIFICATIONS:SUCCESS'), i18next.t('NOTIFICATIONS:USER_CREATED'));
        break;
      case institutionConstants.INSTITUTIONS_ON_DELETE_ONE_SUCCEEDED:
        notifySuccess(
          i18next.t('NOTIFICATIONS:SUCCESS'),
          i18next.t('NOTIFICATIONS:INSTITUTION_DELETED'),
        );
        break;
      case institutionConstants.INSTITUTIONS_ON_DELETE_MANY_SUCCEEDED:
        notifySuccess(
          i18next.t('NOTIFICATIONS:SUCCESS'),
          `${action.countDeleted} ${i18next.t('NOTIFICATIONS:INSTITUTIONS_DELETED')}`,
        );
        break;
      case institutionConstants.INSTITUTIONS_ON_EDIT_ONE_SUCCEEDED:
        notifySuccess(
          i18next.t('NOTIFICATIONS:SUCCESS'),
          i18next.t('NOTIFICATIONS:INSTITUTION_EDITED'),
        );
        break;
      case institutionConstants.INSTITUTIONS_ON_CREATE_ONE_SUCCEEDED:
        notifySuccess(
          i18next.t('NOTIFICATIONS:SUCCESS'),
          i18next.t('NOTIFICATIONS:INSTITUTION_CREATED'),
        );
        break;
      case boxConstants.BOXES_ON_DELETE_ONE_SUCCEEDED:
        notifySuccess(i18next.t('NOTIFICATIONS:SUCCESS'), i18next.t('NOTIFICATIONS:BOX_DELETED'));
        break;
      case boxConstants.BOXES_ON_DELETE_MANY_SUCCEEDED:
        notifySuccess(
          i18next.t('NOTIFICATIONS:SUCCESS'),
          `${action.countDeleted} ${i18next.t('NOTIFICATIONS:BOXES_DELETED')}`,
        );
        break;
      case boxConstants.BOXES_ON_EDIT_ONE_SUCCEEDED:
        notifySuccess(i18next.t('NOTIFICATIONS:SUCCESS'), i18next.t('NOTIFICATIONS:BOX_EDITED'));
        break;
      case boxConstants.BOXES_ON_CREATE_ONE_SUCCEEDED:
        notifySuccess(i18next.t('NOTIFICATIONS:SUCCESS'), i18next.t('NOTIFICATIONS:BOX_CREATED'));
        break;
      case requestConstants.REQUESTS_ON_DELETE_ONE_SUCCEEDED:
        notifySuccess(
          i18next.t('NOTIFICATIONS:SUCCESS'),
          i18next.t('NOTIFICATIONS:REQUEST_DELETED'),
        );
        break;
      case requestConstants.REQUESTS_ON_DELETE_MANY_SUCCEEDED:
        notifySuccess(
          i18next.t('NOTIFICATIONS:SUCCESS'),
          `${action.countDeleted} ${i18next.t('NOTIFICATIONS:REQUESTS_DELETED')}`,
        );
        break;
      case requestConstants.REQUESTS_ON_EDIT_ONE_SUCCEEDED:
        notifySuccess(
          i18next.t('NOTIFICATIONS:SUCCESS'),
          i18next.t('NOTIFICATIONS:REQUEST_EDITED'),
        );
        break;
      case qrRequestConstants.QR_REQUESTS_ON_EDIT_ONE_SUCCEEDED:
        notifySuccess(
          i18next.t('NOTIFICATIONS:SUCCESS'),
          i18next.t('NOTIFICATIONS:QR_REQUEST_EDITED'),
        );
        break;
      case qrRequestConstants.QR_REQUESTS_ON_DELETE_ONE_SUCCEEDED:
        notifySuccess(
          i18next.t('NOTIFICATIONS:SUCCESS'),
          i18next.t('NOTIFICATIONS:QR_REQUEST_DELETED'),
        );
        break;
      case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_FAILED:
      case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_FAILED:
      case authConstants.USER_ON_LOGIN_FAILED:
      case authConstants.USER_ON_LOGOUT_FAILED:
      case userConstants.INITIALIZE_USERS_LIST_VIEW_FAILED:
      case userConstants.INITIALIZE_USERS_EDIT_VIEW_FAILED:
      case userConstants.USERS_ON_DELETE_ONE_FAILED:
      case userConstants.USERS_ON_DELETE_MANY_FAILED:
      case userConstants.USERS_ON_EDIT_ONE_FAILED:
      case userConstants.USERS_ON_CREATE_ONE_FAILED:
      case institutionConstants.INITIALIZE_INSTITUTIONS_LIST_VIEW_FAILED:
      case institutionConstants.INITIALIZE_INSTITUTIONS_EDIT_VIEW_FAILED:
      case institutionConstants.INSTITUTIONS_ON_DELETE_ONE_FAILED:
      case institutionConstants.INSTITUTIONS_ON_DELETE_MANY_FAILED:
      case institutionConstants.INSTITUTIONS_ON_EDIT_ONE_FAILED:
      case institutionConstants.INSTITUTIONS_ON_CREATE_ONE_FAILED:
      case boxConstants.INITIALIZE_BOXES_LIST_VIEW_FAILED:
      case boxConstants.INITIALIZE_BOXES_EDIT_VIEW_FAILED:
      case boxConstants.BOXES_ON_DELETE_ONE_FAILED:
      case boxConstants.BOXES_ON_DELETE_MANY_FAILED:
      case boxConstants.BOXES_ON_EDIT_ONE_FAILED:
      case boxConstants.BOXES_ON_CREATE_ONE_FAILED:
      case requestConstants.INITIALIZE_REQUESTS_LIST_VIEW_FAILED:
      case requestConstants.INITIALIZE_REQUESTS_EDIT_VIEW_FAILED:
      case requestConstants.REQUESTS_ON_DELETE_ONE_FAILED:
      case requestConstants.REQUESTS_ON_DELETE_MANY_FAILED:
      case requestConstants.REQUESTS_ON_EDIT_ONE_FAILED:
      case qrRequestConstants.QR_REQUESTS_ON_EDIT_ONE_FAILED:
      case qrRequestConstants.QR_REQUESTS_ON_DELETE_ONE_FAILED:
        if (!action.error.data?.message.includes('Unauthorized')) {
          const message = action.error.data?.message || action.error.errorMessage;
          notifyError(i18next.t('NOTIFICATIONS:ERROR'), message);
        }
        break;
      default:
        break;
    }
    next(action);
  };

export default notificationsMiddleware;
