import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import styled from 'styled-components';

export const Collapse = styled(Accordion)`
  width: 100%;
  margin: ${({ margin = 0 }: { margin?: number }) => `0 ${margin}px ${margin}px`};
`;

export const Summary = styled(AccordionSummary)``;

export const ReportDetails = styled(AccordionDetails)``;

export const Details = styled(AccordionDetails)`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
`;

export const Text = styled.div`
  padding: 5px;
  font-size: ${({ fontSize }: { fontSize: number; fontWeight: number }) => fontSize}px;
  font-weight: ${({ fontWeight }: { fontSize: number; fontWeight: number }) => fontWeight};
`;
