import { AnyAction } from 'redux';

import IReducerInfo from 'src/types/reducer';
import { IUser } from 'src/types/user';

import * as userConstants from '../constants/user';

export interface IUserReducerState {
  users: IUser[] | null;
  userToEdit: IUser | null;
  userInfo: IReducerInfo;
}

const defaultState: IUserReducerState = {
  users: null,
  userToEdit: null,
  userInfo: {
    info: {
      error: false,
      success: false,
      loading: false,
    },
    message: '',
  },
};

const setUsers = (state: IUserReducerState, users: IUser[] | null) => ({
  ...state,
  users,
});

const setUserToEdit = (state: IUserReducerState, userToEdit: IUser | null) => ({
  ...state,
  userToEdit,
});

const removeUsers = (state: IUserReducerState, idsToRemove: string[]) => {
  const newUsers = state.users?.filter((u) => !idsToRemove.includes(u.id)) || null;
  return {
    ...state,
    users: newUsers,
  };
};

const setUserInfo = (
  state: IUserReducerState,
  error: boolean,
  success: boolean,
  loading: boolean,
  message: string,
) => {
  return {
    ...state,
    userInfo: {
      info: {
        error,
        success,
        loading,
      },
      message,
    },
  };
};

const userReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case userConstants.INITIALIZE_USERS_LIST_VIEW_REQUESTED:
    case userConstants.INITIALIZE_USERS_EDIT_VIEW_REQUESTED:
    case userConstants.USERS_ON_DELETE_ONE_REQUESTED:
    case userConstants.USERS_ON_DELETE_MANY_REQUESTED:
    case userConstants.USERS_ON_EDIT_ONE_REQUESTED:
    case userConstants.USERS_ON_CREATE_ONE_REQUESTED:
      return setUserInfo(state, false, false, true, '');
    case userConstants.INITIALIZE_USERS_LIST_VIEW_SUCCEEDED:
      return setUserInfo(setUsers(state, action.users), false, true, false, '');
    case userConstants.INITIALIZE_USERS_EDIT_VIEW_SUCCEEDED:
      return setUserInfo(setUserToEdit(state, action.user), false, true, false, '');
    case userConstants.USERS_ON_DELETE_ONE_SUCCEEDED:
      return setUserInfo(removeUsers(state, [action.userId]), false, true, false, '');
    case userConstants.USERS_ON_DELETE_MANY_SUCCEEDED:
      return setUserInfo(removeUsers(state, action.userIds), false, true, false, '');
    case userConstants.USERS_ON_EDIT_ONE_SUCCEEDED:
      return setUserInfo(setUserToEdit(state, action.user), false, true, false, '');
    case userConstants.USERS_ON_CREATE_ONE_SUCCEEDED:
      return setUserInfo(setUsers(state, action.users), false, true, false, '');
    case userConstants.USERS_ON_DELETE_ONE_FAILED:
    case userConstants.USERS_ON_DELETE_MANY_FAILED:
    case userConstants.USERS_ON_EDIT_ONE_FAILED:
    case userConstants.USERS_ON_CREATE_ONE_FAILED:
      return setUserInfo(state, false, false, false, '');
    case userConstants.INITIALIZE_USERS_LIST_VIEW_FAILED:
    case userConstants.INITIALIZE_USERS_EDIT_VIEW_FAILED:
      return setUserInfo(state, true, false, false, action.errorMessage);
    default:
      return state;
  }
};

export default userReducer;
