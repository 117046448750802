export const USER_ON_INITIALIZE_LOGIN_VIEW = 'USER_ON_INITIALIZE_LOGIN_VIEW';

export const USER_ON_LOGIN_REQUESTED = 'USER_ON_LOGIN_REQUESTED';

export const USER_ON_LOGIN_SUCCEEDED = 'USER_ON_LOGIN_SUCCEEDED';

export const USER_ON_LOGIN_FAILED = 'USER_ON_LOGIN_FAILED';

export const USER_ON_LOGOUT_REQUESTED = 'USER_ON_LOGOUT_REQUESTED';

export const USER_ON_LOGOUT_SUCCEEDED = 'USER_ON_LOGOUT_SUCCEEDED';

export const USER_ON_LOGOUT_FAILED = 'USER_ON_LOGOUT_FAILED';
