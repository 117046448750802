import { INewUser } from 'src/types/user';

import { apiDelete, get, post, put } from './api';

export const getAll = () => {
  return get('brickout-users');
};

export const deleteMany = (userIds: string[]) => {
  return apiDelete('brickout-users', {}, { userIds });
};

export const getOne = (userId: string) => {
  return get(`brickout-users/${userId}`);
};

export const deleteOne = (userId: string) => {
  return apiDelete(`brickout-users/${userId}`);
};

export const editOne = (userId: string, newUser: INewUser) => {
  return put(`brickout-users/${userId}`, newUser);
};

export const createOne = (newUser: INewUser) => {
  return post('brickout-users', newUser);
};
