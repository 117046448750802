import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'USERS_EDIT', {
  SUBMIT_BUTTON: 'Edit',
  TITLE: 'EDIT USER',
  ID_LABEL: 'ID',
  FIRST_NAME_LABEL: 'First name',
  LAST_NAME_LABEL: 'Last name',
  PASSWORD_LABEL: 'Password',
  ROLE_LABEL: 'Role',
  INSTITUTION_LABEL: 'Institution',
  EMAIL_LABEL: 'Email',
  FIRST_NAME_REQUIRED: 'First name is required',
  LAST_NAME_REQUIRED: 'Last name is required',
  PASSWORD_REQUIRED: 'Password is required',
  ROLE_REQUIRED: 'Role is required',
  EMAIL_REQUIRED: 'Email is required',
  EMAIL_INVALID: 'Email is invalid',
  LOADING: 'Loading...',
});

i18next.addResources(locales.ES, 'USERS_EDIT', {
  SUBMIT_BUTTON: 'Guardar',
  TITLE: 'EDITAR USUARIO',
  ID_LABEL: 'ID',
  FIRST_NAME_LABEL: 'Nombre',
  LAST_NAME_LABEL: 'Apellido',
  PASSWORD_LABEL: 'Contraseña',
  ROLE_LABEL: 'Rol',
  INSTITUTION_LABEL: 'Institución',
  EMAIL_LABEL: 'Email',
  FIRST_NAME_REQUIRED: 'Nombre es requerido',
  LAST_NAME_REQUIRED: 'Apellido es requerido',
  PASSWORD_REQUIRED: 'Contraseña es requerido',
  ROLE_REQUIRED: 'Rol es requerido',
  EMAIL_REQUIRED: 'Email es requerido',
  EMAIL_INVALID: 'El email es inválido',
  LOADING: 'Cargando...',
});
