import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'HEADER', {
  TITLE: 'Welcome {{name}}!',
  CONFIRMATION_DIALOG_MESSAGE: 'Do you really want to logout?',
  LOGOUT: 'Log Out',
  PROCESSING_REQUESTS: 'Awaiting requests (old view)',
  USERS: 'Users',
  INSTITUTIONS: 'Institutions',
  BOXES: 'Boxes',
  REQUESTS: 'Requests',
  QR_REQUESTS: 'QR requests',
});

i18next.addResources(locales.ES, 'HEADER', {
  TITLE: '¡Bienvenido {{name}}!',
  CONFIRMATION_DIALOG_MESSAGE: '¿Seguro que quieres cerrar sesión?',
  LOGOUT: 'Cerrar sesión',
  PROCESSING_REQUESTS: 'Solicitudes pendientes (vista antigua)',
  USERS: 'Usuarios',
  INSTITUTIONS: 'Instituciones',
  BOXES: 'Cajas',
  REQUESTS: 'Solicitudes',
  QR_REQUESTS: 'Peticiones de QRs',
});
