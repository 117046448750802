import { Visibility } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

import { IViewActionButtonProps } from './types';

const ViewActionButton = (props: IViewActionButtonProps) => {
  const { onClick } = props;

  return (
    <IconButton color="inherit" data-testid="action-buttons-edit-button" onClick={onClick}>
      <Visibility />
    </IconButton>
  );
};

export default ViewActionButton;
