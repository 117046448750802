import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const STICKER_HEIGHT = 27;
const STICKER_WIDTH = 95;
const NUMBER_PER_PAGE = 16;
const X_MARGIN = 5;
const Y_MARGIN = 5;

const printQRs = async (labels: Element[], filename: string) => {
  const doc = new jsPDF({ unit: 'mm' });
  const labelsCanvas = await Promise.all(
    labels.map(async (label) => {
      const canva = await html2canvas(label as HTMLElement);
      return canva;
    }),
  );

  const pxHeight = labels[0].clientHeight;
  const pxWidth = labels[0].clientWidth;
  const scale = pxWidth / pxHeight;
  const labelWidth = Math.round(STICKER_HEIGHT * scale);
  const labelXOffset = Math.round((STICKER_WIDTH - labelWidth) / 2);

  let yOffset = 0,
    xOffset = 0;
  labelsCanvas.forEach((labelCanvas, index) => {
    if (index % 2 === 1) {
      xOffset = labelWidth + 3 * X_MARGIN + 3 * labelXOffset;
    } else {
      xOffset = X_MARGIN + labelXOffset;
    }
    if (index % NUMBER_PER_PAGE === 0 || (index - 1) % NUMBER_PER_PAGE === 0) {
      yOffset = Y_MARGIN;
    } else {
      const evenIndex = index % 2 === 0 ? index : index - 1;
      const tens = Math.floor(evenIndex / NUMBER_PER_PAGE);
      const multiplier = tens ? evenIndex - NUMBER_PER_PAGE * tens : evenIndex;
      yOffset = Y_MARGIN * (1 + multiplier) + STICKER_HEIGHT * (multiplier / 2);
    }
    if (index && index % NUMBER_PER_PAGE === 0) doc.addPage();
    doc.addImage(labelCanvas.toDataURL(), 'jpeg', xOffset, yOffset, labelWidth, STICKER_HEIGHT);
  });
  doc.save(filename);
};

export default printQRs;
