import i18next from 'i18next';
import React from 'react';

import BoxQR from 'src/components/BoxQR';
import Loading from 'src/components/Loading';

import './i18n';
import { Button, ButtonContainer, PDFIcon, PreviewWrapper, QRsContainer, Title } from './styles';
import { IBoxPreviewProps } from './types';

const BoxesPreview = (props: IBoxPreviewProps) => {
  const { boxes, isLoading, onDownload } = props;

  return (
    <PreviewWrapper>
      <Loading open={isLoading} text={i18next.t('BOXES_PREVIEW:LOADING_PDF')} />
      <Title>{i18next.t('BOXES_PREVIEW:TITLE')}</Title>
      <ButtonContainer>
        <Button onClick={onDownload} isLoading={isLoading} isDisabled={isLoading}>
          {i18next.t('BOXES_PREVIEW:DOWNLOAD')}
          <PDFIcon />
        </Button>
      </ButtonContainer>
      <QRsContainer id={'print_qr'}>
        {boxes.map((box) => (
          <BoxQR key={`box-qr-${box.id}`} id={box.id} />
        ))}
      </QRsContainer>
    </PreviewWrapper>
  );
};

export default BoxesPreview;
