import i18next from 'i18next';
import { QRCodeCanvas } from 'qrcode.react';
import React from 'react';

import './i18n';
import { Container, QRContainer, QRDiv, QRInfo, Text } from './styles';
import IBoxQRProps from './types';

const BoxQR = (props: IBoxQRProps) => {
  const { id, institution, address, address2, qrSize = 100, logo = '', logoSize = 0 } = props;

  return (
    <Container>
      <QRContainer id={`qr-${id}`}>
        <QRDiv>
          <QRCodeCanvas
            value={id.toString()}
            size={qrSize}
            imageSettings={{
              src: logo,
              height: logoSize,
              width: logoSize,
              excavate: true,
            }}
          />
        </QRDiv>
        <QRInfo>
          <Text id={`info-${id}`}>
            {i18next.t('BOX_QR:ID_BOX')}: {id}
          </Text>
          {institution && (
            <>
              <Text>{institution}</Text>
              <Text>{address}</Text>
              <Text>{address2}</Text>
            </>
          )}
        </QRInfo>
      </QRContainer>
    </Container>
  );
};

export default BoxQR;
