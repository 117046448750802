import { GridRowId, GridRowParams, GridSortModel, GridFilterModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import DeleteActionButton from './components/Delete';
import EditActionButton from './components/Edit';
import Toolbar from './components/Toolbar';
import ViewActionButton from './components/View';
import { DataTable } from './styles';
import { ITableProps } from './types';

const Table = (props: ITableProps) => {
  const {
    columns,
    rows,
    title,
    loading,
    error = null,
    pageSize = 10,
    disableVirtualization = false,
    onAddNew,
    hideAddNew = false,
    onDeleteOne,
    onDeleteMany,
    hideActions = false,
    hideDeleteMany = false,
    defaultSortModel = [],
    onRowClick,
    onEdit,
    showEditButton,
    onView,
    hideFooter = false,
  } = props;
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });

  const handleEditRow = (row: GridRowParams) => {
    if (onEdit) {
      onEdit(row.id.toString());
    }
  };

  const handleViewRow = (row: GridRowParams) => {
    if (onView) {
      onView(row.id.toString());
    }
  };

  const handleDeleteRow = (row: GridRowParams) => {
    if (onDeleteOne) {
      onDeleteOne(row.id.toString());
    }
  };

  const handleBulkDelete = () => {
    if (onDeleteMany) {
      onDeleteMany(selectedRows);
    }
  };

  const handleRowSelected = (selectedRows: GridRowId[]) => {
    setSelectedRows(selectedRows.map((id) => id.toString()));
  };

  const gridColumns = hideActions
    ? columns
    : [
        ...columns,
        {
          field: 'Actions',
          type: 'actions',
          flex: 1,
          getActions: (row: GridRowParams) => [
            onView ? <ViewActionButton onClick={() => handleViewRow(row)} /> : <></>,
            onEdit && showEditButton && showEditButton(row) ? (
              <EditActionButton onClick={() => handleEditRow(row)} />
            ) : (
              <></>
            ),
            onDeleteOne ? <DeleteActionButton onClick={() => handleDeleteRow(row)} /> : <></>,
          ],
        },
      ];

  const currentLocation = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(currentLocation.search);
    const filterColumn = queryParams.get('filterColumn');
    const filterValue = queryParams.get('filterValue');

    if (filterColumn && filterValue) {
      setFilterModel({
        items: [{ columnField: filterColumn, operatorValue: 'equals', value: filterValue }],
      });
    } else {
      setFilterModel({ items: [] });
    }
  }, [currentLocation.search]);

  return (
    <DataTable
      hideFooter={hideFooter}
      disableVirtualization={disableVirtualization}
      columns={gridColumns}
      rows={rows}
      pageSize={pageSize}
      loading={loading}
      editMode="row"
      error={error}
      rowsPerPageOptions={[]}
      onRowClick={onRowClick}
      onCellDoubleClick={(params, event) => {
        if (!event.ctrlKey) {
          event.defaultMuiPrevented = true;
        }
      }}
      onSelectionModelChange={handleRowSelected}
      checkboxSelection={hideActions ? false : !hideDeleteMany}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      disableSelectionOnClick
      autoHeight
      components={{
        Toolbar: () => (
          <Toolbar
            onAddNew={onAddNew ? onAddNew : () => null}
            hideAddNew={hideAddNew || hideActions}
            onBulkDelete={handleBulkDelete}
            title={title}
            hideBulkDelete={hideDeleteMany || hideActions}
            disabledBulkDelete={selectedRows.length < 2}
          />
        ),
      }}
      filterModel={filterModel}
    />
  );
};

export default Table;
