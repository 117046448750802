import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'REQUESTS_EDIT', {
  SUBMIT_BUTTON: 'Edit',
  TITLE: 'REQUEST N',
  ID_LABEL: 'ID',
  INSTITUTION_LABEL: 'Institution',
  STATUS_LABEL: 'Status',
  USER_LABEL: 'User',
  CREATED_LABEL: 'Created at',
  USER_REQUIRED: 'User is required',
  STATUS_REQUIRED: 'Status is required',
  DOWNLOAD_LABELS: 'Download labels',
  VIEW_LABELS: 'View labels',
  STATUS_PENDING: 'EXPECTED',
  STATUS_PROCESSING: 'SLOPES',
  STATUS_COMPLETED: 'SERVED',
  STATUS_FAILED: 'FAILED PROCESSING',
  ERROR_MESSAGE_LABEL: 'Error message',
  BACK: 'BACK',
  LOADING: 'Loading...',
  REPROCESS_REQUEST: 'Reprocess request',
});

i18next.addResources(locales.ES, 'REQUESTS_EDIT', {
  SUBMIT_BUTTON: 'Guardar',
  TITLE: 'SOLICITUD N',
  ID_LABEL: 'ID',
  INSTITUTION_LABEL: 'Institución',
  STATUS_LABEL: 'Estado',
  USER_LABEL: 'Usuario',
  CREATED_LABEL: 'Creado en',
  USER_REQUIRED: 'Usuario es requerido',
  STATUS_REQUIRED: 'Estado es requerido',
  DOWNLOAD_LABELS: 'Descargar etiquetas',
  VIEW_LABELS: 'Ver etiquetas',
  STATUS_PENDING: 'ESPERADAS',
  STATUS_PROCESSING: 'PENDIENTES',
  STATUS_COMPLETED: 'SERVIDAS',
  STATUS_FAILED: 'PROCESADO FALLIDO',
  ERROR_MESSAGE_LABEL: 'Mensaje de error',
  BACK: 'VOLVER',
  LOADING: 'Cargando...',
  REPROCESS_REQUEST: 'Reprocesar solicitud',
});
