import * as institutionConstants from 'src/redux/constants/institution';

import { IInstitution, INewInstitution } from 'src/types/institution';

import { IAPIResponse } from '../services/api';

export const initializeInstitutionsListView = () => ({
  type: institutionConstants.INITIALIZE_INSTITUTIONS_LIST_VIEW_REQUESTED,
});

export const initializeInstitutionsListViewSucceeded = (institutions: IInstitution[]) => ({
  type: institutionConstants.INITIALIZE_INSTITUTIONS_LIST_VIEW_SUCCEEDED,
  institutions,
});

export const initializeInstitutionsListViewFailed = (error: IAPIResponse) => ({
  type: institutionConstants.INITIALIZE_INSTITUTIONS_LIST_VIEW_FAILED,
  error,
});

export const initializeInstitutionsEditView = (institutionId: string) => ({
  type: institutionConstants.INITIALIZE_INSTITUTIONS_EDIT_VIEW_REQUESTED,
  institutionId,
});

export const initializeInstitutionsEditViewSucceeded = (institution: IInstitution) => ({
  type: institutionConstants.INITIALIZE_INSTITUTIONS_EDIT_VIEW_SUCCEEDED,
  institution,
});

export const initializeInstitutionsEditViewFailed = (error: IAPIResponse) => ({
  type: institutionConstants.INITIALIZE_INSTITUTIONS_EDIT_VIEW_FAILED,
  error,
});

export const onDeleteOneRequested = (institutionId: string) => ({
  type: institutionConstants.INSTITUTIONS_ON_DELETE_ONE_REQUESTED,
  institutionId,
});

export const onDeleteOneSucceeded = (institutionId: string) => ({
  type: institutionConstants.INSTITUTIONS_ON_DELETE_ONE_SUCCEEDED,
  institutionId,
});

export const onDeleteOneFailed = (error: IAPIResponse) => ({
  type: institutionConstants.INSTITUTIONS_ON_DELETE_ONE_FAILED,
  error,
});

export const onDeleteManyRequested = (institutionIds: string[]) => ({
  type: institutionConstants.INSTITUTIONS_ON_DELETE_MANY_REQUESTED,
  institutionIds,
});

export const onDeleteManySucceeded = (institutionIds: string[], countDeleted: number) => ({
  type: institutionConstants.INSTITUTIONS_ON_DELETE_MANY_SUCCEEDED,
  institutionIds,
  countDeleted,
});

export const onDeleteManyFailed = (error: IAPIResponse) => ({
  type: institutionConstants.INSTITUTIONS_ON_DELETE_MANY_FAILED,
  error,
});

export const onEditOneRequested = (institutionId: number, newInstitution: INewInstitution) => ({
  type: institutionConstants.INSTITUTIONS_ON_EDIT_ONE_REQUESTED,
  institutionId,
  newInstitution,
});

export const onEditOneSucceeded = (institution: IInstitution) => ({
  type: institutionConstants.INSTITUTIONS_ON_EDIT_ONE_SUCCEEDED,
  institution,
});

export const onEditOneFailed = (error: IAPIResponse) => ({
  type: institutionConstants.INSTITUTIONS_ON_EDIT_ONE_FAILED,
  error,
});

export const onCreateOneRequested = (institution: INewInstitution) => ({
  type: institutionConstants.INSTITUTIONS_ON_CREATE_ONE_REQUESTED,
  institution,
});

export const onCreateOneSucceeded = (institutions: IInstitution[]) => ({
  type: institutionConstants.INSTITUTIONS_ON_CREATE_ONE_SUCCEEDED,
  institutions,
});

export const onCreateOneFailed = (error: IAPIResponse) => ({
  type: institutionConstants.INSTITUTIONS_ON_CREATE_ONE_FAILED,
  error,
});
