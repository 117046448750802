import { IconButton } from '@mui/material';
import React from 'react';

import { AddIcon, Label } from './styles';
import { IAddActionButtonProps } from './types';

const AddActionButton = (props: IAddActionButtonProps) => {
  const { onClick, disabled = false, label = '' } = props;

  return (
    <>
      <IconButton
        color="inherit"
        data-testid="action-buttons-add-button"
        disabled={disabled}
        onClick={onClick}
      >
        <>
          <AddIcon />
          {label !== '' && <Label isDisabled={disabled}>{label}</Label>}
        </>
      </IconButton>
    </>
  );
};

export default AddActionButton;
