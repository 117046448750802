import { AnyAction } from 'redux';

import IReducerInfo from 'src/types/reducer';
import { IRequest, IRequestReport, ISingleRequestReport, IBoxImage } from 'src/types/request';

import * as requestConstants from '../constants/request';

export interface IRequestReducerState {
  requests: IRequest[] | null;
  requestToEdit: IRequest | null;
  requestInfo: IReducerInfo;
  requestsReport: IRequestReport;
  currentRequestReport: ISingleRequestReport;
}

const defaultState: IRequestReducerState = {
  requests: null,
  requestToEdit: null,
  requestsReport: {
    totalRequests: 0,
    totalBox: 0,
    totalBricksMissing: 0,
  },
  requestInfo: {
    info: {
      error: false,
      success: false,
      loading: false,
    },
    message: '',
  },
  currentRequestReport: {
    requestState: {
      totalBoxOk: 0,
      totalBoxMissing: 0,
      totalBricksMissing: 0,
    },
  },
};

const setRequests = (state: IRequestReducerState, payload: any | null) => ({
  ...state,
  requests: payload.requests,
  requestsReport: {
    totalRequests: payload.totalRequests,
    totalBox: payload.totalBox,
    totalBricksMissing: payload.totalBricksMissing,
  },
});

const setCurrentRequestReport = (state: IRequestReducerState, payload: any | null) => ({
  ...state,
  currentRequestReport: payload,
});

const setRequestToEdit = (state: IRequestReducerState, requestToEdit: IRequest | null) => ({
  ...state,
  requestToEdit,
});

const removeRequests = (state: IRequestReducerState, idsToRemove: number[]) => {
  const newRequests = state.requests?.filter((r) => !idsToRemove.includes(r.id)) || null;
  return {
    ...state,
    requests: newRequests,
  };
};

const setRequestInfo = (
  state: IRequestReducerState,
  error: boolean,
  success: boolean,
  loading: boolean,
  message: string,
) => {
  return {
    ...state,
    requestInfo: {
      info: {
        error,
        success,
        loading,
      },
      message,
    },
  };
};

const updateBoxImage = (state: IRequestReducerState, newBoxImage: IBoxImage) => {
  const newBoxImages = state.requestToEdit?.boxImages;
  if (newBoxImages) {
    for (let i = 0; i < newBoxImages.length; i++) {
      if (newBoxImages[i].id === newBoxImage.id) {
        newBoxImages[i] = newBoxImage;
      }
    }
  }

  return {
    ...state,
    requestToEdit: { ...state.requestToEdit, boxImages: newBoxImages },
    requestInfo: { ...state.requestInfo, info: { error: false, success: true, loading: false } },
  };
};

const requestReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case requestConstants.INITIALIZE_REQUESTS_LIST_VIEW_REQUESTED:
    case requestConstants.INITIALIZE_REQUESTS_EDIT_VIEW_REQUESTED:
    case requestConstants.REQUESTS_ON_DELETE_ONE_REQUESTED:
    case requestConstants.REQUESTS_ON_DELETE_MANY_REQUESTED:
    case requestConstants.REQUESTS_ON_EDIT_ONE_REQUESTED:
    case requestConstants.UPDATE_ACCEPTED_FIELD_BOXIMAGES:
    case requestConstants.GET_REQUEST_STATE:
      return setRequestInfo(state, false, false, true, '');
    case requestConstants.INITIALIZE_REQUESTS_LIST_VIEW_SUCCEEDED:
      return setRequestInfo(setRequests(state, action.payload), false, true, false, '');
    case requestConstants.INITIALIZE_REQUESTS_EDIT_VIEW_SUCCEEDED:
      return setRequestInfo(setRequestToEdit(state, action.request), false, true, false, '');
    case requestConstants.REQUESTS_ON_DELETE_ONE_SUCCEEDED:
      return setRequestInfo(removeRequests(state, [action.requestId]), false, true, false, '');
    case requestConstants.REQUESTS_ON_DELETE_MANY_SUCCEEDED:
      return setRequestInfo(removeRequests(state, action.requestIds), false, true, false, '');
    case requestConstants.REQUESTS_ON_EDIT_ONE_SUCCEEDED:
      return setRequestInfo(setRequestToEdit(state, action.request), false, true, false, '');
    case requestConstants.REQUESTS_ON_DELETE_ONE_FAILED:
    case requestConstants.REQUESTS_ON_DELETE_MANY_FAILED:
    case requestConstants.REQUESTS_ON_EDIT_ONE_FAILED:
    case requestConstants.UPDATE_ACCEPTED_FIELD_BOXIMAGES_FAILED:
    case requestConstants.GET_REQUEST_STATE_FAILED:
      return setRequestInfo(state, false, false, false, '');
    case requestConstants.INITIALIZE_REQUESTS_LIST_VIEW_FAILED:
    case requestConstants.INITIALIZE_REQUESTS_EDIT_VIEW_FAILED:
      return setRequestInfo(state, true, false, false, action.errorMessage);
    case requestConstants.UPDATE_ACCEPTED_FIELD_BOXIMAGES_SUCCEEDED:
      return updateBoxImage(state, action.newBoxImage);
    case requestConstants.GET_REQUEST_STATE_SUCCEEDED:
      return setCurrentRequestReport(state, action.report);
    default:
      return state;
  }
};

export default requestReducer;
