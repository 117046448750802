import { AnyAction } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { IQrRequest } from '../../types/qrRequest';
import * as qrRequestActions from '../actions/qrRequest';
import * as appConstants from '../constants/app';
import * as qrRequestConstants from '../constants/qrRequest';
import * as qrRequestService from '../services/qrRequest';

function* listQrRequest() {
  try {
    const qrRequests: { qrRequests: IQrRequest[] } = yield call(qrRequestService.getAll);
    yield put(qrRequestActions.initializeQrRequestsListViewSucceeded(qrRequests.qrRequests));
  } catch (error: any) {
    yield put(qrRequestActions.initializeQrRequestsListViewFailed(error));
  }
}

function* getQrRequestToEdit(action: AnyAction) {
  try {
    const qrRequest: { qrRequest: IQrRequest } = yield call(
      qrRequestService.getOne,
      action.qrRequestId,
    );
    yield put(qrRequestActions.initializeQrRequestsEditViewSucceeded(qrRequest.qrRequest));
  } catch (error: any) {
    yield put(qrRequestActions.initializeQrRequestsEditViewFailed(error));
  }
}

function* editQrRequest(action: AnyAction) {
  try {
    const editedQrRequest: { qrRequest: IQrRequest } = yield call(
      qrRequestService.editOne,
      action.qrRequestId,
      action.newQrRequest,
    );
    yield put(qrRequestActions.onEditOneSucceeded(editedQrRequest.qrRequest));
  } catch (error: any) {
    yield put(qrRequestActions.onEditOneFailed(error));
  }
}

function* deleteQrRequest(action: AnyAction) {
  try {
    yield call(qrRequestService.deleteOne, action.qrRequestId);
    yield put(qrRequestActions.onDeleteOneSucceeded(action.qrRequestId));
  } catch (error: any) {
    yield put(qrRequestActions.onDeleteOneFailed(error));
  }
}

export default all([
  takeLatest(qrRequestConstants.INITIALIZE_QR_REQUESTS_LIST_VIEW_REQUESTED, listQrRequest),
  takeLatest(qrRequestConstants.QR_REQUESTS_ON_DELETE_ONE_SUCCEEDED, listQrRequest),
  takeLatest(appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_SUCCEDED, listQrRequest),
  takeLatest(qrRequestConstants.INITIALIZE_QR_REQUESTS_EDIT_VIEW_REQUESTED, getQrRequestToEdit),
  takeLatest(qrRequestConstants.QR_REQUESTS_ON_EDIT_ONE_REQUESTED, editQrRequest),
  takeLatest(qrRequestConstants.QR_REQUESTS_ON_DELETE_ONE_REQUESTED, deleteQrRequest),
]);
