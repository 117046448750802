import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import styled from 'styled-components';

export const Input = styled(TextField)`
  .MuiInputLabel-root {
    color: ${(props) => props.theme.palette.primary};
  }
  label.Mui-focused {
    color: ${(props) => props.theme.palette.primary};
  }
`;

export const ToggleButton = styled(IconButton)``;

export const EyeIcon = styled(Visibility)`
  color: ${(props) => props.theme.palette.primary};
`;

export const SlashedEyeIcon = styled(VisibilityOff)`
  color: ${(props) => props.theme.palette.primary};
`;

export const LockIcon = styled(Lock)`
  color: ${(props) => props.theme.palette.primary};
`;
