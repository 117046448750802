import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import history from 'src/helpers/history';

import authReducer from './auth';
import boxReducer from './box';
import institutionReducer from './institution';
import qrRequestReducer from './qrRequest';
import requestReducer from './request';
import requestStatusReducer from './requestStatus';
import rolesReducer from './roles';
import setTypeReducer from './setType';
import userReducer from './user';

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  user: userReducer,
  roles: rolesReducer,
  requestStatus: requestStatusReducer,
  setType: setTypeReducer,
  institution: institutionReducer,
  box: boxReducer,
  request: requestReducer,
  qrRequest: qrRequestReducer,
});

export default rootReducer;
