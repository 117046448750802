import i18next from 'i18next';
import React from 'react';

import BoxImageCollapse from '../BoxImageCollapse';
import './i18n';
import { Container, Text } from './styles';
import IBoxImagesContainerProps from './types';

const BoxImagesContainer = (props: IBoxImagesContainerProps) => {
  const { boxes } = props;

  return (
    <Container>
      <Text fontSize={20} fontWeight={600}>
        {`${i18next.t('BOX_IMAGE_CONTAINER:TITLE')}`}
      </Text>
      {boxes.map((box) => (
        <BoxImageCollapse key={box.id} id={box.box.id} report={box.report} />
      ))}
    </Container>
  );
};

export default BoxImagesContainer;
