import { INewRequest } from 'src/types/request';

import { apiDelete, get, post, put } from './api';

export const getAll = (status?: string) => {
  return get(`requests?status=${status || ''}`);
};

export const getRequestStatus = () => {
  return get('requests/status');
};

export const deleteMany = (requestIds: string[]) => {
  return apiDelete('requests', {}, { requestIds });
};

export const getOne = (requestId: string) => {
  return get(`requests/${requestId}`);
};

export const getBoxImages = (requestId: string) => {
  return get(`box-images?request=${requestId}`);
};

export const deleteOne = (requestId: string) => {
  return apiDelete(`requests/${requestId}`);
};

export const editOne = (requestId: string, newRequest: INewRequest) => {
  return put(`requests/${requestId}`, newRequest);
};

export const updateBoxImages = (boxImageId: string, accepted: boolean) => {
  return put(`box-images/${boxImageId}`, { accepted });
};

export const reprocessRequest = (requestId: string) => {
  return post('box-images/recreate-queue', { requestId });
};

export const getRequestState = (requestId: string) => {
  return get(`requests/request-state/${requestId}/`);
};
