export const APP_DOMAIN = process.env.REACT_APP_APP_DOMAIN;

export const APP_URL = process.env.REACT_APP_APP_URL;

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const BRICKS_URL = process.env.REACT_APP_BRICKS_URL;

export const COOKIE_EXPIRATION = process.env.REACT_APP_COOKIE_EXPIRATION;

export const REFRESH_COOKIE_EXPIRATION = process.env.REACT_APP_REFRESH_COOKIE_EXPIRATION;
