import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'INSTITUTIONS_LIST', {
  TITLE: 'Institutions',
});

i18next.addResources(locales.ES, 'INSTITUTIONS_LIST', {
  TITLE: 'Instituciones',
});
