import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from 'i18next';
import React from 'react';

import TextButton from 'src/components/Buttons/TextButton';
import ImagesRequestQr from 'src/components/ImagesRequestQr';
import Loading from 'src/components/Loading';
import Table from 'src/components/Table';
import BrickCard from 'src/views/Requests/RequestsEdit/components/BrickCard';

import './i18n';
import {
  ProcessingRequestsDetailsContainer,
  ProcessingRequestsTableContent,
  ProcessingRequestsDetailsContent,
  ButtonContainer,
  BrickCardContainer,
  LeftButtonsContainer,
  ImageContainer,
  Image,
  ImagesFooterText,
  Row1,
  Row2Bricks,
  Row2Image,
  Row3,
  Info,
} from './styles';
import { IProcessingRequestsDetailsProps } from './types';

const ProcessingRequestsDetailsView = ({
  showModal,
  columns,
  requests,
  error,
  isLoading,
  loadingPDF,
  boxId,
  imagesRequest,
  boxImagesIndex,
  showImages,
  modalType,
  showPrintScreen,
  handleModal,
  handleSeeImagesButton,
  handlePrintLabel,
  handleConfirm,
  handleRejectModal,
  handleClose,
  handleContinueModal,
}: IProcessingRequestsDetailsProps) => {
  return (
    <ProcessingRequestsDetailsContainer>
      <Loading
        open={loadingPDF || isLoading}
        text={i18next.t(`PROCESSING_REQUEST_DETAILS:${isLoading ? 'LOADING' : 'LOADING_PDF'}`)}
      />
      <ProcessingRequestsTableContent>
        <Table
          columns={columns}
          rows={requests}
          error={error}
          title={''}
          pageSize={1}
          loading={isLoading}
          hideActions
          hideFooter
        />
      </ProcessingRequestsTableContent>
      <ProcessingRequestsDetailsContent>
        {showPrintScreen ? (
          <ImagesRequestQr imagesRequest={imagesRequest} />
        ) : (
          <>
            <Row1>
              <Info id={'boxId'}>
                {i18next.t('PROCESSING_REQUEST_DETAILS:BOX_ID')} {boxId}
              </Info>
            </Row1>
            {showImages ? (
              <>
                <Row2Image>
                  {imagesRequest[boxImagesIndex].images.map((imageUrl, index) => (
                    <ImageContainer key={imageUrl}>
                      <a href={imageUrl} target="_blank" rel="noopener noreferrer">
                        <Image src={imageUrl} alt={`Request ${index}`} />
                      </a>
                    </ImageContainer>
                  ))}
                </Row2Image>
                <ImagesFooterText>
                  {i18next.t('PROCESSING_REQUEST_DETAILS:FOOTER_IMAGES')}
                </ImagesFooterText>
              </>
            ) : (
              <Row2Bricks>
                {Object.keys(imagesRequest[boxImagesIndex].report.missing).map((id) => (
                  <BrickCardContainer key={id}>
                    <BrickCard
                      id={id}
                      quantity={imagesRequest[boxImagesIndex].report.missing[id]}
                    />
                  </BrickCardContainer>
                ))}
              </Row2Bricks>
            )}
          </>
        )}
        <Row3>
          <LeftButtonsContainer>
            {!showPrintScreen ? (
              <>
                <ButtonContainer>
                  <TextButton
                    text={i18next.t(
                      showImages
                        ? 'PROCESSING_REQUEST_DETAILS:VIEW_BRICKS'
                        : 'PROCESSING_REQUEST_DETAILS:IMAGES',
                    )}
                    onSubmit={handleSeeImagesButton}
                  />
                </ButtonContainer>
                <ButtonContainer>
                  <TextButton
                    text={i18next.t('PROCESSING_REQUEST_DETAILS:NOT_ACCEPTED')}
                    onSubmit={handleRejectModal}
                  />
                </ButtonContainer>
              </>
            ) : (
              <ButtonContainer>
                <TextButton
                  text={i18next.t('PROCESSING_REQUEST_DETAILS:PRINT_LABEL')}
                  onSubmit={handlePrintLabel}
                />
              </ButtonContainer>
            )}
          </LeftButtonsContainer>
          {!showPrintScreen ? (
            <ButtonContainer>
              <TextButton
                text={i18next.t('PROCESSING_REQUEST_DETAILS:VERIFIED')}
                onSubmit={handleConfirm}
              />
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <TextButton
                text={i18next.t('PROCESSING_REQUEST_DETAILS:CONTINUE')}
                onSubmit={handleContinueModal}
              />
            </ButtonContainer>
          )}
        </Row3>
      </ProcessingRequestsDetailsContent>
      <Dialog open={showModal} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <DialogTitle id="alert-dialog-title">
          {i18next.t(`PROCESSING_REQUEST_DETAILS:${modalType}_MODAL`)}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>{i18next.t('PROCESSING_REQUEST_DETAILS:CLOSE')}</Button>
          <Button onClick={handleModal} autoFocus>
            {i18next.t('PROCESSING_REQUEST_DETAILS:CONTINUE')}
          </Button>
        </DialogActions>
      </Dialog>
    </ProcessingRequestsDetailsContainer>
  );
};

export default ProcessingRequestsDetailsView;
