import React from 'react';

import PrivateRoute from 'src/components/PrivateRoute';
import ProcessingRequestsDetails from 'src/containers/ProcessingRequests/ProcessingRequestsDetails';

const InstitutionRoutes = (isUserLogged: boolean) => {
  return [
    <PrivateRoute
      key="list"
      path="/processing-requests/:id"
      exact
      component={ProcessingRequestsDetails}
      isUserLogged={isUserLogged}
    />,
  ];
};

export default InstitutionRoutes;
