import { GridColumns, GridRowModel } from '@mui/x-data-grid';
import i18next from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as institutionActions from 'src/redux/actions/institution';
import institutionInfoSelector from 'src/redux/selectors/institution/institutionInfo';
import institutionsListSelector from 'src/redux/selectors/institution/institutionsList';

import history from 'src/helpers/history';
import { useTypedSelector } from 'src/hooks/typeSelector';
import IReducerInfo from 'src/types/reducer';
import InstitutionsList from 'src/views/Institutions/InstitutionsList';

import LayoutContainer from '../Layout/Layout';
import './i18n';

const InstitutionsListContainer = () => {
  const dispatch = useDispatch();

  const institutions: GridRowModel[] = useTypedSelector(institutionsListSelector());
  const institutionInfo: IReducerInfo = useTypedSelector(institutionInfoSelector());

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: i18next.t('INSTITUTIONS:NAME_LABEL'),
      flex: 1,
    },
    {
      field: 'address',
      headerName: i18next.t('INSTITUTIONS:ADDRESS_LABEL'),
      flex: 1,
    },
    {
      field: 'population',
      headerName: i18next.t('INSTITUTIONS:POPULATION_LABEL'),
      flex: 1,
    },
    {
      field: 'province',
      headerName: i18next.t('INSTITUTIONS:PROVINCE_LABEL'),
      flex: 1,
    },
    {
      field: 'zipCode',
      headerName: i18next.t('INSTITUTIONS:ZIP_CODE_LABEL'),
      flex: 1,
    },
    {
      field: 'phoneNumber',
      headerName: i18next.t('INSTITUTIONS:PHONE_LABEL'),
      flex: 1,
    },
    {
      field: 'clientId',
      headerName: i18next.t('INSTITUTIONS:CLIENT_ID_LABEL'),
      flex: 1,
    },
  ];

  const onEdit = (rowId: string) => {
    history.push({
      pathname: `institutions/edit/${rowId}`,
    });
  };

  const onDeleteOne = (rowId: string) => {
    dispatch(institutionActions.onDeleteOneRequested(rowId));
  };

  const onDeleteMany = (rowsId: string[]) => {
    dispatch(institutionActions.onDeleteManyRequested(rowsId));
  };

  const onAddNew = () => {
    history.push({
      pathname: '/institutions/new',
    });
  };

  const onInitializeInstitutionsList = useCallback(() => {
    dispatch(institutionActions.initializeInstitutionsListView());
  }, [dispatch]);

  useEffect(() => {
    onInitializeInstitutionsList();
  }, [onInitializeInstitutionsList]);

  return (
    <LayoutContainer>
      <InstitutionsList
        columns={columns}
        error={institutionInfo.info.error ? institutionInfo.message : null}
        isLoading={institutionInfo.info.loading}
        institutions={institutions}
        onAddNew={onAddNew}
        hideAddNew={false}
        onEdit={onEdit}
        onDeleteOne={onDeleteOne}
        onDeleteMany={onDeleteMany}
        hideDeleteMany
      />
    </LayoutContainer>
  );
};

export default InstitutionsListContainer;
