import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'INSTITUTIONS', {
  NAME_LABEL: 'Name',
  ADDRESS_LABEL: 'Address',
  POPULATION_LABEL: 'Population',
  PROVINCE_LABEL: 'Province',
  ZIP_CODE_LABEL: 'Zip code',
  PHONE_LABEL: 'Phone',
  CLIENT_ID_LABEL: 'Client ID',
});

i18next.addResources(locales.ES, 'INSTITUTIONS', {
  NAME_LABEL: 'Nombre',
  ADDRESS_LABEL: 'Dirección',
  POPULATION_LABEL: 'Población',
  PROVINCE_LABEL: 'Provincia',
  ZIP_CODE_LABEL: 'Código postal',
  PHONE_LABEL: 'Teléfono',
  CLIENT_ID_LABEL: 'ID de cliente',
});
