import { all } from 'redux-saga/effects';

import auth from './sagas/auth';
import box from './sagas/box';
import institution from './sagas/institution';
import qrRequest from './sagas/qrRequest';
import request from './sagas/request';
import requestStatus from './sagas/requestStatus';
import roles from './sagas/roles';
import setType from './sagas/setType';
import user from './sagas/user';

export default function* rootSaga() {
  yield all([auth, user, box, request, roles, requestStatus, setType, institution, qrRequest]);
}
