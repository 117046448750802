import { INewInstitution } from 'src/types/institution';

import { apiDelete, get, post, put } from './api';

export const getAll = () => {
  return get('institutions');
};

export const deleteMany = (institutionIds: string[]) => {
  return apiDelete('institutions', {}, { institutionIds });
};

export const getOne = (institutionId: string) => {
  return get(`institutions/${institutionId}`);
};

export const deleteOne = (institutionId: string) => {
  return apiDelete(`institutions/${institutionId}`);
};

export const editOne = (institutionId: string, newInstitution: INewInstitution) => {
  return put(`institutions/${institutionId}`, newInstitution);
};

export const createOne = (newInstitution: INewInstitution) => {
  return post('institutions', { ...newInstitution });
};
