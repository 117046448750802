import i18next from 'i18next';
import * as Yup from 'yup';

import './i18n';

export const validationSchema = Yup.object({
  set: Yup.string().required(i18next.t('BOXES_NEW:SET_REQUIRED')).trim(),
  institutionId: Yup.string().required(i18next.t('BOXES_NEW:INSTITUTION_REQUIRED')).trim(),
  quantity: Yup.number()
    .typeError(i18next.t('BOXES_NEW:QUANTITY_INTEGER'))
    .required(i18next.t('BOXES_NEW:QUANTITY_REQUIRED'))
    .positive(i18next.t('BOXES_NEW:QUANTITY_POSITIVE'))
    .integer('BOXES_NEW:QUANTITY_INTEGER')
    .lessThan(1000, i18next.t('BOXES_NEW:QUANTITY_MAX')),
});
