import { FormControl, FormHelperText, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';

import { Input, InputOption, Label } from './styles';
import { ISelectInputProps } from './types';

const SelectInput = (props: ISelectInputProps) => {
  const {
    options,
    id,
    name,
    label,
    value,
    error = '',
    disabled = false,
    defaultOpen = false,
    onChange,
  } = props;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    onChange(event);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <FormControl fullWidth>
      <Label id={`${id}-label`}>{label}</Label>
      <Input
        fullWidth
        data-testid="select-field"
        inputProps={{ 'data-testid': 'select-input' }}
        id={id}
        labelId={`${id}-label`}
        name={name}
        value={value}
        label={label}
        open={isOpen}
        disabled={disabled}
        error={error !== ''}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        {options.map((op) => {
          return (
            <InputOption data-testid="select-input-option" key={op.value} value={op.value}>
              {op.text}
            </InputOption>
          );
        })}
      </Input>
      {error !== '' && (
        <FormHelperText data-testid="select-input-error-block" error={true} variant="outlined">
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectInput;
