import * as requestStatusConstants from 'src/redux/constants/requestStatus';

import { IAPIResponse } from '../services/api';

export const onGetRequested = () => ({
  type: requestStatusConstants.REQUEST_STATUS_ON_REQUESTED,
});

export const onGetSucceeded = (requestStatus: string[]) => ({
  type: requestStatusConstants.REQUEST_STATUS_ON_SUCCEEDED,
  requestStatus,
});

export const onGetFailed = (error: IAPIResponse) => ({
  type: requestStatusConstants.REQUEST_STATUS_ON_FAILED,
  error,
});
