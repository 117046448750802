import dayjs from 'dayjs';
import i18next from 'i18next';
import React from 'react';

import { Button, ButtonImportant } from 'src/components/Buttons/TextButton/styles';
import ImagesRequestQr from 'src/components/ImagesRequestQr';
import Loading from 'src/components/Loading';
import { IBoxImage } from 'src/types/request';

import BoxImagesContainer from './components/BoxImagesContainer';
import './i18n';
import { FormWrapper, Title, Text, ButtonContainer } from './styles';
import { IRequestsEditProps } from './types';

const RequestsEdit = (props: IRequestsEditProps) => {
  const {
    request,
    handleDownloadLabels,
    handleBack,
    handleViewLabels,
    handleReprocessing,
    imagesRequest,
    seeLables,
    seeReprocessRequest,
    loading,
  } = props;

  return (
    <FormWrapper>
      <Loading open={loading} text={i18next.t(`REQUESTS_EDIT:LOADING`)} />
      <Title>
        {i18next.t('REQUESTS_EDIT:TITLE')} {request.id}
      </Title>
      {seeLables ? (
        <ImagesRequestQr imagesRequest={imagesRequest} />
      ) : (
        <>
          <Text>
            {`${i18next.t('REQUESTS_EDIT:INSTITUTION_LABEL')}: ${
              request.brickoutUser.institution.name
            }`}
          </Text>
          <Text>
            {`${i18next.t('REQUESTS_EDIT:USER_LABEL')}: ${request.brickoutUser.firstName} ${
              request.brickoutUser.lastName
            }`}
          </Text>
          <Text>
            {`${i18next.t('REQUESTS_EDIT:CREATED_LABEL')}: ${dayjs(request.dateCreated).format(
              'DD/MM/YYYY hh:mm',
            )}`}
          </Text>
          <Text>
            {`${i18next.t('REQUESTS_EDIT:STATUS_LABEL')}: ${i18next.t(
              `REQUESTS_EDIT:STATUS_${request.status}`,
            )}`}
          </Text>
          {request.errorMessage && (
            <Text>{`${i18next.t('REQUESTS_EDIT:ERROR_MESSAGE_LABEL')}: ${
              request.errorMessage
            }`}</Text>
          )}
          <div style={{ height: '8px' }}></div>
          <BoxImagesContainer boxes={(request.boxImages as IBoxImage[]) || []} />
        </>
      )}
      <ButtonContainer>
        {seeLables ? (
          <Button onClick={handleDownloadLabels}>
            {i18next.t('REQUESTS_EDIT:DOWNLOAD_LABELS')}
          </Button>
        ) : (
          <Button onClick={handleViewLabels}>{i18next.t('REQUESTS_EDIT:VIEW_LABELS')}</Button>
        )}
        {seeReprocessRequest && (
          <ButtonImportant onClick={handleReprocessing}>
            {i18next.t('REQUESTS_EDIT:REPROCESS_REQUEST')}
          </ButtonImportant>
        )}
        <Button onClick={handleBack}>{i18next.t('REQUESTS_EDIT:BACK')}</Button>
      </ButtonContainer>
    </FormWrapper>
  );
};

export default RequestsEdit;
