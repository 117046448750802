import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'BOXES_EDIT', {
  SUBMIT_BUTTON: 'Edit',
  TITLE: 'REASSIGN BOX',
  ID_LABEL: 'ID',
  SET_LABEL: 'Set',
  INSTITUTION_LABEL: 'Institution',
  SET_REQUIRED: 'Set is required',
  INSTITUTION_REQUIRED: 'Institution is required',
  LOADING: 'Loading...',
});

i18next.addResources(locales.ES, 'BOXES_EDIT', {
  SUBMIT_BUTTON: 'Guardar',
  TITLE: 'REASIGNAR CAJA',
  ID_LABEL: 'ID',
  SET_LABEL: 'Set',
  INSTITUTION_LABEL: 'Institución',
  SET_REQUIRED: 'Set es requerido',
  INSTITUTION_REQUIRED: 'Institución es requerido',
  LOADING: 'Cargando...',
});
