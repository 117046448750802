import i18next from 'i18next';
import * as Yup from 'yup';

import './i18n';

export const validationSchema = Yup.object({
  name: Yup.string().required(i18next.t('INSTITUTIONS_EDIT:NAME_REQUIRED')).trim(),
  street: Yup.string().required(i18next.t('INSTITUTIONS_EDIT:STREET_REQUIRED')).trim(),
  streetNumber: Yup.string().required(i18next.t('INSTITUTIONS_EDIT:STREET_NUMBER_REQUIRED')).trim(),
  door: Yup.string().required(i18next.t('INSTITUTIONS_EDIT:DOOR_REQUIRED')).trim(),
  zipCode: Yup.string().required(i18next.t('INSTITUTIONS_EDIT:ZIP_CODE_REQUIRED')).trim(),
  population: Yup.string().required(i18next.t('INSTITUTIONS_EDIT:POPULATION_REQUIRED')).trim(),
  province: Yup.string().required(i18next.t('INSTITUTIONS_EDIT:PROVINCE_REQUIRED')).trim(),
  phoneNumber: Yup.string().required(i18next.t('INSTITUTIONS_EDIT:PHONE_REQUIRED')).trim(),
  clientId: Yup.string().required(i18next.t('INSTITUTIONS_EDIT:CLIENT_ID_REQUIRED')).trim(),
});
