import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'QR_REQUESTS_EDIT', {
  SUBMIT_BUTTON: 'Edit',
  TITLE: 'EDIT QR REQUEST',
  ID_LABEL: 'ID',
  USER_LABEL: 'User',
  AMOUNT_LABEL: 'Quantity',
  STATUS_LABEL: 'Status',
  STATUS_PENDING: 'Pending',
  STATUS_PROCESSED: 'Processed',
  AMOUNT_REQUIRED: 'Quantity is required',
  LOADING: 'Loading...',
});

i18next.addResources(locales.ES, 'QR_REQUESTS_EDIT', {
  SUBMIT_BUTTON: 'Guardar',
  TITLE: 'EDITAR PETICIÓN QR',
  ID_LABEL: 'ID',
  USER_LABEL: 'Usuario',
  AMOUNT_LABEL: 'Cantidad',
  STATUS_LABEL: 'Estado',
  STATUS_PENDING: 'Pendiente',
  STATUS_PROCESSED: 'Procesado',
  AMOUNT_REQUIRED: 'Cantidad es requerido',
  LOADING: 'Cargando...',
});
