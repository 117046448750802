export const INITIALIZE_REQUESTS_LIST_VIEW_REQUESTED = 'INITIALIZE_REQUESTS_LIST_VIEW_REQUESTED';

export const INITIALIZE_REQUESTS_LIST_VIEW_SUCCEEDED = 'INITIALIZE_REQUESTS_LIST_VIEW_SUCCEEDED';

export const INITIALIZE_REQUESTS_LIST_VIEW_FAILED = 'INITIALIZE_REQUESTS_LIST_VIEW_FAILED';

export const REQUESTS_ON_DELETE_ONE_REQUESTED = 'REQUESTS_ON_DELETE_ONE_REQUESTED';

export const REQUESTS_ON_DELETE_ONE_SUCCEEDED = 'REQUESTS_ON_DELETE_ONE_SUCCEEDED';

export const REQUESTS_ON_DELETE_ONE_FAILED = 'REQUESTS_ON_DELETE_ONE_FAILED';

export const REQUESTS_ON_DELETE_MANY_REQUESTED = 'REQUESTS_ON_DELETE_MANY_REQUESTED';

export const REQUESTS_ON_DELETE_MANY_SUCCEEDED = 'REQUESTS_ON_DELETE_MANY_SUCCEEDED';

export const REQUESTS_ON_DELETE_MANY_FAILED = 'REQUESTS_ON_DELETE_MANY_FAILED';

export const REQUESTS_ON_EDIT_ONE_REQUESTED = 'REQUESTS_ON_EDIT_ONE_REQUESTED';

export const REQUESTS_ON_EDIT_ONE_SUCCEEDED = 'REQUESTS_ON_EDIT_ONE_SUCCEEDED';

export const REQUESTS_ON_EDIT_ONE_FAILED = 'REQUESTS_ON_EDIT_ONE_FAILED';

export const INITIALIZE_REQUESTS_EDIT_VIEW_REQUESTED = 'INITIALIZE_REQUESTS_EDIT_VIEW_REQUESTED';

export const INITIALIZE_REQUESTS_EDIT_VIEW_SUCCEEDED = 'INITIALIZE_REQUESTS_EDIT_VIEW_SUCCEEDED';

export const INITIALIZE_REQUESTS_EDIT_VIEW_FAILED = 'INITIALIZE_REQUESTS_EDIT_VIEW_FAILED';

export const UPDATE_ACCEPTED_FIELD_BOXIMAGES = 'UPDATE_ACCEPTED_FIELD_BOXIMAGES';

export const UPDATE_ACCEPTED_FIELD_BOXIMAGES_SUCCEEDED =
  'UPDATE_ACCEPTED_FIELD_BOXIMAGES_SUCCEEDED';

export const UPDATE_ACCEPTED_FIELD_BOXIMAGES_FAILED = 'UPDATE_ACCEPTED_FIELD_BOXIMAGES_FAILED';

export const REPROCESS_REQUEST = 'REPROCESS_REQUEST';

export const REPROCESS_REQUEST_SUCCEEDED = 'REPROCESS_REQUEST_SUCCEEDED';

export const REPROCESS_REQUEST_FAILED = 'REPROCESS_REQUEST_FAILED';

export const GET_REQUEST_STATE = 'GET_REQUEST_STATE';

export const GET_REQUEST_STATE_SUCCEEDED = 'GET_REQUEST_STATE_SUCCEEDED';

export const GET_REQUEST_STATE_FAILED = 'GET_REQUEST_STATE_FAILED';
