import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'BOXES', {
  SET_LABEL: 'Set',
  INSTITUTION_LABEL: 'Institution',
  BOX: 'Box',
});

i18next.addResources(locales.ES, 'BOXES', {
  SET_LABEL: 'Set',
  INSTITUTION_LABEL: 'Institución',
  BOX: 'Caja',
});
