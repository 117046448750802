import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'REQUESTS', {
  STATUS_LABEL: 'Status',
  INSTITUTION_LABEL: 'Institution',
  CREATED_LABEL: 'Created at',
  USER_LABEL: 'User',
  STATUS_PENDING: 'EXPECTED',
  STATUS_PROCESSING: 'SLOPES',
  STATUS_COMPLETED: 'SERVED',
  STATUS_FAILED: 'FAILED PROCESSING',
  LABELS: 'Labels',
  REQUEST: 'Request',
});

i18next.addResources(locales.ES, 'REQUESTS', {
  STATUS_LABEL: 'Estado',
  INSTITUTION_LABEL: 'Institución',
  CREATED_LABEL: 'Creada en',
  USER_LABEL: 'Usuario',
  STATUS_PENDING: 'ESPERADAS',
  STATUS_PROCESSING: 'PENDIENTES',
  STATUS_COMPLETED: 'SERVIDAS',
  STATUS_FAILED: 'PROCESADO FALLIDO',
  LABELS: 'Etiquetas',
  REQUEST: 'Solicitud',
});
