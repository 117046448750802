import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'PROCESSING_REQUEST_DETAILS', {
  BOX_ID: 'Box ID',
  REQUEST_ID: 'Request',
  PHONE_NUMBER: 'Phone',
  IMAGES: 'Images',
  VIEW_BRICKS: 'View Bricks',
  PRINT_LABEL: 'Download labels',
  VERIFIED: 'Verified',
  NOT_ACCEPTED: 'Not accepted',
  CLOSE: 'Close',
  CONTINUE: 'Continue',
  CONTINUE_MODAL: 'Has the label already been printed?',
  CONFIRM_MODAL: 'Have been organized the bricks to send?',
  REJECT_MODAL: 'Are you sure you want to reject the box?',
  LOADING_PDF: 'Generating PDF',
  LOADING: 'Loading...',
  FOOTER_IMAGES:
    'Please review the application photos. If they do not meet the preconditions, you must reject them.',
});

i18next.addResources(locales.ES, 'PROCESSING_REQUEST_DETAILS', {
  BOX_ID: 'Caja ID',
  REQUEST_ID: 'Solicitud',
  PHONE_NUMBER: 'Teléfono',
  IMAGES: 'Imágenes',
  VIEW_BRICKS: 'Ver Piezas',
  PRINT_LABEL: 'Descargar etiquetas',
  VERIFIED: 'Verificada',
  NOT_ACCEPTED: 'No aceptada',
  CLOSE: 'Cerrar',
  CONTINUE: 'Continuar',
  CONTINUE_MODAL: '¿Ya ha sido impresa la etiqueta?',
  CONFIRM_MODAL: '¿Ya se organizaron las piezas a enviar?',
  REJECT_MODAL: '¿Está seguro que desea rechazar la caja?',
  LOADING_PDF: 'Generando PDF',
  LOADING: 'Cargando...',
  FOOTER_IMAGES:
    'Revise las fotos de la solicitud. Si no cumplen con las precondiciones, debe rechazarlas.',
});
