import * as setTypeConstants from 'src/redux/constants/setType';

import { IAPIResponse } from '../services/api';

export const onGetRequested = () => ({
  type: setTypeConstants.SET_TYPE_ON_REQUESTED,
});

export const onGetSucceeded = (setType: string[]) => ({
  type: setTypeConstants.SET_TYPE_ON_SUCCEEDED,
  setType,
});

export const onGetFailed = (error: IAPIResponse) => ({
  type: setTypeConstants.SET_TYPE_ON_FAILED,
  error,
});
