import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'TABLE_TOOLBAR', {
  ADD: 'New',
  BULK_DELETE: 'Bulk delete',
});

i18next.addResources(locales.ES, 'TABLE_TOOLBAR', {
  ADD: 'Nuevo',
  BULK_DELETE: 'Eliminar en masa',
});
