import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'BOX_QR', {
  INSTITUTION: 'Institution',
  DOWNLOAD: 'Download QR',
  ID_BOX: 'Box',
  ID_CLIENT: 'Client',
});

i18next.addResources(locales.ES, 'BOX_QR', {
  INSTITUTION: 'Institución',
  DOWNLOAD: 'Descargar QR',
  ID_BOX: 'Caja',
  ID_CLIENT: 'Cliente',
});
