import * as rolesConstants from 'src/redux/constants/roles';

import { IAPIResponse } from '../services/api';

export const onGetRequested = () => ({
  type: rolesConstants.ROLES_ON_REQUESTED,
});

export const onGetSucceeded = (roles: string[]) => ({
  type: rolesConstants.ROLES_ON_SUCCEEDED,
  roles,
});

export const onGetFailed = (error: IAPIResponse) => ({
  type: rolesConstants.ROLES_ON_FAILED,
  error,
});
