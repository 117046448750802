import { Paper } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Paper)`
  margin: 10px;
`;

export const BrickContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
`;

export const BrickImage = styled.img`
  height: 100px;
  width: 100px;
  align-self: center;
`;

export const Text = styled.div`
  padding: 5px;
  font-size: ${({ fontSize }: { fontSize: number; fontWeight: number }) => fontSize}px;
  font-weight: ${({ fontWeight }: { fontSize: number; fontWeight: number }) => fontWeight};
`;
