import { GridColumns } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import i18next from 'i18next';

import './i18n';

export const requestTableColumns: GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
  },
  {
    field: 'institution',
    headerName: i18next.t('PROCESSING_REQUESTS_TABLE:INSTITUTION_LABEL'),
    flex: 1,
  },
  {
    field: 'user',
    headerName: i18next.t('PROCESSING_REQUESTS_TABLE:USER_LABEL'),
    flex: 1,
  },
  {
    field: 'dateCreated',
    headerName: i18next.t('PROCESSING_REQUESTS_TABLE:SENT_DATE'),
    valueFormatter: (params) => dayjs(params.value as Date).format('DD/MM/YYYY hh:mm'),
    flex: 1,
  },
  {
    field: 'daysOfDelay',
    headerName: i18next.t('PROCESSING_REQUESTS_TABLE:DAYS_OF_DELAY'),
    flex: 1,
  },
  {
    field: 'boxOk',
    headerName: i18next.t('PROCESSING_REQUESTS_TABLE:BOX_OK'),
    flex: 1,
  },
  {
    field: 'boxMissing',
    headerName: i18next.t('PROCESSING_REQUESTS_TABLE:BOX_MISSING'),
    flex: 1,
  },
  {
    field: 'totalBricksMissing',
    headerName: i18next.t('PROCESSING_REQUESTS_TABLE:TOTAL_BRICKS_MISSING'),
    flex: 1,
  },
];
