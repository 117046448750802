import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const institutionsListSelector = () =>
  createSelector([(state: IReduxState) => state.institution.institutions], (institutions) => {
    if (!institutions) return [];
    return institutions.map((i) => ({
      id: i.id,
      name: i.name,
      address: `${i.street} ${i.streetNumber} ${i.door}`,
      zipCode: i.zipCode,
      population: i.population,
      province: i.province,
      phoneNumber: i.phoneNumber,
      clientId: i.clientId,
    }));
  });

export default institutionsListSelector;
