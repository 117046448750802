import { AnyAction, MiddlewareAPI } from 'redux';

import { saveAuthToken, removeAuthToken } from 'src/helpers/cookies';

import * as appConstants from '../constants/app';
import * as authConstants from '../constants/auth';

const cookiesMiddleware =
  (state: MiddlewareAPI) => (next: (action: AnyAction) => void) => (action: AnyAction) => {
    switch (action.type) {
      case authConstants.USER_ON_LOGIN_SUCCEEDED:
        saveAuthToken(action.authToken, action.refreshToken);
        break;
      case authConstants.USER_ON_LOGOUT_SUCCEEDED:
        removeAuthToken();
        break;
      case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_FAILED:
      case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_FAILED:
        if (action.error.statusCode === 401 || action.error.statusCode === 422) {
          removeAuthToken();
        }
        break;
      default:
        break;
    }
    next(action);
  };

export default cookiesMiddleware;
