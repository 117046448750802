import { GridColumns, GridRowModel, GridSortItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import i18next from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as requestActions from 'src/redux/actions/request';
import requestInfoSelector from 'src/redux/selectors/request/requestInfo';
import requestsListSelector from 'src/redux/selectors/request/requestsList';

import history from 'src/helpers/history';
import { useTypedSelector } from 'src/hooks/typeSelector';
import IReducerInfo from 'src/types/reducer';
import RequestsList from 'src/views/Requests/RequestsList';

import LayoutContainer from '../Layout/Layout';
import './i18n';

const RequestsListContainer = () => {
  const dispatch = useDispatch();

  const requests: GridRowModel[] = useTypedSelector(requestsListSelector());
  const requestInfo: IReducerInfo = useTypedSelector(requestInfoSelector());

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'status',
      headerName: i18next.t('REQUESTS:STATUS_LABEL'),
      valueFormatter: (params) => i18next.t(`REQUESTS:STATUS_${params.value}`),
      flex: 1,
    },
    {
      field: 'institution',
      headerName: i18next.t('REQUESTS:INSTITUTION_LABEL'),
      flex: 1,
    },
    {
      field: 'user',
      headerName: i18next.t('REQUESTS:USER_LABEL'),
      flex: 1,
    },
    {
      field: 'dateCreated',
      headerName: i18next.t('REQUESTS:CREATED_LABEL'),
      valueFormatter: (params) => dayjs(params.value as Date).format('DD/MM/YYYY hh:mm'),
      flex: 1,
    },
  ];

  const sortModel: GridSortItem[] = [{ field: 'id', sort: 'desc' }];

  const onView = (rowId: string) => {
    history.push({
      pathname: `requests/edit/${rowId}`,
    });
  };

  const showEditButton = (row: GridRowModel) => {
    const request = requests.find((r) => r.id === row.row.id);
    if (!request) return false;
    return request.status === 'PROCESSING' && request.allBoxesProcessed === true;
  };

  const onRowClick = (row: GridRowModel) => {
    const path = showEditButton(row) ? 'processing-requests' : 'requests/edit';
    history.push({
      pathname: `${path}/${row.row.id}`,
    });
  };

  const onEdit = (rowId: string) => {
    history.push({
      pathname: `processing-requests/${rowId}`,
    });
  };

  const onDeleteOne = (rowId: string) => {
    dispatch(requestActions.onDeleteOneRequested(rowId));
  };

  const onDeleteMany = (rowsId: string[]) => {
    dispatch(requestActions.onDeleteManyRequested(rowsId));
  };

  const onAddNew = () => {
    history.push({
      pathname: '/requests/new',
    });
  };

  const onInitializeRequestsList = useCallback(() => {
    dispatch(requestActions.initializeRequestsListView());
  }, [dispatch]);

  useEffect(() => {
    onInitializeRequestsList();
  }, [onInitializeRequestsList]);

  return (
    <LayoutContainer>
      <RequestsList
        columns={columns}
        error={requestInfo.info.error ? requestInfo.message : null}
        isLoading={requestInfo.info.loading}
        requests={requests}
        onAddNew={onAddNew}
        sortModel={sortModel}
        hideAddNew
        onView={onView}
        onEdit={onEdit}
        onRowClick={onRowClick}
        showEditButton={showEditButton}
        onDeleteOne={onDeleteOne}
        onDeleteMany={onDeleteMany}
        hideDeleteMany
      />
    </LayoutContainer>
  );
};

export default RequestsListContainer;
