import { takeLatest, all, call, put } from 'redux-saga/effects';

import * as setTypeActions from 'src/redux/actions/setType';
import * as appConstants from 'src/redux/constants/app';
import * as setTypeConstants from 'src/redux/constants/setType';
import * as setTypeService from 'src/redux/services/setType';

function* getSetType() {
  try {
    const setType: { setTypes: string[] } = yield call(setTypeService.getSetType);
    yield put(setTypeActions.onGetSucceeded(setType.setTypes));
  } catch (error: any) {
    yield put(setTypeActions.onGetFailed(error));
  }
}

export default all([
  takeLatest(appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_SUCCEDED, getSetType),
  takeLatest(setTypeConstants.SET_TYPE_ON_REQUESTED, getSetType),
]);
