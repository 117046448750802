import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Text = styled.div`
  padding: 10px;
  font-size: ${({ fontSize }: { fontSize: number; fontWeight: number }) => fontSize}px;
  font-weight: ${({ fontWeight }: { fontSize: number; fontWeight: number }) => fontWeight};
`;
